<template>
  <v-row>
    <!-- Filters -->
    <v-col cols="4">
      <div class="custom-label">Period</div>
      <v-select v-model="filters.period" :items="periodItems" item-title="title" item-value="id" clearable class="white-background"></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">Coverage</div>
      <v-combobox
        v-model="coverageId"
        variant="outlined"
        density="compact"
        :items="filteredCoverage"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">User</div>
      <v-combobox
        v-model="userId"
        variant="outlined"
        density="compact"
        :items="filteredUser"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">Sold-to</div>
      <v-combobox
        v-model="soldToId"
        variant="outlined"
        density="compact"
        :items="filteredSoldTo"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      >
      </v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">Ship-to</div>
      <v-combobox
        v-model="shipToId"
        variant="outlined"
        density="compact"
        :items="filteredShipTo"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      >
      </v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">View By</div>
      <v-select v-model="filters.view_mode" :items="viewModeItems" clearable class="white-background"></v-select>
    </v-col>

    <!-- Generate & Export Buttons -->
    <v-col cols="12" class="d-flex justify-end">
      <!-- <v-btn color="secondary" @click="downloadReport">EXPORT</v-btn> -->
      <v-btn color="primary" class="ml-2" @click="fetchrofoSummary">GENERATE</v-btn>
    </v-col>

    <!-- Table -->
    <v-row v-if="rofoSummaryDetail.length" class="scroll-container justify-end">
      <v-data-table
        :items="rofoSummaryDetail"
        :headers="headers"
        :key="headers.length"
        class="elevation-1 table-fixed"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:headers>
          <!-- First row: Month headers -->
          <tr>
            <th></th>
            <template v-for="period in periods" :key="period">
              <th :colspan="4" class="text-center">{{ period }}</th>
            </template>
          </tr>

          <!-- Second row: MTD & YTD -->
          <tr>
            <th>Type</th>
            <template v-for="period in periods" :key="period">
              <th :colspan="2" class="text-center">MTD</th>
              <th :colspan="2" class="text-center">YTD</th>
            </template>
          </tr>

          <!-- Third row: QTY & VALUE -->
          <tr>
            <th></th>
            <template v-for="period in periods" :key="period">
              <th class="text-center">QTY</th>
              <th class="text-center">VALUE</th>
              <th class="text-center">QTY</th>
              <th class="text-center">VALUE</th>
            </template>
          </tr>
        </template>

        <!-- Data Rows -->
        <template v-slot:[`item`]="{ item }">
          <tr>
            <td :class="{ 'font-weight-bold': item.type === 'DOMESTIC' }">{{ item.type }}</td>
            <template v-for="period in periods" :key="period">
              <td class="text-right">{{ formatNumber(item[`${period}_mtd_qty`]) }}</td>
              <td class="text-right">{{ formatNumber(item[`${period}_mtd_value`]) }}</td>
              <td class="text-right">{{ formatNumber(item[`${period}_ytd_qty`]) }}</td>
              <td class="text-right">{{ formatNumber(item[`${period}_ytd_value`]) }}</td>
            </template>
          </tr>
        </template>
      </v-data-table>
    </v-row>

    <v-overlay v-if="loading" persistent class="align-center justify-center">
      <v-progress-circular color="primary" size="120" indeterminate />
    </v-overlay>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      headers: [],
      rofoSummaryDetail: [],
      periods: [],
      loading: false,

      filters: {
        period: null,
        coverage: null,
        user: null,
        sold_to: null,
        ship_to: null,
        view_mode: null
      },

      periodItems: [],
      coverageItems: [],
      userItems: [],
      soldToItems: [],
      shipToItems: [],
      viewModeItems: [],
      searchQuery: ''
    };
  },
  computed: {
    filteredCoverage() {
      if (!this.searchQuery) return this.coverageItems;
      const query = this.searchQuery.toLowerCase();
      return this.coverageItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredUser() {
      if (!this.searchQuery) return this.userItems;
      const query = this.searchQuery.toLowerCase();
      return this.userItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredSoldTo() {
      if (!this.searchQuery) return this.soldToItems;
      const query = this.searchQuery.toLowerCase();
      return this.soldToItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredShipTo() {
      if (!this.searchQuery) return this.shipToItems;
      const query = this.searchQuery.toLowerCase();
      return this.shipToItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    coverageId: {
      get() {
        return this.filters.coverage ?? null;;
      },
      set(value) {
        this.filters.coverage = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    userId: {
      get() {
        return this.filters.user ?? null;;
      },
      set(value) {
        this.filters.user = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    shipToId: {
      get() {
        return this.filters.ship_to ?? null;;
      },
      set(value) {
        this.filters.ship_to = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    soldToId: {
      get() {
        return this.filters.sold_to ?? null;;
      },
      set(value) {
        this.filters.sold_to = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
  },
  methods: {
    ...mapActions(['showAlert']),
    formatNumber(value) {
      return value ? value.toLocaleString() : '0';
    },
    
    async fetchrofoSummary() {
      this.loading = true;
      try {
        const response = await axios.get('/report/rofo_summary', { params: this.filters });
        console.log(response.data.report);

        const transformedData = this.transformData(response.data.report);
        this.rofoSummaryDetail = transformedData.data;
        this.headers = transformedData.headers;
        this.periods = transformedData.periods;
      } catch (error) {
        this.showAlert({ message: error.response.data.messages, color: 'error' });
      } finally {
        this.loading = false;
      }
    },

    async fetchFilterData() {
      this.loading = true;
      try {
        const response = await axios.get('/report/filter-options?type=rofo_summary');
        this.periodItems = response.data.periods;
        this.coverageItems = response.data.coverages;
        this.userItems = response.data.users;
        this.soldToItems = response.data.ship_to;
        this.shipToItems = response.data.sold_to;
        this.viewModeItems = response.data.view_by;
        
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    transformData(report) {
      const monthOrder = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

      const periods = new Set();

      Object.keys(report).forEach((key) => {
        Object.keys(report[key]).forEach((period) => periods.add(period.toUpperCase()));
      });

      // const periodList = Array.from(periods).sort((a, b) => {
      //   return monthOrder.indexOf(a) - monthOrder.indexOf(b);
      // });

      const periodList = Array.from(periods);

      // Construct headers with multiple layers
      const headers = [
        { title: '', key: 'type' },
        ...periodList.flatMap((period) => [
          { title: period, key: `${period}`, colspan: 4, align: 'center' }, // Top Level
        ]),
        ...periodList.flatMap(() => [
          { title: 'MTD', key: 'mtd', colspan: 2, align: 'center' },
          { title: 'YTD', key: 'ytd', colspan: 2, align: 'center' }
        ]),
        ...periodList.flatMap(() => [
          { title: 'QTY', key: 'mtd_qty', align: 'center' },
          { title: 'VALUE', key: 'mtd_value', align: 'center' },
          { title: 'QTY', key: 'ytd_qty', align: 'center' },
          { title: 'VALUE', key: 'ytd_value', align: 'center' }
        ])
      ];

      const groupedData = [
        { type: 'FOOD', ...this.buildRow(report.food, periodList) },
        { type: 'BEVERAGE', ...this.buildRow(report.beverage, periodList) },
        { type: 'DOMESTIC', ...this.buildRow(report.domestic, periodList) }
      ];

      return { headers, data: groupedData, periods: periodList };
    },

    buildRow(data, periodList) {
      const row = {};
      periodList.forEach((period) => {
        const periodData = data[period.toLowerCase()] || {};
        row[`${period}_mtd_qty`] = periodData.mtd?.qty || 0;
        row[`${period}_mtd_value`] = periodData.mtd?.value || 0;
        row[`${period}_ytd_qty`] = periodData.ytd?.qty || 0;
        row[`${period}_ytd_value`] = periodData.ytd?.value || 0;
      });
      return row;
    }

  },
  mounted() {
    this.fetchFilterData();
  }
};
</script>

<style scoped>
.scroll-container { 
  max-width: 100% !important;
  margin: 10px !important;
  overflow-x: auto !important;
}

.white-background .v-select__slot {
  background-color: white !important;
}

.white-background .v-select__selections {
  background-color: white !important;
}

.white-background .v-select-list {
  background-color: white !important;
}

.custom-label {
  font-weight: bold;
  margin-bottom: 8px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
</style>
