<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <!-- <v-btn disabled color="primary" @click="navigateToForm('add')">{{ $t('button.newSellIn') }}</v-btn> -->
        <v-btn class="ml-2" color="primary" @click="navigateToForm('import')">Import</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="sellIn" :actions="tableActions" :loading="loading" 
          :filterLabel2="'Period'" 
          :filter2="true"
          :filterAttributes2="period"
          
          @filterChanged="fetchFilteredItems" 
           
          :filter5="true"
          :filterLabel5="'Ship To'" 
          :filterAttributes5="filteredShipTo"
          :searchQuery="searchQuery"
          />
      </v-col>
    </v-row>
    <v-overlay
      v-if="loading"
      :model-value="loading"
      class="align-center justify-center"
      persistent
    >
      <v-progress-circular
        color="primary"
        size="120"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
          { title: 'Ship to', key: 'distributor_name', align: 'start', sortable: true},
          { title: 'Product', key: 'product_description', align: 'start', sortable: true},
          { title: 'Total Qty', key: 'qtytotal', align: 'start', sortable: true},
          { title: 'Month', key: 'month', align: 'start', sortable: true},
          { title: 'Year', key: 'year', align: 'start', sortable: true},
          { title: '', key: 'actions', align: 'start'},
        ],
        sellIn: [],
        tableActions: {
          // edit: this.navigateToForm,
          delete: this.deleteSellOut,
          view: this.navigateToForm
        },
        loading: false,
        shipTo: [],
        period: [],
        searchQuery: ''
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'sellIn-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      },
      filteredShipTo() {
        if (!this.searchQuery) return this.shipTo;
        const query = this.searchQuery.toLowerCase();
        return this.shipTo.filter(d => 
          d.name.toLowerCase().includes(query)
        );
      },
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchsellIn() {
        this.loading = true;
        try {
          const response = await axios.get('/selling_in');
          this.sellIn = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages 
                              ? error.response.data.messages 
                              : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, sellIn = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'sellIn-view', params: { action, id: sellIn.id } });
        } else if(action == 'import') {
            this.$router.push({ name: 'sellIn-import', params: { action, id: sellIn.id } });
        } else {
          this.$router.push({ name: 'sellIn-form', params: { action, id: sellIn.id } });
        }
      },
      async deleteSellOut(sellInToDelete) {
        try {
          const response = await axios.delete(`/selling_in/${sellInToDelete.id}`);
  
          if (response.data) {
            this.sellIn = this.sellIn.filter(u => u.id !== sellInToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete sellIn');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages 
                              ? error.response.data.messages 
                              : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      
      async fetchShipTo() {
        try {
          const response = await axios.get('/distributor?is_parent=false');
          this.shipTo = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchFilteredItems(filters) {
        this.loading = true;

        try {
          // Handle Proxy or Target Structure
          const actualFilters = filters.target || filters;

          const params = {};

          // Map filter indices to keys
          if (actualFilters[1]) {
            params.month = actualFilters[1]; // Month
          }

          if (actualFilters[2]) {
            params.period = actualFilters[2]; // Period ID
          }

          if (actualFilters[4]) {
            params.ship_to = actualFilters[4]; // Ship To ID
          }

          if (actualFilters[5]) {
            params.ship_to = actualFilters[5].id; // Ship To ID
          }

          // Construct query string
          const queryString = new URLSearchParams(params).toString();
          // Make API request
          const response = await axios.get(`/selling_in?${queryString}`);
          this.sellIn = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage =
            error.response?.data?.messages ||
            error.response?.message ||
            "An error occurred";
          this.showAlert({ message: errorMessage, color: "error" });
        }
      },
      async fetchPeriod() {
        try {
          const response = await axios.get('/period');
          this.period = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
    },
    mounted() {
      this.fetchsellIn();
      this.fetchShipTo();
      this.fetchPeriod();
    }
  };
  </script>
  