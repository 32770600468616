<template>
  <v-row>
    <!-- Filters with custom labels at the top -->
    <v-col cols="4">
      <div class="custom-label">Period</div>
      <v-select
        v-model="filters.period"
        :items="periodItems"
        item-title="title"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">Coverage</div>
      <v-combobox
        v-model="coverageId"
        variant="outlined"
        density="compact"
        :items="filteredCoverage"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">User</div>
      <v-combobox
        v-model="userId"
        variant="outlined"
        density="compact"
        :items="filteredUser"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">Sold-to</div>
      <v-combobox
        v-model="soldToId"
        variant="outlined"
        density="compact"
        :items="filteredSoldTo"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      >
      </v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">Ship-to</div>
      <v-combobox
        v-model="shipToId"
        variant="outlined"
        density="compact"
        :items="filteredShipTo"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      >
      </v-combobox>
    </v-col>

    <!-- <v-col cols="4">
      <div class="custom-label">View By</div>
      <v-select
        v-model="filters.view_mode"
        :items="viewModeItems"
        clearable
        class="white-background"
      ></v-select>
    </v-col> -->

    <!-- Generate & Export Buttons -->
    <v-col cols="12" class="d-flex justify-end">
      <!-- <v-btn color="secondary" @click="downloadReport">EXPORT</v-btn> -->
      <v-btn color="primary" class="ml-2" @click="fetchrofoSummaryDetailDetail">GENERATE</v-btn>
    </v-col>
  </v-row>

  <!-- Table -->
  <v-row v-if="rofoSummaryDetail.length">
    <v-col cols="12">
      <v-data-table
        :items="rofoSummaryDetail"
        :headers="headers"
        :key="headers.length"
        class="elevation-1"
        hide-default-footer
        :items-per-page="-1"
      >
        <!-- Corrected Qty/Value and % Row -->
        <template v-slot:body.prepend>
          <tr>
            <td></td> <!-- Empty cell for Type column -->
            <template v-for="period in periods" :key="period">
              <td class="text-left">Qty/Value</td>
              <td class="text-left">%</td>
            </template>
          </tr>
        </template>

        <template v-slot:[`item`]="{ item }">
          <tr 
            v-if="item.isDivider" 
            style="background-color: #e0e0e0; height: 10px"
          ></tr>
          <tr 
            v-else 
            :style="item.type.toUpperCase() === 'SNOP TO BUDGET' || 
                    item.type.toUpperCase() === 'SI TO BUDGET' || 
                    item.type.toUpperCase().includes('YTD') ||
                    item.type.toUpperCase().includes('AVERAGE') 
                    ? 'font-weight: bold;' : ''"
          >
            <td v-for="header in headers" :key="header.key">{{ item[header.key] || '' }}</td>
          </tr>
        </template>
      </v-data-table>

    </v-col>
  </v-row>

  <v-overlay v-if="loading" persistent class="align-center justify-center">
    <v-progress-circular color="primary" size="120" indeterminate />
  </v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      headers: [],
      rofoSummaryDetail: [],
      periods: [],
      loading: false,

      filters: {
        period: null,
        coverage: null,
        user: null,
        sold_to: null,
        ship_to: null,
        view_mode: null
      },

      periodItems: [],
      coverageItems: [],
      userItems: [],
      soldToItems: [],
      shipToItems: [],
      viewModeItems: [],
      searchQuery: ''
    };
  },
  computed: {
    filteredCoverage() {
      if (!this.searchQuery) return this.coverageItems;
      const query = this.searchQuery.toLowerCase();
      return this.coverageItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredUser() {
      if (!this.searchQuery) return this.userItems;
      const query = this.searchQuery.toLowerCase();
      return this.userItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredSoldTo() {
      if (!this.searchQuery) return this.soldToItems;
      const query = this.searchQuery.toLowerCase();
      return this.soldToItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredShipTo() {
      if (!this.searchQuery) return this.shipToItems;
      const query = this.searchQuery.toLowerCase();
      return this.shipToItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    coverageId: {
      get() {
        return this.filters.coverage ?? null;;
      },
      set(value) {
        this.filters.coverage = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    userId: {
      get() {
        return this.filters.user ?? null;;
      },
      set(value) {
        this.filters.user = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    shipToId: {
      get() {
        return this.filters.ship_to ?? null;;
      },
      set(value) {
        this.filters.ship_to = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    soldToId: {
      get() {
        return this.filters.sold_to ?? null;;
      },
      set(value) {
        this.filters.sold_to = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchrofoSummaryDetailDetail() {
      this.loading = true;
      try {
        const response = await axios.get('/report/rofo_summary_detail', { params: this.filters });
        console.log(response.data.report);

        const transformedData = this.transformData(response.data.report);
        this.rofoSummaryDetail = transformedData.data;
        this.headers = transformedData.headers;
        this.periods = transformedData.periods;
      } catch (error) {
        this.showAlert({ message: error.response.data.messages, color: 'error' });
      } finally {
        this.loading = false;
      }
    },

    async fetchFilterData() {
      this.loading = true;
      try {
        const response = await axios.get('/report/filter-options?type=rofo_summary_detail');
        this.periodItems = response.data.periods;
        this.coverageItems = response.data.coverages;
        this.userItems = response.data.users;
        this.soldToItems = response.data.ship_to;
        this.shipToItems = response.data.sold_to;
        this.viewModeItems = response.data.view_by;
        
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    transformData(report) {
      const monthOrder = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

      const labelMapping = {
        snop_to_budget: 'SNOP TO BUDGET',
        si_to_budget: 'SI TO BUDGET',
        qty_ytd_value: 'Qty YTD (SNOP + SI)',
        si_ytd_avg: 'SI YTD AVERAGE',
        qty_ytd_percent: '% Qty YTD (SNOP + SI)'
      };

      const periods = new Set();

      Object.keys(report).forEach((key) => {
        Object.keys(report[key]).forEach((period) => periods.add(period));
      });

      const periodList = Array.from(periods).sort((a, b) => {
        return monthOrder.indexOf(a.toUpperCase()) - monthOrder.indexOf(b.toUpperCase());
      });

      const groupedData = [];

      Object.keys(report).forEach((group, index) => {
        if (index !== 0) {
          groupedData.push({ isDivider: true });
        }

        const label = labelMapping[group] || group.toUpperCase();

        groupedData.push({ type: label, ...this.buildRow(report[group], 'average', periodList) });

        groupedData.push({ type: 'FOOD', ...this.buildRow(report[group], 'food', periodList) });

        groupedData.push({ type: 'BEVERAGE', ...this.buildRow(report[group], 'bvrg', periodList) });
      });

      const headers = [
        { title: '', key: 'type' },
        ...periodList.flatMap((period) => [
          { title: `${period.toUpperCase()}`, key: `${period}_qty`, align: 'end', class: 'text-end' },
          { title: ``, key: `${period}_percent`, align: 'end', class: 'text-end' }
        ])
      ];

      return { headers, data: groupedData, periods: periodList };
    },

    buildRow(data, category, periodList) {
      const row = {};
      periodList.forEach((period) => {
        const periodData = data[period] ? data[period][category] || {} : {};
        row[`${period}_qty`] = periodData.qty || 0;
        row[`${period}_percent`] = periodData.percent || '0%';
      });
      return row;
    }
  },
  mounted() {
    this.fetchFilterData();
  }
};
</script>

<style scoped>
.white-background .v-select__slot {
  background-color: white !important;
}

.white-background .v-select__selections {
  background-color: white !important;
}

.white-background .v-select-list {
  background-color: white !important;
}

.custom-label {
  font-weight: bold;
  margin-bottom: 8px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
</style>
