<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <v-card-title class="text-center justify-center py-6">
    </v-card-title>

    <v-tabs
      v-model="tab"
      bg-color="transparent"
      
      grow
    >
      <v-tab
        v-for="item in items"
        :key="item"
        :text="item"
        :value="item"
      ></v-tab>
    </v-tabs>

    <v-tabs-window v-model="tab">
      <v-tabs-window-item
        v-for="item in items"
        :key="item"
        :value="item"
      >
        <v-card flat>
          <v-card-text>
            <!-- Conditional content for each tab -->
            <div v-if="tab === 'Transaction Code Formatting'">
              <v-card>
                <template v-slot:title>Transaction Code Formatting</template>
                <v-divider></v-divider>
                <v-card-text>
                  <div>
                    <v-form ref="form" @submit.prevent="handleSubmitConfig('format')">
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Transaction Number Prefix</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-text-field v-model="cmo_config.cmo_prefix.transaction_number_prefix" bgColor="none"
                            persistent-hint
                            :hide-details="false"
                            hint="Kode Transaksi CMO yang akan melekeat dalam nomor transaksi setiap pembuatan dokumen CMO"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Date Format</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-select v-model="cmo_config.cmo_prefix.date_format"
                          :items="dateFormats"></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Sequence Length</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-text-field v-model="cmo_config.cmo_prefix.sequence_length" bgColor="none"
                            persistent-hint
                            :hide-details="false"
                            hint="Jumlah karakter dari counter number"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
                </v-card-text>

                <template v-slot:append>
                  <v-btn @click="handleSubmitConfig('format')" color="primary">
                    {{ $t('button.save') }}
                  </v-btn>
                </template>
              </v-card>
            </div>
            <div v-else-if="tab === 'Fulfillment Limit'">
              <v-card>
                <template v-slot:title>Fulfillment Limit</template>
                <v-divider></v-divider>
                <v-card-text>
                  <div>
                    <v-form ref="form" @submit.prevent="handleSubmitConfig('limit')">
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Area</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-select v-model="cmo_config_fulfillment_limit.area" item-value="id" item-title="name"
                          :items="areas"></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Fulfillment Limit</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-text-field v-model.number="cmo_config_fulfillment_limit.fulfillment_limit" bgColor="none"
                            persistent-hint
                            :hide-details="false"
                            hint="Set Presentation with decimal value"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
                        <v-col offset="2">
                          <v-btn variant="flat" color="primary" @click="addNewCmoFulfillment">Add to List</v-btn>
                        </v-col>
                      </v-row> -->
                      <v-row>
                        <v-data-table :items="cmo_config.cmo_fulfillment_limits" :headers="cmoFulfillmentLimit" hide-default-footer >
                          <template v-slot:item.actions="{ item }">
                            <!-- <v-btn density="compact" variant="text" color="error"
                              @click="confirmDelete(item, 2)">{{ $t('actions.delete') }}</v-btn> -->
                          </template>
                        </v-data-table>
                      </v-row>
                    </v-form>
                  </div>
                </v-card-text>

                <template v-slot:append>
                  <v-btn @click="handleSubmitConfig('limit')" color="primary">
                    {{ $t('button.save') }}
                  </v-btn>
                </template>
              </v-card>
            </div>
            <div v-else-if="tab === 'Combining Document'">
              <v-card>
                <template v-slot:title>Combining Document</template>
                <v-divider></v-divider>
                <v-card-text>
                  <div v-if="editConfig">
                    <v-form ref="form" @submit.prevent="handleSubmitConfig">
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Shipment Type</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-select readonly v-model="cmo_config.shipment_type" item-title="shipment_type" item-value="id"
                          :items="cmo_config.cmo_combine_document"></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Max. Document to Combine</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-text-field v-model.number="cmo_combine.max_combine" bgColor="none"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider class="my-4"></v-divider>
                      <!-- <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Ship To</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-select v-model="cmo_combine.ship_to_id" item-title="name" item-value="id"
                          :items="shipTo"></v-select>
                        </v-col>
                      </v-row> -->
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Ship To</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-combobox
                            v-model="shipToId"
                            variant="outlined"
                            density="compact"
                            :items="filteredShipTo"
                            item-value="id"
                            item-title="name"
                            placeholder="Search or select distributor"
                            clearable
                            hide-no-data
                            :search-input.sync="searchQuery"
                            return-object="false"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Ship to Combined</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-select v-model="cmo_combine.ship_to_combined_id" item-title="name" item-value="id"
                          :items="shipTo"></v-select>
                        </v-col>
                      </v-row> -->
                      <v-row>
                        <v-col lg="2" md="2" sm="2" cols="12">
                          <label class="custom-label">Ship to Combine</label>
                        </v-col>
                        <v-col md="6" sm="6" cols="12">
                          <v-combobox
                            v-model="combinedShipToId"
                            variant="outlined"
                            density="compact"
                            :items="filteredShipTo"
                            item-value="id"
                            item-title="name"
                            placeholder="Search or select distributor"
                            clearable
                            hide-no-data
                            :search-input.sync="searchQuery"
                            return-object="false"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col offset="2">
                          <v-btn variant="flat" color="primary" @click="addNewCombineDocument">Add to List</v-btn>
                        </v-col>
                      </v-row>
                      <v-data-table :items="cmoCombinedDocumentDetail" :headers="cmoCombinedDocumentDetailHeader" hide-default-footer >
                      </v-data-table>
                    </v-form>
                  </div>
                  <div v-else>
                    <v-row class="pt-4">
                      <v-data-table :items="cmo_config.cmo_combine_document" :headers="cmoCombinedDocument" hide-default-footer >
                        <template v-slot:item.actions="{ item }">
                          <v-btn density="compact" variant="text" color="primary"
                            @click="customizeCombineConfig(item, 2)">Customize</v-btn>
                        </template>
                      </v-data-table>
                    </v-row>
                  </div>
                </v-card-text>

                <template v-slot:append>
                  <!-- <v-btn @click="submitForm" color="primary" v-if="editConfig">
                    {{ $t('button.save') }}
                  </v-btn> -->
                  <v-btn @click="backToTable" color="secondary" v-if="editConfig">
                    Back
                  </v-btn>
                </template>
              </v-card>
            </div>
            <div v-else>
              Under Development
            </div>
          </v-card-text>
        </v-card>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-card>

  <v-row class="text-caption mt-2">
    <v-col>
      <v-btn-toggle v-model="toggle" color="primary" variant="text">
          <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-history">History</v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>
  <v-row class="mt-0 px-4" v-if="toggle === 0">
    <v-timeline align="start" side="end">
      <v-timeline-item
        v-for="(item, index) in historyItems"
        :key="index"
        dot-color="white"
        size="x-small"
        density="compact"
      >
        <div class="d-flex">
          <div class="me-4 text-caption">{{ item.time }}</div>
          <div>
            <div class="text-caption">{{ item.text }}</div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-row>

  <!-- Confirmation Dialog -->
  <v-dialog v-model="showConfirmation" max-width="400" persistent>
    <v-card>
      <v-card-title>
        Confirm Deletion
      </v-card-title>
      <v-card-subtitle>
        Are you sure you want to delete this item?
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showConfirmation = false">
          Cancel
        </v-btn>
        <v-btn @click="deleteItem">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  

</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      cmo_config: {
        cmo_prefix : {  
          transaction_number_prefix: '',
          date_format: '',
          sequence_length: '',
        },
        cmo_fulfillment_limits:[],
        cmo_combine_document: []
      },
      cmo_config_fulfillment_limit : {
        area: null,
        fulfillment_limit: 0,
      },
      cmo_combine: {
        max_combine: 0,
        ship_to_id: null,
        ship_to_combined_id: null,
      },
      dateFormats: ['mmYYYY', 'ddMMYYYY', 'yyyyMMdd'],
      tab: '',
      items: [
        'Transaction Code Formatting', 'Combining Document',
      ],
      coverages: [],
      roles: [],
      selectedRoleId: null,
      selectedCoverageId: null,
      model: false,
      showConfirmation: false,
      areas: [],
      shipTo: [],
      cmoFulfillmentLimit: [
        { title: 'Area', value: 'area' },
        { title: 'Fulfillment Limit', key: 'fulfillment_limit' },
        { title: '', key: 'actions', sortable: false },
      ],
      cmoCombinedDocument: [
        { title: 'Shipment Type', value: 'shipment_type' },
        { title: 'Updated At', key: 'updated_at' },
        { title: '', key: 'actions', sortable: false },
      ],
      cmoCombinedDocumentDetailHeader: [
        { title: 'Max Combine', value: 'max_combine' },
        { title: 'Ship To', key: 'ship_to_id' },
        { title: 'Ship To Combined', key: 'ship_to_combined_id' },
      ],
      cmoCombinedDocumentDetail: [],
      shipmentTypes:['Single Drop', 'Multi Drop'],
      editConfig: false,
      selectedType: null,
      historyItems: [
        { time: "5pm", text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, eaque." },
        { time: "5pm", text: "Lorem elit. Quia, eaque." },
        { time: "5pm", text: "Lorem ipsum dolor sit amet, Quia, eaque." },
      ],
      notesItems: [
        { time: "5pm", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, aliquid unde." },
        { time: "5pm", text: "Lorem ipsum dolor sit amet, Quia, eaque." },
      ],
      toggle: null,
      searchQuery: '',
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
    filteredShipTo() {
      if (!this.searchQuery) return this.shipTo;
      const query = this.searchQuery.toLowerCase();
      return this.shipTo.filter(d => 
        d.name.toLowerCase().includes(query) || d.short_name.toLowerCase().includes(query) ||
        (d.email && d.email.toLowerCase().includes(query)) ||
        (d.code && d.code.includes(query))
      );
    },
    shipToId: {
      get() {
        return this.cmo_combine.ship_to_id ?? null;;
      },
      set(value) {
        this.cmo_combine.ship_to_id = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    combinedShipToId: {
      get() {
        return this.cmo_combine?.ship_to_combined_id ?? null;
      },
      set(value) {
        this.cmo_combine.ship_to_combined_id = value && typeof value === 'object' ? value.id : value ?? null;
      }
    }
  },
  async mounted() {

    this.fetchCoverage();
    this.fetchShipTo();
    this.fetchCmoConfig();

    // Fetch Default Configuration
    // try {
    //   const response = await axios.get(`/cmo_configuration`);
    //   if (response.data) {
    //     this.cmo_config = response.data;
    //   }
    // } catch (error) {
    //   const errorMessage = error.response?.data?.messages
    //     ? error.response.data.messages
    //     : error.response?.message || 'An error occurred';
    //   this.showAlert({ message: errorMessage, color: 'error' });
    // }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const response = await axios.put(`/cmo_configuration`, { rofo_config: this.cmo_config });
        this.showAlert({ message: 'Config successfully update', color: 'success' });
        if (response.data) {
          this.$router.push({ name: 'rollingForecastConfiguration' });
        } else {
          throw new Error('Failed to update configuration');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async handleSubmitConfig(config) {
      try {
        let response;

        switch (config) {
          case 'limit':
            response = await axios.put(`/cmo_config_fulfillment_limit`, { 
              cmo_config: this.cmo_config_fulfillment_limit
            });
            break;

          case 'format':
            response = await axios.put(`/cmo_config_transaction_code_formatting`, { 
              cmo_config: this.cmo_config.cmo_prefix 
            });
            break;

          default:
            throw new Error('Invalid configuration type specified');
        }

        if (response.data) {
          this.showAlert({ message: 'Config successfully update', color: 'success' });
          this.$router.push({ name: 'cmoConfiguration' });
          this.fetchCmoConfig();
        } else {
          throw new Error('Failed to update configuration');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    // submitForm() {
    //   this.$refs.form.validate(); // Trigger form validation
    //   this.handleSubmit(); // Call the submit method
    // },
    backToTable() {
      this.editConfig = false;
      this.cmo_combine = {
        max_combine: 0,
        ship_to_id: null,
        ship_to_combined_id: null,
      }
    },
    itemProps (item) {
      return {
        title: item.name,
        subtitle: `Type : ${item.coverage_type}, Parent: ${item.parent.name || 'None'}`,
      }
    },
    addNewCmoFulfillment() {
      let newLimit = {
        area: null,
        fulfillment_limit: null,
      }
      this.cmo_config_fulfillment_limits.push(newLimit); 
    },
    confirmDelete(item, type) {
      this.itemToDelete = item;
      this.itemNeedToDelete = type;
      this.showConfirmation = true;
    },
    async fetchCoverage() {
      try {
        const area = await axios.get('/coverage?type=area');
        this.areas = area.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchShipTo() {
      try {
      const response = await axios.get(`/distributor?cmo=true`);
        this.shipTo = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchCmoConfig() {
      try {
        const format = await axios.get(`/cmo_config_transaction_code_formatting`);
        if (format.data) {
          this.cmo_config.cmo_prefix = format.data;
        } 
        const limit = await axios.get(`/cmo_config_fulfillment_limit`);
        if (limit.data) {
          this.cmo_config.cmo_fulfillment_limits = limit.data;
        } 

        const combined = await axios.get(`/cmo_config_combine_document`);
        if (combined.data) {
          this.cmo_config.cmo_combine_document = combined.data;
        } 
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    customizeCombineConfig(item) {
      this.selectedType = item;
      this.editConfig = true;
      this.cmo_config.shipment_type = item.id;
      this.cmoCombinedDocumentDetail = item.cmo_config_combine_documents_details;
    },
    async addNewCombineDocument() {
      try {
        let response = await axios.put(`/cmo_config_combine_document`, { 
          cmo_config: {
            id: this.selectedType.id,
            shipment_type: this.selectedType.shipment_type,
            cmo_config_combine_document_details_attributes: [this.cmo_combine]
          }
        });

        if (response.data) {
          this.showAlert({ message: 'Config successfully update', color: 'success' });
          // this.$router.push({ name: 'cmoConfiguration' });
          this.editConfig = false;
          this.fetchCmoConfig();
        } else {
          throw new Error('Failed to update configuration');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
    
  }
};
</script>

<style scoped>
.bg-basil {
  /* background-color: #FFFBE6 !important; */
}
.text-basil {
  color: #356859 !important;
}
</style>
