<template>
  <v-row>
    <!-- Filters with custom labels at the top -->
    <v-col cols="4">
      <div class="custom-label">Period</div>
      <v-select
        v-model="filters.period"
        :items="periodItems"
        item-title="title"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">Region</div>
      <v-select
        v-model="filters.region"
        :items="regionItems"
        item-title="name"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">Area</div>
      <v-select
        v-model="filters.area"
        :items="areaItems"
        item-title="name"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label pt-5">ASS Name</div>
      <v-combobox
        v-model="assName"
        variant="outlined"
        density="compact"
        :items="filteredAssName"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">Product Division</div>
      <v-select
        v-model="filters.product_division"
        :items="productDivisionItems"
        item-title="name"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <!-- Generate & Export Buttons -->
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="secondary" @click="downloadReport">EXPORT</v-btn>
      <v-btn color="primary" class="ml-2" @click="fetchRofoExtract">GENERATE</v-btn>
    </v-col>
  </v-row>

  <!-- Table -->
  <v-row v-if="rofoExtract.length">
    <v-col cols="12">
      <v-data-table
        :items="rofoExtract"
        :headers="headers"
        :sort-by.sync="[sortKey]"
        :sort-desc.sync="[sortDesc]"
        class="elevation-1"
      >
        <template v-slot:[`item`]="{ item }">
          <tr>
            <td v-for="header in headers" :key="header.key">{{ item[header.key] }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>

  <v-overlay v-if="loading" persistent class="align-center justify-center">
    <v-progress-circular color="primary" size="120" indeterminate />
  </v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { title: 'Region', key: 'region' },
        { title: 'Area', key: 'area' },
        { title: 'ASS Name', key: 'ass_name' },
        { title: 'Period', key: 'period' },
        { title: 'Product Division', key: 'product_division' },
        { title: 'Product Category 1', key: 'product_category_1' },
        { title: 'Product Category 2', key: 'product_category_2' },
        { title: 'Product Number', key: 'product_number' },
        { title: 'Product Description', key: 'product_description' },
        { title: 'Qty', key: 'qty' }
      ],
      rofoExtract: [],
      loading: false,
      sortKey: 'region', // Set default sort key
      sortDesc: false,

      // Filters
      filters: {
        period: null,
        region: null,
        area: null,
        ass_name: null,
        product_division: null
      },

      // Filter data
      periodItems: [],
      regionItems: [],
      areaItems: [],
      assNameItems: [],
      productDivisionItems: [],
      searchQuery: ''
    };
  },
  computed: {
    filteredAssName() {
      if (!this.searchQuery) return this.assNameItems;
      const query = this.searchQuery.toLowerCase();
      return this.assNameItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    assName: {
      get() {
        return this.filters.ass_name ?? null;;
      },
      set(value) {
        this.filters.ass_name = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
  },
  methods: {
    async fetchFilterData() {
      this.loading = true;
      try {
        const response = await axios.get('/report/filter-options?type=rofo_extract');
        this.periodItems = response.data.periods;
        this.regionItems = response.data.regions;
        this.areaItems = response.data.areas;
        this.assNameItems = response.data.ass_names;
        this.productDivisionItems = response.data.product_divisions;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchRofoExtract() {
      this.loading = true;
      try {
        const response = await axios.get('/report/rofo_extract', { params: this.filters });
        this.rofoExtract = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async downloadReport() {
      this.loading = true;
      try {
        const response = await axios.get(`/report/rofo_extract?export=true`, {
          params: this.filters,
          responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'rofo_extract.xlsx';
        link.click();

        // Cleanup
        URL.revokeObjectURL(url);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
  },
  mounted() {
    this.fetchFilterData();
  }
};
</script>

<style scoped>
.white-background .v-select__slot {
  background-color: white !important;
}

.white-background .v-select__selections {
  background-color: white !important;
}

.white-background .v-select-list {
  background-color: white !important;
}

.custom-label {
  font-weight: bold;
  margin-bottom: 8px;
}
</style>
