<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newProductCategory') }}</v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <data-table :headers="headers" :items="productCategory" :actions="tableActions" :loading="loading" />
    </v-col>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'Name', key: 'name', align: 'start', clickable: true , sortable: true},
        { title: 'Code', key: 'code', align: 'start', clickable: true, sortable: true },
        { title: 'Parent', value: 'parent.name', align: 'start', clickable: true, sortable: true },
        { title: '', key: 'actions', align: 'end', sortable: false },
      ],
      productCategory: [],
      tableActions: {
        edit: this.navigateToForm,
        delete: this.deleteCategory,
        view: this.navigateToForm
      },
      loading: false
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'productCategory-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchCategory() {
      this.loading = true;
      try {
        const response = await axios.get('/product_category');
        this.productCategory = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    navigateToForm(action, productCategory = { id: null }) {
      if (action == 'view') {
        this.$router.push({ name: 'productCategory-view', params: { action, id: productCategory.id } });
      } else {
        this.$router.push({ name: 'productCategory-form', params: { action, id: productCategory.id } });
      }
    },
    async deleteCategory(productCategoryToDelete) {
      try {
        const response = await axios.delete(`/product_category/${productCategoryToDelete.id}`);

        if (response.data) {
          this.productCategory = this.productCategory.filter(u => u.id !== productCategoryToDelete.id);

          this.showAlert({ message: 'Success', color: 'success' });
        } else {
          throw new Error('Failed to delete Product Category');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message
          ? error.response.data.message
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  mounted() {
    this.fetchCategory();
  }
};
</script>