<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Username</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="user.username" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Employee</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="selectedEmployeeId" :items="employees" item-value="id" item-title="full_name"
                placeholder="Select Employee" required></v-select>
            </v-col>
          </v-row>

          <!-- Role Selection -->
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">User Role</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="selectedRoleId" :items="roles" item-value="id" item-title="name"
                placeholder="Select Role" required></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Coverage</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" :item-props="itemProps" v-model="selectedCoverageId" :items="coverages" item-value="id" item-title="name"
                placeholder="Select Coverage" required></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Non-Transactional User</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-switch
                v-model="user.is_admin_only"
                color="primary"
                hide-details
                inset
              ></v-switch>
            </v-col>
          </v-row>
          
          <div v-if="$route.params.action === 'add'">
            <v-divider></v-divider>
            <v-row class="mt-2">
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">New Password</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field
                    v-model="user.new_password"
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'"
                    placeholder="Enter your password"
                    outlined
                    @click:append-inner="visible = !visible"
                    required
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">New Password Confirmation</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field
                    v-model="user.password_confirmation"
                    :append-inner-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible2 ? 'text' : 'password'"
                    placeholder="Re-Enter your password"
                    outlined
                    @click:append-inner="visible2 = !visible2"
                    required
                  ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-row class="py-5">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="user.status" :items="statusOption" placeholder="" required></v-select>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="changePassword" color="primary" class="mr-2" v-if="$route.params.action === 'edit'">Change Password</v-btn>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>


  <v-dialog
      v-model="changePasswordDialog"
      max-width="600"
    >
      <v-card
        prepend-icon="mdi-lock"
        title="User Password Management"
      >
        <v-card-text>
          <v-row class="mt-2">
            <v-col lg="6" md="6" sm="6" cols="12">
              <label class="custom-label">Current Password</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field
                  v-model="user.current_password"
                  :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible ? 'text' : 'password'"
                  placeholder="Enter your current password"
                  outlined
                  @click:append-inner="visible = !visible"
                  required
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
                <v-col lg="6" md="6" sm="6" cols="12">
              <label class="custom-label">New Password</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field
                  v-model="user.new_password"
                  :append-inner-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible1 ? 'text' : 'password'"
                  placeholder="Enter your New password"
                  outlined
                  @click:append-inner="visible1 = !visible1"
                  required
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col lg="6" md="6" sm="6" cols="12">
              <label class="custom-label">New Password Confirmation</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field
                  v-model="user.password_confirmation"
                  :append-inner-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible2 ? 'text' : 'password'"
                  placeholder="Re-Enter your New password"
                  outlined
                  @click:append-inner="visible2 = !visible2"
                  required
                ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text="Close"
            variant="plain"
            @click="changePasswordDialog = false"
          ></v-btn>

          <v-btn
            color="primary"
            text="Save"
            variant="tonal"
            @click="savePassword"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      user: {
        id: null,
        username: '',
        is_admin_only: false,
        status: 'Active',
        coverage: {},
        role: {},
        current_password: '',
        new_password: '',
        password_confirmation: ''
      },
      statusOption: ['Active', 'Inactive'],
      coverages: [],
      roles: [],
      employees: [],
      selectedEmployeeId: null,
      selectedRoleId: null,
      selectedCoverageId: null,
      visible: false,
      visible1: false,
      visible2: false,
      changePasswordDialog: false
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchCoverage();
    this.fetchEmployee();
    
    // Fetch roles
    try {
      const response = await axios.get('/role'); // Endpoint for fetching roles
      this.roles = response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.messages
        ? error.response.data.messages
        : error.response?.message || 'An error occurred';

      this.showAlert({ message: errorMessage, color: 'error' });
    }

    // Fetch user details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/users/${id}`);
        this.user = response.data;
        this.selectedEmployeeId = this.user?.employee?.id || null; // Set selectedEmployeeId based on user data
        this.selectedRoleId = this.user.role.id; // Set selectedRoleId based on user data
        this.selectedCoverageId = this.user.coverage.id
      } catch (error) {
      console.log(error)
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const userData = {
          username: this.user.username,
          password: this.user.new_password,
          password_confirmation: this.user.password_confirmation,
          role_id: this.selectedRoleId, // Include role_id in submit data
          is_admin_only: this.user.is_admin_only,
          employee_id: this.selectedEmployeeId,
          coverage_id: this.selectedCoverageId,
          status: this.user.status
        };

        if (this.isAdding) {
          if (this.user.new_password !== this.user.password_confirmation) {
            this.showAlert({ message: "Password and confirmation do not match", color: "error" });
            return;
          } else {
            const response = await axios.post('/signup', { user: userData });
            this.showAlert({ message: 'User data successfully saved', color: 'success' });
            if (response.data) {
              this.$router.push({ name: 'user' });
            } else {
              throw new Error(response.message);
            }
          }
        } else {
          const response = await axios.put(`/users/${this.user.id}`, { user: userData });
          if (response.status === 200) {
            this.$router.push({ name: 'user' });
            this.showAlert({ message: response.data.message, color: 'success' });
          } else {
            this.showAlert({ message: response.data.message, color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message
          ? error.response.data.message
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchCoverage() {
      try {
        const response = await axios.get('/coverage');
        this.coverages = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchEmployee() {
      try {
        const response = await axios.get('/employee');
        this.employees = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async findCoverageByType(type) {
      try {
        const response = await axios.get(`/coverage?type=${(type).toLowerCase()}`);
        this.coverages = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An asdfasdf asdf';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    changePassword() {
      this.changePasswordDialog = true;
    },
    async savePassword() {
      try {
        const userData = {
          current_password: this.user.current_password,
          new_password: this.user.new_password,
          password_confirmation: this.user.password_confirmation
        };

        const response = await axios.put(`/users/${this.user.id}/change_password`, { user: userData });
        this.showAlert({ message: 'User password successfully update', color: 'success' });
        if (response.data) {
          this.$router.push({ name: 'user' });
        } else {
          throw new Error('Failed to update user');
        }
        console.log(userData)
      } catch (error) {
        console.log(error);
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    itemProps (item) {
        return {
          title: item.name,
          subtitle: `Type : ${item.coverage_type}, Parent: ${item.parent?.name || 'None'}`,
        }
      },
  }
};
</script>
