<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card>
        <template v-slot:title>
          <v-row class="py-3" v-if="cmo.is_combined_document">
            <v-tabs v-model="selectedHeadTab" bg-color="white" show-arrows
              @update:modelValue="(value) => updateHeadTabValue(value, headTabsData.findIndex(tab => tab === value))">
              <v-tab v-for="(data, index) in headTabsData" :key="data" :text="data.ship_to"
                :value="data"></v-tab>
            </v-tabs>
          </v-row>

          <!-- <div class="text-caption">{{ cmo.status || '' }}</div> -->

          <div>
            <v-chip class="ma-2" label>
              <v-icon icon="mdi-label" start></v-icon>
              {{ cmo.status || '' }}
            </v-chip>
          </div>
        </template>

        <v-divider></v-divider>

        <v-card-text>
            <div>
                <v-form ref="form" @submit.prevent="handleSubmit">
                    <v-row v-if="!cmo.is_combined_document">
                        <v-col cols="6" md="6">
                            <v-row>
                                <v-col cols="3">
                                    <label class="custom-label">Transaction Type</label>
                                </v-col>
                                <v-col cols="8">
                                    <v-select :readonly="cmo.is_combined_document" v-model="cmo.transaction_type_id"
                                    :items="transactionTypes" item-value="id" item-title="name" @update:modelValue="changeCmoType($event, cmo.period_id, cmo.ship_to)"></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" v-if="!cmo.is_combined_document">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">PO Distributor</label>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="cmo.purchase_order_number" bgColor="none"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Number</label>
                                </v-col>
                                <v-col>
                                    <v-text-field readonly placeholder="Automatic" v-model="cmo.transaction_number"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- This section only shows on combined documents  -->
                    <v-row v-if="cmo.is_combined_document">
                      <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Number</label>
                                </v-col>
                                <v-col>
                                    <v-text-field readonly placeholder="Automatic" v-model="cmo.transaction_number"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="cmo.is_combined_document">
                      <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Date</label>
                                </v-col>
                                <v-col>
                                  <v-date-input v-model="cmo.transaction_date" variant="outlined" density="compact"
                                    bgColor="none" :multiple="false" prepend-icon="" hide-details
                                    prepend-inner-icon="$calendar" 
                                    :allowed-dates="(date) => transactionDateFilter(date, cmo.period_id.date)"></v-date-input>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="cmo.is_combined_document">
                      <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Period</label>
                                </v-col>
                                <v-col>
                                  <v-select v-model="cmo.period_id"
                                    :items="periods" return-object item-value="id" item-title="title" ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- -- -->
                    <v-row v-if="!cmo.is_combined_document">
                        <v-col cols="6" md="6">
                          <v-row v-if="!cmo.is_combined_document">
                            <v-col cols="3">
                                <label class="custom-label">Distributor Name</label>
                            </v-col>
                            <v-col cols="8">
                                <v-select readonly v-model="cmo.sold_to" :items="parentDistributors" return-object item-title="name"
                                    placeholder="" @update:modelValue="soldToChanged($event)"></v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="3" md="3" v-if="!cmo.is_combined_document">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">PO Date</label>
                                </v-col>
                                <v-col>
                                    <v-date-input v-model="cmo.purchase_order_date" variant="outlined" density="compact"
                                        bgColor="none" :multiple="false" prepend-icon="" hide-details
                                        prepend-inner-icon="$calendar" @update:modelValue="poDateSelected"></v-date-input>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Date</label>
                                </v-col>
                                <v-col>
                                    <!-- <v-text-field v-model="cmo.transaction_date"></v-text-field> -->
                                    <v-date-input v-model="cmo.transaction_date" variant="outlined" density="compact"
                                        bgColor="none" :multiple="false" prepend-icon="" hide-details
                                        prepend-inner-icon="$calendar" 
                                        :allowed-dates="(date) => transactionDateFilter(date, new Date(cmo.period_id.date))"></v-date-input>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="!cmo.is_combined_document">
                        <v-col cols="6" md="6">
                          <v-row v-if="!cmo.is_combined_document">
                                <v-col cols="3">
                                    <label class="custom-label">Distributor Address</label>
                                </v-col>
                                <v-col cols="8">
                                    <v-textarea readonly variant="outlined" rows="4" v-model="cmo.sold_to_address" 
                                        bgColor="none"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" v-if="!cmo.is_combined_document">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">PO Expired Date</label>
                                </v-col>
                                <v-col>
                                    <v-date-input v-model="cmo.purchase_order_expired_date" variant="outlined" density="compact"
                                        bgColor="none" :multiple="false" prepend-icon="" hide-details
                                        prepend-inner-icon="$calendar" @update:modelValue="poExpiredDateSelected"></v-date-input>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Transaction Period</label>
                                </v-col>
                                <v-col>
                                  <v-select :readonly="cmo.is_combined_document" v-model="cmo.period_id" @update:modelValue="changeCmoType(cmo.transaction_type_id, $event, cmo.ship_to)"
                                    :items="periods" return-object item-title="title" ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <label class="custom-label font-weight-bold">Shipping Information</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="6">
                            <v-row>
                                <v-col cols="3">
                                    <label class="custom-label">Ship to Name</label>
                                </v-col>
                                <v-col cols="8">
                                  <!-- <v-select :readonly="cmo.is_combined_document" v-model="cmo.ship_to" :items="distributors" return-object item-title="name" @update:modelValue="changeCmoType(cmo.transaction_type_id, cmo.period_id, $event)"
                                  placeholder=""></v-select> -->
                                  <v-combobox
                                    v-model="cmo.ship_to"
                                    variant="outlined"
                                    density="compact"
                                    :items="filteredShipTo"
                                    item-value="id"
                                    item-title="name"
                                    placeholder="Search or select distributor"
                                    clearable
                                    hide-no-data
                                    @update:modelValue="val => val && val.id ? changeCmoType(cmo.transaction_type_id, cmo.period_id, val) : null"
                                    :search-input.sync="searchQuery"
                                  >
                                  </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3"  v-if="!cmo.is_combined_document">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Area</label>
                                </v-col>
                                <v-col>
                                    <v-text-field readonly v-model="cmo.ship_to_area" ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="6">
                            <v-row>
                                <v-col cols="3">
                                    <label class="custom-label">Shipment Type</label>
                                </v-col>
                                <v-col cols="8">
                                    <v-select :readonly="cmo.is_combined_document" v-model="cmo.shipment_type" :disabled="directTransaction"
                                    :items="shipmentTypes"></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" md="3" v-if="!cmo.is_combined_document">
                            <v-row>
                                <v-col>
                                    <label class="custom-label">Region</label>
                                </v-col>
                                <v-col>
                                    <v-text-field readonly v-model="cmo.ship_to_region" ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-row v-if="!cmo.is_combined_document || cmo.is_combined_document && cmo.shipment_type === 'Single Drop'">
                            <v-col cols="3">
                                <label class="custom-label">Ship to Address</label>
                            </v-col>
                            <v-col cols="8">
                                <v-textarea rows="4" v-model="cmo.ship_to_address"  bgColor="none"></v-textarea>
                            </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="6">
                          <v-row v-if="!cmo.is_combined_document || cmo.is_combined_document && cmo.shipment_type === 'Single Drop'">
                              <v-col cols="3">
                                  <label class="custom-label">Ship to City</label>
                              </v-col>
                              <v-col cols="8">
                                  <v-text-field v-model="cmo.ship_to_city"  bgColor="none"></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                    </v-row>
                </v-form>
            </div>
        </v-card-text>

        <template v-slot:append>
          <v-btn color="primary" class="ml-2" @click="submitForm(0)" :disabled="!draftDocument && combiningProcess">Save</v-btn>
          <!-- <v-btn color="primary" class="ml-2" @click="submitForm(1)" :disabled="!draftDocument || combiningProcess">Submit</v-btn> -->
          <v-btn color="primary" class="ml-2" @click="triggerFileInput" :disabled="!draftDocument && combiningProcess" prepend-icon="mdi-upload">Attachment</v-btn>
          <input type="file" ref="fileInput" style="display: none" @change="attachDocument" />
          <!-- <v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" v-if="!asApprover || cmo.status === 'Awaiting for Approval'">
            {{ $route.params.action === 'add' || $route.params.action === 'combine' ? $t('button.save') : $t('button.update') }}

            <v-menu activator="parent" density="compact">
              <v-list density="compact">
                <v-list-item density="compact" title="Save as draft" value="edit" @click="submitForm(0)"></v-list-item>
                <v-list-item density="compact" title="Save & Submit" value="submit" @click="submitForm(1)"></v-list-item>
              </v-list>
            </v-menu>
          </v-btn> -->
        </template>
    </v-card>

    <div class="pa-3"></div>
    <v-card>
        <template v-slot:title>
          <v-row class="pa-2">
            <v-col>
              <v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-fullscreen"
                @click="isBottomSheetOpen = true">
                Full Screen
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="4">
              <v-text-field placeholder="Search" v-model="searchQuery" density="compact" append-inner-icon="mdi-magnify"></v-text-field>
            </v-col>
          </v-row>
        </template>

        <v-divider></v-divider>

        <v-card-text>
            <v-data-table style="display: none;" hide-default-footer fixed-header :headers="headers" :items="selected_confirm_monthly_order_details_attributes" item-key="name"
                class="frozen-columns-table" :items-per-page="100" min-height="45vh">

                <template v-slot:item.rolling_forecast_outstanding="{ item }">
                  <span>
                    {{ item.rolling_forecast_outstanding < 0 
                      ? `(${Math.abs(item.rolling_forecast_outstanding)})` 
                      : item.rolling_forecast_outstanding }}
                  </span>
                </template>

                <!-- Customize Header Req.Delivery Date-->
                <template v-slot:header.v1="{ header }">
                  <div v-if="cmo.is_combined_document">
                    {{ formatDate(cmo.w1_request_delivery_date) }}
                  </div>
                  <div v-else>
                    <v-date-input v-model="cmo.w1_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon="" 
                        :allowed-dates="(date) => dateFilter(date, cmo.w1_request_delivery_date)"
                        :disabled="!cmo.w1_active"></v-date-input>
                  </div>  
                </template>

                <template v-slot:header.v2="{ header }">
                  <div v-if="cmo.is_combined_document">
                    {{ formatDate(cmo.w2_request_delivery_date) }}
                  </div>
                  <div v-else>
                    <v-date-input v-model="cmo.w2_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon=""
                        :allowed-dates="(date) => dateFilter(date, cmo.w2_request_delivery_date)"
                        :disabled="!cmo.w2_active"></v-date-input>
                  </div>
                </template>

                <template v-slot:header.v3="{ header }">
                  <div v-if="cmo.is_combined_document">
                    {{ formatDate(cmo.w3_request_delivery_date) }}
                  </div>
                  <div v-else>
                    <v-date-input v-model="cmo.w3_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon=""
                        :allowed-dates="(date) => dateFilter(date, cmo.w3_request_delivery_date)"
                        :disabled="!cmo.w3_active"></v-date-input>
                  </div>
                </template>

                <template v-slot:header.v4="{ header }">
                  <div v-if="cmo.is_combined_document">
                    {{ formatDate(cmo.w4_request_delivery_date) }}
                  </div>
                  <div v-else>
                    <v-date-input v-model="cmo.w4_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                        :multiple="false" prepend-icon="" prepend-inner-icon=""
                        :allowed-dates="(date) => dateFilter(date, cmo.w4_request_delivery_date)"
                        :disabled="!cmo.w4_active"></v-date-input>
                  </div>
                </template>
                <!-- // -->

                <!-- Customize Header Valid from - Valid To-->
                <template v-slot:header.w1="{ header }">
                    <v-row class="py-4" v-if="!cmo.is_combined_document">
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w1_valid_from" variant="outlined" density="compact" bgColor="none"
                                  :multiple="false" prepend-icon="" prepend-inner-icon="" 
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                          ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                          : null"
                                  :disabled="!cmo.w1_active"
                                 ></v-date-input>
                        </v-col>
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w1_valid_to" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                        ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                        : null"
                                :disabled="!cmo.w1_active"
                            ></v-date-input>
                        </v-col>
                    </v-row>
                    <v-row class="py-4" v-else>
                      <v-col>
                          {{ formatDate(cmo.w3_valid_from) }}
                      </v-col>
                      <v-col>
                          {{ formatDate(cmo.w3_valid_to) }}
                      </v-col>
                    </v-row>
                </template>

                <template v-slot:header.w2="{ header }">
                    <v-row v-if="!cmo.is_combined_document">
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w2_valid_from" variant="outlined" density="compact" bgColor="none" class="px-4"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                      ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                      : null"
                              :disabled="!cmo.w2_active">
                            </v-date-input>
                        </v-col>
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w2_valid_to" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                  ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                  : null"
                              :disabled="!cmo.w2_active">
                            </v-date-input>
                        </v-col>
                    </v-row>
                    <v-row class="py-4" v-else>
                      <v-col>
                          {{ formatDate(cmo.w2_valid_from) }}
                      </v-col>
                      <v-col>
                          {{ formatDate(cmo.w2_valid_to) }}
                      </v-col>
                    </v-row>
                </template>

                <template v-slot:header.w3="{ header }">
                      <v-row v-if="!cmo.is_combined_document">
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w3_valid_from" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                  ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                  : null"
                              :disabled="!cmo.w3_active">
                            </v-date-input>
                        </v-col>
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w3_valid_to" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                  ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                  : null"
                              :disabled="!cmo.w3_active">
                            </v-date-input>
                        </v-col>
                    </v-row>
                    <v-row class="py-4" v-else>
                      <v-col>
                          {{ formatDate(cmo.w3_valid_from) }}
                      </v-col>
                      <v-col>
                          {{ formatDate(cmo.w3_valid_to) }}
                      </v-col>
                    </v-row>
                </template>

                <template v-slot:header.w4="{ header }">
                    <v-row v-if="!cmo.is_combined_document">
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w4_valid_from" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                  ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                  : null"
                              :disabled="!cmo.w4_active">
                            </v-date-input>
                        </v-col>
                        <v-col cols="6">
                            <v-date-input v-model="cmo.w4_valid_to" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                  ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                  : null"
                              :disabled="!cmo.w4_active">
                            </v-date-input>
                        </v-col>
                    </v-row>
                    <v-row class="py-4" v-else>
                      <v-col>
                          {{ formatDate(cmo.w4_valid_from) }}
                      </v-col>
                      <v-col>
                          {{ formatDate(cmo.w4_valid_to) }}
                      </v-col>
                    </v-row>
                </template>
                <!-- // -->

                <template v-slot:item.week1="{ item }">
                    <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                      {{ formatNumber(item.w1_qty) }}
                    </div>
                    <div v-else>
                      <vue-number v-model="item.w1_qty" v-bind="number" :disabled="!cmo.w1_active"
                      @change="updateValue(item, 'grand_total_qty', 1)" class="custom-input text-right ma-2"></vue-number>
                    </div>
                    
                </template>

                <template v-slot:item.week2="{ item }">
                    <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                      {{ formatNumber(item.w2_qty) }}
                    </div>
                    <div v-else>
                      <vue-number v-model="item.w2_qty" v-bind="number" :disabled="!cmo.w2_active"
                      @change="updateValue(item, 'grand_total_qty', 2)" class="custom-input text-right ma-2"></vue-number>
                    </div>
                </template>

                <!-- Define slot for week3 -->
                <template v-slot:item.week3="{ item }">
                    <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                      {{ formatNumber(item.w3_qty) }}
                    </div>
                    <div v-else>
                      <vue-number v-model="item.w3_qty" v-bind="number" :disabled="!cmo.w3_active"
                      @change="updateValue(item, 'grand_total_qty', 3)" class="custom-input text-right ma-2"></vue-number>
                    </div>
                </template>

                <!-- Define slot for week4 -->
                <template v-slot:item.week4="{ item }">
                    <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                      {{ formatNumber(item.w4_qty) }}
                    </div>
                    <div v-else>
                      <vue-number v-model="item.w4_qty" v-bind="number" :disabled="!cmo.w4_active"
                      @change="updateValue(item, 'grand_total_qty', 4)" class="custom-input text-right ma-2"></vue-number>
                    </div>
                </template>

                <!-- Define slot for remarks -->
                <template v-slot:item.remarks="{ item }">
                    <input type="text" v-model="item.remarks" @change="updateValue(item, 'grand_total_qty')" class="custom-input" />
                </template>

                <!-- Append slot for the "Total" row -->
                <template v-slot:body.append>
                    <!-- GRAND TOTAL ORDER QTY -->
                    <tr class="elevation-0">
                        <td></td>
                        <td></td>
                        <td class="text-right font-bold">GRAND TOTAL ORDER QTY</td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <!-- Total for qty_m0 -->
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <!-- Total for qty_m1 -->
                        <td class="text-center">{{ formatNumber(grandTotalQty) }}</td>
                        <td class="text-center">{{ formatNumber(w1TotalQty) }}</td>
                        <td class="text-center">{{ formatNumber(w2TotalQty) }}</td>
                        <td class="text-center">{{ formatNumber(w3TotalQty) }}</td>
                        <td class="text-center">{{ formatNumber(w4TotalQty) }}</td>
                        <td class="text-center"></td>
                    </tr>

                    <!-- Total Weight (Kg) -->
                    <tr class="elevation-0">
                        <td></td>
                        <td></td>
                        <td class="text-right font-bold">TOTAL WEIGHT (Kg)</td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <!-- Total for qty_m0 -->
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <!-- Total for qty_m1 -->
                        <td class="text-center">{{  formatNumber(totalWeight.toFixed(2)) }}</td>
                        <td class="text-center">{{ formatNumber(w1TotalWeight.toFixed(2)) }}</td>
                        <td class="text-center">{{ formatNumber(w2TotalWeight.toFixed(2)) }}</td>
                        <td class="text-center">{{ formatNumber(w3TotalWeight.toFixed(2)) }}</td>
                        <td class="text-center">{{  formatNumber(w4TotalWeight.toFixed(2)) }}</td>
                        <td class="text-center"></td>
                    </tr>
                </template>
            </v-data-table>

            <div class="mt-5" >
              <div class="table-wrapper">
                <table class="custom-table">
                  <thead>
                    <!-- First Header Row -->
                    <tr>
                      <th colspan="7" class="merge-cell">REQ. DELIVERY DATE</th>
                      <th>
                        <div v-if="cmo.is_combined_document">
                          {{ formatDate(cmo.w1_request_delivery_date) }}
                        </div>
                        <div v-else>
                          <v-date-input v-model="cmo.w1_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon="" 
                              :allowed-dates="(date) => dateFilter(date, cmo.w1_request_delivery_date)"
                              :disabled="!cmo.w1_active"></v-date-input>
                        </div>  
                      </th>
                      <th>
                        <div v-if="cmo.is_combined_document">
                          {{ formatDate(cmo.w2_request_delivery_date) }}
                        </div>
                        <div v-else>
                          <v-date-input v-model="cmo.w2_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="(date) => dateFilter(date, cmo.w2_request_delivery_date)"
                              :disabled="!cmo.w2_active"></v-date-input>
                        </div>
                      </th>
                      <th>
                        <div v-if="cmo.is_combined_document">
                          {{ formatDate(cmo.w3_request_delivery_date) }}
                        </div>
                        <div v-else>
                          <v-date-input v-model="cmo.w3_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="(date) => dateFilter(date, cmo.w3_request_delivery_date)"
                              :disabled="!cmo.w3_active"></v-date-input>
                        </div>
                      </th>
                      <th>
                        <div v-if="cmo.is_combined_document">
                          {{ formatDate(cmo.w4_request_delivery_date) }}
                        </div>
                        <div v-else>
                          <v-date-input v-model="cmo.w4_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                              :multiple="false" prepend-icon="" prepend-inner-icon=""
                              :allowed-dates="(date) => dateFilter(date, cmo.w4_request_delivery_date)"
                              :disabled="!cmo.w4_active"></v-date-input>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                    <!-- Second Header Row -->
                    <tr>
                      <th colspan="7" class="merge-cell">Valid From - Valid To</th>
                      <th>
                        <v-row class="py-4" v-if="!cmo.is_combined_document">
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w1_valid_from" variant="outlined" density="compact" bgColor="none"
                                    :multiple="false" prepend-icon="" prepend-inner-icon="" 
                                    :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                            ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                            : null"
                                    :disabled="!cmo.w1_active"
                                  ></v-date-input>
                          </v-col>
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w1_valid_to" variant="outlined" density="compact" bgColor="none"
                                  :multiple="false" prepend-icon="" prepend-inner-icon=""
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                          ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                          : null"
                                  :disabled="!cmo.w1_active"
                              ></v-date-input>
                          </v-col>
                        </v-row>
                        <v-row class="py-4" v-else>
                          <v-col>
                              {{ formatDate(cmo.w3_valid_from) }}
                          </v-col>
                          <v-col>
                              {{ formatDate(cmo.w3_valid_to) }}
                          </v-col>
                        </v-row>
                      </th>
                      <th>
                        <v-row v-if="!cmo.is_combined_document">
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w2_valid_from" variant="outlined" density="compact" bgColor="none" class="px-4"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                        ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                        : null"
                                :disabled="!cmo.w2_active">
                              </v-date-input>
                          </v-col>
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w2_valid_to" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                    ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                    : null"
                                :disabled="!cmo.w2_active">
                              </v-date-input>
                          </v-col>
                        </v-row>
                        <v-row class="py-4" v-else>
                          <v-col>
                              {{ formatDate(cmo.w2_valid_from) }}
                          </v-col>
                          <v-col>
                              {{ formatDate(cmo.w2_valid_to) }}
                          </v-col>
                        </v-row>
                      </th>
                      <th>
                        <v-row v-if="!cmo.is_combined_document">
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w3_valid_from" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                    ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                    : null"
                                :disabled="!cmo.w3_active">
                              </v-date-input>
                          </v-col>
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w3_valid_to" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                    ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                    : null"
                                :disabled="!cmo.w3_active">
                              </v-date-input>
                          </v-col>
                        </v-row>
                        <v-row class="py-4" v-else>
                          <v-col>
                              {{ formatDate(cmo.w3_valid_from) }}
                          </v-col>
                          <v-col>
                              {{ formatDate(cmo.w3_valid_to) }}
                          </v-col>
                        </v-row>
                      </th>
                      <th>
                        <v-row v-if="!cmo.is_combined_document">
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w4_valid_from" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                    ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                    : null"
                                :disabled="!cmo.w4_active">
                              </v-date-input>
                          </v-col>
                          <v-col cols="6">
                              <v-date-input v-model="cmo.w4_valid_to" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                    ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                    : null"
                                :disabled="!cmo.w4_active">
                              </v-date-input>
                          </v-col>
                        </v-row>
                        <v-row class="py-4" v-else>
                          <v-col>
                              {{ formatDate(cmo.w4_valid_from) }}
                          </v-col>
                          <v-col>
                              {{ formatDate(cmo.w4_valid_to) }}
                          </v-col>
                        </v-row>
                      </th>
                      <th></th>
                    </tr>
                    <!-- Third Header Row -->
                    <tr>
                      <th>SKU</th>
                      <th>Product Name</th>
                      <th>Product Nickname</th>
                      <th>Product Group</th>
                      <th>ROFO Total</th>
                      <th>ROFO Outstanding</th>
                      <th>Grand Total</th>
                      <th>Week 1</th>
                      <th>Week 2</th>
                      <th>Week 3</th>
                      <th>Week 4</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Iterate through grouped data -->
                    <template v-for="(group, groupIndex) in filteredProducts" :key="groupIndex">
                      <!-- Render each product in the group -->
                      <tr v-for="(item, index) in group.items" :key="index" :class="{ 'highlight-promotion': isProductInPromotion(item.product_id) }">
                        <!-- SKU -->
                        <td>{{ item.sku }}</td>

                        <!-- Product Name -->
                        <td>{{ item.product_name }}</td>

                        <!-- Product Nickname -->
                        <td>{{ item.alias_name }}</td>

                        <!-- Product Group -->
                        <td>{{ item.product_group }}</td>

                        <!-- ROFO Total -->
                        <td class="text-right">{{ item.rolling_forecast_total }}</td>

                        <!-- ROFO Outstanding -->
                        <td :class="item.rolling_forecast_outstanding < 0 ? 'invalid-mark' : ''" class="text-right">
                          <span>
                            {{ item.rolling_forecast_outstanding < 0 
                              ? `(${Math.abs(item.rolling_forecast_outstanding)})` 
                              : item.rolling_forecast_outstanding }}
                          </span>
                        </td>

                        <!-- Grand Total -->
                        <td class="text-right">{{ item.grand_total_qty }}</td>

                        <!-- Week 1 -->
                        <td>
                          <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                            {{ formatNumber(item.w1_qty) }}
                          </div>
                          <div v-else>
                            <vue-number
                              v-model.number="item.w1_qty"
                              v-bind="number"
                              :disabled="!cmo.w1_active"
                              @change="updateValue(item, 'grand_total_qty', 1)"
                              class="custom-input text-right ma-2"
                            ></vue-number>
                          </div>
                        </td>

                        <!-- Week 2 -->
                        <td>
                          <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                            {{ formatNumber(item.w2_qty) }}
                          </div>
                          <div v-else>
                            <vue-number
                              v-model.number="item.w2_qty"
                              v-bind="number"
                              :disabled="!cmo.w2_active"
                              @change="updateValue(item, 'grand_total_qty', 2)"
                              class="custom-input text-right ma-2"
                            ></vue-number>
                          </div>
                        </td>

                        <!-- Week 3 -->
                        <td>
                          <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                            {{ formatNumber(item.w3_qty) }}
                          </div>
                          <div v-else>
                            <vue-number
                              v-model.number="item.w3_qty"
                              v-bind="number"
                              :disabled="!cmo.w3_active"
                              @change="updateValue(item, 'grand_total_qty', 3)"
                              class="custom-input text-right ma-2"
                            ></vue-number>
                          </div>
                        </td>

                        <!-- Week 4 -->
                        <td>
                          <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                            {{ formatNumber(item.w4_qty) }}
                          </div>
                          <div v-else>
                            <vue-number
                              v-model.number="item.w4_qty"
                              v-bind="number"
                              :disabled="!cmo.w4_active"
                              @change="updateValue(item, 'grand_total_qty', 4)"
                              class="custom-input text-right ma-2"
                            ></vue-number>
                          </div>
                        </td>

                        <!-- Remarks -->
                        <td>
                          <input
                            type="text"
                            v-model="item.remarks"
                            @change="updateValue(item, 'remarks')"
                            class="custom-input"
                          />
                        </td>
                      </tr>

                        <!-- Subtotal Row -->
                        <tr class="subtotal">
                        <td class="text-left font-weight-bold">TOTAL {{ group.category }}</td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="text-right font-weight-bold">{{ group.totals.grand_total_qty }}</td>
                        <td class="text-right font-weight-bold">{{ group.totals.w1_qty }}</td>
                        <td class="text-right font-weight-bold">{{ group.totals.w2_qty }}</td>
                        <td class="text-right font-weight-bold">{{ group.totals.w3_qty }}</td>
                        <td class="text-right font-weight-bold">{{ group.totals.w4_qty }}</td>
                        <td class="empty-column"></td>
                      </tr>
                    </template>
                  </tbody>
                  <tfoot>
                    <tr class="subtotal elevation-0">
                      <td class="text-left font-weight-bold">GRAND TOTAL ORDER QTY</td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="text-right font-weight-bold">{{ formatNumber(grandTotalQty) }}</td>
                      <td class="text-right font-weight-bold">{{ formatNumber(w1TotalQty) }}</td>
                      <td class="text-right font-weight-bold">{{ formatNumber(w2TotalQty) }}</td>
                      <td class="text-right font-weight-bold">{{ formatNumber(w3TotalQty) }}</td>
                      <td class="text-right font-weight-bold">{{ formatNumber(w4TotalQty) }}</td>
                      <td class="empty-column"></td>
                    </tr>

                    <!-- Total Weight (Kg) -->
                    <tr class="subtotal elevation-0">
                      <td class="text-left font-weight-bold">TOTAL WEIGHT (Kg)</td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="empty-column"></td>
                      <td class="text-right font-weight-bold">{{  formatNumber(totalWeight.toFixed(2)) }}</td>
                      <td class="text-right font-weight-bold">{{ formatNumber(w1TotalWeight.toFixed(2)) }}</td>
                      <td class="text-right font-weight-bold">{{ formatNumber(w2TotalWeight.toFixed(2)) }}</td>
                      <td class="text-right font-weight-bold">{{ formatNumber(w3TotalWeight.toFixed(2)) }}</td>
                      <td class="text-right font-weight-bold">{{  formatNumber(w4TotalWeight.toFixed(2)) }}</td>
                      <td class="empty-column"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div class="mt-5 ml-4">
              <v-row>
                <v-col cols="auto" >
                  <div class="text-center">
                    <div class="highlight-promotion" style="height: 50; width: 50px; border: 1px solid gray;">&nbsp;</div>
                  </div>
                </v-col>
                <v-col>With Promotion</v-col>
              </v-row>
            </div>
        </v-card-text>

        <v-card-action v-if="!directTransaction && distributionTypeGT">
            <div class="mx-5">
                <v-row>
                    <v-col cols="12" md>
                        <label class="custom-label font-weight-bold text-subtitle-1">Vehicle Recommendation</label>
                    </v-col>
                </v-row>
                <v-row align="start" justify="space-around">
                    <v-col cols="12" md="3" v-for="(week, index) in weeks" :key="index">
                        <v-card variant="text" class="mx-0 px-0">
                            <v-card-title class="text-center">
                                <label class="text-caption font-weight-bold">{{ week.week }}</label>
                            </v-card-title>
                            <v-divider></v-divider>
                                <v-card-text>
                                  <v-row>
                                      <v-col cols="12">
                                          <v-row class="mx-1 my-1">
                                              <v-col cols="8">
                                                <v-select
                                                  v-model="week.vehicle_id"
                                                  :items="vehicleTypes"
                                                  item-value="vehicle_id.id"  
                                                  item-title="vehicle_id.vehicle_name" 
                                                  label="Vehicle Type - Qty"
                                                  @update:modelValue="calculateVehicleQty(index+1)"
                                                ></v-select>
                                              </v-col>
                                              <v-col cols="4">
                                                  <v-text-field readonly v-model="computedTotalUnits[index]" label="Qty"
                                                      min="1"></v-text-field>
                                              </v-col>
                                          </v-row>
                                      </v-col>
                                  </v-row>
                                  <v-row>
                                      <v-col cols="12">    
                                          <v-row class="mx-2 my-0" no-gutters justify="end"> 
                                              <v-col class="d-flex justify-content-end">
                                                  <v-switch
                                                      v-model="week.same_type"
                                                      color="primary"
                                                      label="Same Type"
                                                      hide-details
                                                      density="comfortable"
                                                      @update:modelValue="calculateVehicleQty(index+1)"
                                                  ></v-switch>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1 my-1">
                                              <v-col cols="8">
                                                <v-select
                                                  :readonly="week.same_type"
                                                  v-model="week.additional_vehicle_id"
                                                  :items="vehicleTypes"
                                                  item-value="vehicle_id.id"  
                                                  item-title="vehicle_id.vehicle_name" 
                                                  label="Vehicle Type - Qty"
                                                  @update:modelValue="calculateVehicleQty(index+1)"
                                                ></v-select>
                                              </v-col>
                                              <v-col cols="4">
                                                  <v-text-field readonly v-model="computedAdditionalTotalUnits[index]" label="Qty"
                                                      min="1"></v-text-field>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1 pb-4" v-if="checkLargestFulfillment(week.fulfillment_cbm, week.fulfillment_kg)">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">{{ calculateFulfillment(week.fulfillment_cbm) + ' %' }}</label>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1 pb-4" v-if="checkLargestFulfillment(week.fulfillment_kg, week.fulfillment_cbm)">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment (Kg) :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">{{ calculateFulfillment(week.fulfillment_kg) + ' %' }}</label>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1 pb-4" v-if="week.fulfillment_cbm === 0 ||  week.fulfillment_kg === 0">
                                            <v-col>
                                                <label class="custom-label">Fulfillment :</label>
                                            </v-col>
                                            <v-col>
                                                <label class="custom-label">0</label>
                                            </v-col>
                                          </v-row>
                                          <v-row class="my-1">
                                            <v-expansion-panels>
                                              <v-expansion-panel>
                                                <v-expansion-panel-title expand-icon="mdi-menu-down" disable-icon-rotate class="text-caption">
                                                  Add Qty Recommendation
                                                  <template v-slot:actions>
                                                    <v-icon color="primary" icon="mdi-plus"></v-icon>
                                                  </template>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                  <v-row class="mx-1 pt-1" v-for="(productGroup, index) in product_groups_name" :key="index">
                                                    <v-col>
                                                      <label class="custom-label">{{ productGroup }}</label>
                                                    </v-col>
                                                    <v-col>
                                                      <label class="custom-label">
                                                        {{ 
                                                          calculateRecomendation(week, week[`${productGroup.toLowerCase().replace('-', '')}_recomendation`]) 
                                                        }}
                                                      </label>
                                                    </v-col>
                                                    <v-col>
                                                        {{ 
                                                          calculateRecomendation(week, week[`${productGroup.toLowerCase().replace('-', '')}_recomendation2`]) 
                                                        }}
                                                    </v-col>
                                                  </v-row>
                                                </v-expansion-panel-text>
                                              </v-expansion-panel>

                                              <v-expansion-panel>
                                                <v-expansion-panel-title expand-icon="mdi-menu-down" disable-icon-rotate class="text-caption">
                                                  Reduce Qty Recommendation
                                                  <template v-slot:actions>
                                                    <v-icon color="primary" icon="mdi-minus"></v-icon>
                                                  </template>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                  <v-row class="my-1" v-for="(productGroup, index) in product_groups_name" :key="index">
                                                    <v-col>
                                                      <label class="custom-label">{{ productGroup }}</label>
                                                    </v-col>
                                                    <v-col>
                                                      <label class="custom-label">
                                                        {{ 
                                                          week[`${productGroup.toLowerCase().replace('-', '')}_reduce`] 
                                                        }}
                                                      </label>
                                                    </v-col>
                                                    <v-col>
                                                      {{ 
                                                          week[`${productGroup.toLowerCase().replace('-', '')}_reduce2`] 
                                                        }}
                                                    </v-col>
                                                  </v-row>
                                                </v-expansion-panel-text>                          
                                              </v-expansion-panel>
                                            </v-expansion-panels>
                                          </v-row>
                                      </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-divider class="mt-2"></v-divider>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

        </v-card-action>


        <!-- This Section below to show the Total recommendations on child -->
        <v-card-action v-else-if="!directTransaction && selectedHeadTab > 0">
            <div class="mx-5">
                <v-row>
                    <v-col cols="12" md>
                        <label class="custom-label font-weight-bold">Combined Document Qty Recommendation</label>
                    </v-col>
                </v-row>
                <v-row align="start" justify="space-around">
                    <v-col cols="12" md="3" v-for="(week, index) in combinedWeeks" :key="index">
                        <v-card variant="text" class="my-5 mx-0 px-0">
                            <v-card-title class="text-center">
                                <label class="text-caption font-weight-bold">{{ week.week }}</label>
                            </v-card-title>
                            <v-divider></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row class="mx-1 my-1">
                                                <v-col cols="8">
                                                  <v-select
                                                    readonly
                                                    v-model="week.vehicle_id"
                                                    :items="vehicleTypes"
                                                    item-value="vehicle_id.id"  
                                                    item-title="vehicle_id.vehicle_name" 
                                                    label="Vehicle Type - Qty"
                                                    @update:modelValue="calculateVehicleQty(index+1)"
                                                  ></v-select>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field readonly v-model="computedAdditionalTotalUnits[index]"  label="Qty"
                                                        min="1"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <!-- <v-row class="mx-1">
                                                <v-col>
                                                    <label class="custom-label">Dimension/ Capacity :</label>
                                                </v-col>
                                                <v-col>
                                                    <label class="custom-label">
                                                      {{ (vehicleDetail(week.vehicle_id, 'dimension') + ' CBM' || '') + '/' + (vehicleDetail(week.vehicle_id, 'capacity') + ' Kg' || '') }}
                                                    </label>
                                                </v-col>
                                            </v-row> -->
                                            <v-row class="mx-1 pb-4" v-if="checkLargestFulfillment(week.fulfillment_cbm, week.fulfillment_kg)">
                                                <v-col>
                                                    <label class="custom-label">Fulfillment :</label>
                                                </v-col>
                                                <v-col>
                                                    <label class="custom-label">{{ calculateFulfillment(week.fulfillment_cbm) + ' %' }}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1 pb-4" v-if="checkLargestFulfillment(week.fulfillment_kg, week.fulfillment_cbm)">
                                                <v-col>
                                                    <label class="custom-label">Fulfillment (Kg) :</label>
                                                </v-col>
                                                <v-col>
                                                    <label class="custom-label">{{ calculateFulfillment(week.fulfillment_kg ) + ' %' }}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1 pb-4" v-if="week.fulfillment_cbm === 0 ||  week.fulfillment_kg === 0">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label"></label>
                                              </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mx-1">
                                        <v-col cols="12">    
                                          <v-row class="my-1 ">
                                            <v-expansion-panels>
                                              <v-expansion-panel>
                                                <v-expansion-panel-title expand-icon="mdi-menu-down" disable-icon-rotate class="text-caption">
                                                  Add Qty Recommendation
                                                  <template v-slot:actions>
                                                    <v-icon color="primary" icon="mdi-plus"></v-icon>
                                                  </template>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                  <v-row class="mx-1 pt-1" v-for="(productGroup, index) in product_groups_name" :key="index">
                                                    <v-col>
                                                      <label class="custom-label">{{ productGroup }}</label>
                                                    </v-col>
                                                    <v-col>
                                                      <label class="custom-label">
                                                        {{ 
                                                         calculateRecomendation(week, week[`${productGroup.toLowerCase().replace('-', '')}_recomendation`]) 
                                                        }}
                                                      </label>
                                                    </v-col>
                                                    <v-col>
                                                      Carton
                                                    </v-col>
                                                  </v-row>
                                                </v-expansion-panel-text>
                                              </v-expansion-panel>

                                              <v-expansion-panel>
                                                <v-expansion-panel-title expand-icon="mdi-menu-down" disable-icon-rotate class="text-caption">
                                                  Reduce Qty Recommendation
                                                  <template v-slot:actions>
                                                    <v-icon color="primary" icon="mdi-minus"></v-icon>
                                                  </template>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                  <v-row class="my-1" v-for="(productGroup, index) in product_groups_name" :key="index">
                                                    <v-col>
                                                      <label class="custom-label">{{ productGroup }}</label>
                                                    </v-col>
                                                    <v-col>
                                                      <label class="custom-label">
                                                        {{ 
                                                          week[`${productGroup.toLowerCase().replace('-', '')}_reduce`] 
                                                        }}
                                                      </label>
                                                    </v-col>
                                                    <v-col>
                                                      {{ 
                                                          week[`${productGroup.toLowerCase().replace('-', '')}_reduce2`] 
                                                        }}
                                                    </v-col>
                                                  </v-row>
                                                </v-expansion-panel-text>                          
                                              </v-expansion-panel>
                                            </v-expansion-panels>
                                          </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider class="mt-2"></v-divider>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

        </v-card-action>
    </v-card>

    <v-row class="text-caption mt-2">
        <!-- <v-col>History and Notes</v-col> -->
        <v-col>
            <v-btn-toggle v-model="toggle" color="primary" variant="text">
                <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-history">History</v-btn>
                <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-note">Notes</v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
    <v-row class="mt-0 px-4" v-if="toggle === 0">
        <v-timeline align="start" side="end">
            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>

            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem elit. Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>


            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet, Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>
        </v-timeline>
    </v-row>
    <v-row class="mt-0 px-4" v-if="toggle === 1">
        <v-timeline align="start" side="end">
            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <QuillEditor ref="myEditor" theme="snow" toolbar="essential" v-model="notes" />
                <v-btn class="my-2">Post</v-btn>
            </v-timeline-item>

            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
                            aliquid unde, voluptate a id ea explicabo, aperiam nostrum debitis quaerat fugit. Quaerat
                            praesentium rem autem.
                        </div>
                    </div>
                </div>
            </v-timeline-item>


            <v-timeline-item dot-color="white" size="x-small" density="compact">
                <div class="d-flex">
                    <div class="me-4 text-caption">5pm</div>
                    <div>
                        <div class="text-caption">Lorem ipsum dolor sit amet, Quia, eaque.
                        </div>
                    </div>
                </div>
            </v-timeline-item>
        </v-timeline>
    </v-row>


    <!-- Bottom Sheet for Full Screen Card -->
    <v-bottom-sheet v-model="isBottomSheetOpen" fullscreen>
        <v-card>
            <template v-slot:title>
                <v-row class="pa-2">
                  <v-col>
                    <v-btn class="mx-1" color="error" variant="outlined" prepend-icon="mdi-fullscreen"
                      @click="isBottomSheetOpen = false">
                      Exit Full Screen
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4">
                    <v-text-field placeholder="Search" v-model="searchQuery" density="compact" append-inner-icon="mdi-magnify"></v-text-field>
                  </v-col>
                </v-row>
            </template>

            <v-divider></v-divider>

            <v-card-text>
              <div class="mt-5">
                <div class="table-wrapper">
                  <table class="custom-table">
                    <thead>
                      <!-- First Header Row -->
                      <tr>
                        <th colspan="7" class="merge-cell">REQ. DELIVERY DATE</th>
                        <th>
                          <div v-if="cmo.is_combined_document">
                            {{ formatDate(cmo.w1_request_delivery_date) }}
                          </div>
                          <div v-else>
                            <v-date-input v-model="cmo.w1_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon="" 
                                :allowed-dates="(date) => dateFilter(date, cmo.w1_request_delivery_date)"
                                :disabled="!cmo.w1_active"></v-date-input>
                          </div>  
                        </th>
                        <th>
                          <div v-if="cmo.is_combined_document">
                            {{ formatDate(cmo.w2_request_delivery_date) }}
                          </div>
                          <div v-else>
                            <v-date-input v-model="cmo.w2_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="(date) => dateFilter(date, cmo.w2_request_delivery_date)"
                                :disabled="!cmo.w2_active"></v-date-input>
                          </div>
                        </th>
                        <th>
                          <div v-if="cmo.is_combined_document">
                            {{ formatDate(cmo.w3_request_delivery_date) }}
                          </div>
                          <div v-else>
                            <v-date-input v-model="cmo.w3_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="(date) => dateFilter(date, cmo.w3_request_delivery_date)"
                                :disabled="!cmo.w3_active"></v-date-input>
                          </div>
                        </th>
                        <th>
                          <div v-if="cmo.is_combined_document">
                            {{ formatDate(cmo.w4_request_delivery_date) }}
                          </div>
                          <div v-else>
                            <v-date-input v-model="cmo.w4_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                                :multiple="false" prepend-icon="" prepend-inner-icon=""
                                :allowed-dates="(date) => dateFilter(date, cmo.w4_request_delivery_date)"
                                :disabled="!cmo.w4_active"></v-date-input>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                      <!-- Second Header Row -->
                      <tr>
                        <th colspan="7" class="merge-cell">Valid From - Valid To</th>
                        <th>
                          <v-row class="py-4" v-if="!cmo.is_combined_document">
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w1_valid_from" variant="outlined" density="compact" bgColor="none"
                                      :multiple="false" prepend-icon="" prepend-inner-icon="" 
                                      :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                              ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                              : null"
                                      :disabled="!cmo.w1_active"
                                    ></v-date-input>
                            </v-col>
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w1_valid_to" variant="outlined" density="compact" bgColor="none"
                                    :multiple="false" prepend-icon="" prepend-inner-icon=""
                                    :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                            ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                            : null"
                                    :disabled="!cmo.w1_active"
                                ></v-date-input>
                            </v-col>
                          </v-row>
                          <v-row class="py-4" v-else>
                            <v-col>
                                {{ formatDate(cmo.w3_valid_from) }}
                            </v-col>
                            <v-col>
                                {{ formatDate(cmo.w3_valid_to) }}
                            </v-col>
                          </v-row>
                        </th>
                        <th>
                          <v-row v-if="!cmo.is_combined_document">
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w2_valid_from" variant="outlined" density="compact" bgColor="none" class="px-4"
                                  :multiple="false" prepend-icon="" prepend-inner-icon=""
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                          ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                          : null"
                                  :disabled="!cmo.w2_active">
                                </v-date-input>
                            </v-col>
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w2_valid_to" variant="outlined" density="compact" bgColor="none"
                                  :multiple="false" prepend-icon="" prepend-inner-icon=""
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                      ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                      : null"
                                  :disabled="!cmo.w2_active">
                                </v-date-input>
                            </v-col>
                          </v-row>
                          <v-row class="py-4" v-else>
                            <v-col>
                                {{ formatDate(cmo.w2_valid_from) }}
                            </v-col>
                            <v-col>
                                {{ formatDate(cmo.w2_valid_to) }}
                            </v-col>
                          </v-row>
                        </th>
                        <th>
                          <v-row v-if="!cmo.is_combined_document">
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w3_valid_from" variant="outlined" density="compact" bgColor="none"
                                  :multiple="false" prepend-icon="" prepend-inner-icon=""
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                      ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                      : null"
                                  :disabled="!cmo.w3_active">
                                </v-date-input>
                            </v-col>
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w3_valid_to" variant="outlined" density="compact" bgColor="none"
                                  :multiple="false" prepend-icon="" prepend-inner-icon=""
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                      ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                      : null"
                                  :disabled="!cmo.w3_active">
                                </v-date-input>
                            </v-col>
                          </v-row>
                          <v-row class="py-4" v-else>
                            <v-col>
                                {{ formatDate(cmo.w3_valid_from) }}
                            </v-col>
                            <v-col>
                                {{ formatDate(cmo.w3_valid_to) }}
                            </v-col>
                          </v-row>
                        </th>
                        <th>
                          <v-row v-if="!cmo.is_combined_document">
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w4_valid_from" variant="outlined" density="compact" bgColor="none"
                                  :multiple="false" prepend-icon="" prepend-inner-icon=""
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                      ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                      : null"
                                  :disabled="!cmo.w4_active">
                                </v-date-input>
                            </v-col>
                            <v-col cols="6">
                                <v-date-input v-model="cmo.w4_valid_to" variant="outlined" density="compact" bgColor="none"
                                  :multiple="false" prepend-icon="" prepend-inner-icon=""
                                  :allowed-dates="cmo.purchase_order_date && cmo.purchase_order_expired_date 
                                      ? validFromAndToAllowedDate(cmo.purchase_order_date, cmo.purchase_order_expired_date) 
                                      : null"
                                  :disabled="!cmo.w4_active">
                                </v-date-input>
                            </v-col>
                          </v-row>
                          <v-row class="py-4" v-else>
                            <v-col>
                                {{ formatDate(cmo.w4_valid_from) }}
                            </v-col>
                            <v-col>
                                {{ formatDate(cmo.w4_valid_to) }}
                            </v-col>
                          </v-row>
                        </th>
                        <th></th>
                      </tr>
                      <!-- Third Header Row -->
                      <tr>
                        <th>SKU</th>
                        <th>Product Name</th>
                        <th>Product Nickname</th>
                        <th>Product Group</th>
                        <th>ROFO Total</th>
                        <th>ROFO Outstanding</th>
                        <th>Grand Total</th>
                        <th>Week 1</th>
                        <th>Week 2</th>
                        <th>Week 3</th>
                        <th>Week 4</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Iterate through grouped data -->
                      <template v-for="(group, groupIndex) in filteredProducts" :key="groupIndex">
                        <!-- Render each product in the group -->
                        <tr v-for="(item, index) in group.items" :key="index" :class="{ 'highlight-promotion': isProductInPromotion(item.product_id) }">
                          <!-- SKU -->
                          <td>{{ item.sku }}</td>

                          <!-- Product Name -->
                          <td>{{ item.product_name }}</td>

                          <!-- Product Nickname -->
                          <td>{{ item.alias_name }}</td>

                          <!-- Product Group -->
                          <td>{{ item.product_group }}</td>

                          <!-- ROFO Total -->
                          <td class="text-right">{{ item.rolling_forecast_total }}</td>

                          <!-- ROFO Outstanding -->
                          <td :class="item.rolling_forecast_outstanding < 0 ? 'invalid-mark' : ''" class="text-right">
                            <span>
                              {{ item.rolling_forecast_outstanding < 0 
                                ? `(${Math.abs(item.rolling_forecast_outstanding)})` 
                                : item.rolling_forecast_outstanding }}
                            </span>
                          </td>

                          <!-- Grand Total -->
                          <td class="text-right">{{ item.grand_total_qty }}</td>

                          <!-- Week 1 -->
                          <td>
                            <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                              {{ formatNumber(item.w1_qty) }}
                            </div>
                            <div v-else>
                              <vue-number
                                v-model.number="item.w1_qty"
                                v-bind="number"
                                :disabled="!cmo.w1_active"
                                @change="updateValue(item, 'grand_total_qty', 1)"
                                class="custom-input text-right ma-2"
                              ></vue-number>
                            </div>
                          </td>

                          <!-- Week 2 -->
                          <td>
                            <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                              {{ formatNumber(item.w2_qty) }}
                            </div>
                            <div v-else>
                              <vue-number
                                v-model.number="item.w2_qty"
                                v-bind="number"
                                :disabled="!cmo.w2_active"
                                @change="updateValue(item, 'grand_total_qty', 2)"
                                class="custom-input text-right ma-2"
                              ></vue-number>
                            </div>
                          </td>

                          <!-- Week 3 -->
                          <td>
                            <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                              {{ formatNumber(item.w3_qty) }}
                            </div>
                            <div v-else>
                              <vue-number
                                v-model.number="item.w3_qty"
                                v-bind="number"
                                :disabled="!cmo.w3_active"
                                @change="updateValue(item, 'grand_total_qty', 3)"
                                class="custom-input text-right ma-2"
                              ></vue-number>
                            </div>
                          </td>

                          <!-- Week 4 -->
                          <td>
                            <div v-if="cmo.is_combined_document && selectedHeadTab === 0" class="text-right">
                              {{ formatNumber(item.w4_qty) }}
                            </div>
                            <div v-else>
                              <vue-number
                                v-model.number="item.w4_qty"
                                v-bind="number"
                                :disabled="!cmo.w4_active"
                                @change="updateValue(item, 'grand_total_qty', 4)"
                                class="custom-input text-right ma-2"
                              ></vue-number>
                            </div>
                          </td>

                          <!-- Remarks -->
                          <td>
                            <input
                              type="text"
                              v-model="item.remarks"
                              @change="updateValue(item, 'remarks')"
                              class="custom-input"
                            />
                          </td>
                        </tr>

                         <!-- Subtotal Row -->
                        <tr class="subtotal">
                          <td class="text-left font-weight-bold">TOTAL {{ group.category }}</td>
                          <td class="empty-column"></td>
                          <td class="empty-column"></td>
                          <td class="empty-column"></td>
                          <td class="empty-column"></td>
                          <td class="empty-column"></td>
                          <td class="text-right font-weight-bold">{{ group.totals.grand_total_qty }}</td>
                          <td class="text-right font-weight-bold">{{ group.totals.w1_qty }}</td>
                          <td class="text-right font-weight-bold">{{ group.totals.w2_qty }}</td>
                          <td class="text-right font-weight-bold">{{ group.totals.w3_qty }}</td>
                          <td class="text-right font-weight-bold">{{ group.totals.w4_qty }}</td>
                          <td class="empty-column"></td>
                        </tr>
                      </template>
                    </tbody>
                    <tfoot>
                      <tr class="subtotal elevation-0">
                        <td class="text-left font-weight-bold">GRAND TOTAL ORDER QTY</td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="text-right font-weight-bold">{{ formatNumber(grandTotalQty) }}</td>
                        <td class="text-right font-weight-bold">{{ formatNumber(w1TotalQty) }}</td>
                        <td class="text-right font-weight-bold">{{ formatNumber(w2TotalQty) }}</td>
                        <td class="text-right font-weight-bold">{{ formatNumber(w3TotalQty) }}</td>
                        <td class="text-right font-weight-bold">{{ formatNumber(w4TotalQty) }}</td>
                        <td class="empty-column"></td>
                      </tr>

                      <!-- Total Weight (Kg) -->
                      <tr class="subtotal elevation-0">
                        <td class="text-left font-weight-bold">TOTAL WEIGHT (Kg)</td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="empty-column"></td>
                        <td class="text-right font-weight-bold">{{  formatNumber(totalWeight.toFixed(2)) }}</td>
                        <td class="text-right font-weight-bold">{{ formatNumber(w1TotalWeight.toFixed(2)) }}</td>
                        <td class="text-right font-weight-bold">{{ formatNumber(w2TotalWeight.toFixed(2)) }}</td>
                        <td class="text-right font-weight-bold">{{ formatNumber(w3TotalWeight.toFixed(2)) }}</td>
                        <td class="text-right font-weight-bold">{{  formatNumber(w4TotalWeight.toFixed(2)) }}</td>
                        <td class="empty-column"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div class="mt-5 ml-4">
                <v-row>
                  <v-col cols="auto" >
                    <div class="text-center">
                      <div class="highlight-promotion" style="height: 50; width: 50px; border: 1px solid gray;">&nbsp;</div>
                    </div>
                  </v-col>
                  <v-col>With Promotion</v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-action>
              <div class="mx-5">
                <v-row>
                    <v-col cols="12" md>
                        <label class="custom-label font-weight-bold text-subtitle-1">Vehicle Recommendation</label>
                    </v-col>
                </v-row>
                <v-row align="start" justify="space-around">
                    <v-col cols="12" md="3" v-for="(week, index) in weeks" :key="index">
                        <v-card variant="text" class="mx-0 px-0">
                            <v-card-title class="text-center">
                                <label class="text-caption font-weight-bold">{{ week.week }}</label>
                            </v-card-title>
                            <v-divider></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row class="mx-1 my-1">
                                                <v-col cols="8">
                                                  <v-select
                                                    v-model="week.vehicle_id"
                                                    :items="vehicleTypes"
                                                    item-value="vehicle_id.id"  
                                                    item-title="vehicle_id.vehicle_name" 
                                                    label="Vehicle Type - Qty"
                                                    @update:modelValue="calculateVehicleQty(index+1)"
                                                  ></v-select>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field readonly v-model="computedTotalUnits[index]" label="Qty"
                                                        min="1"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">    
                                            <v-row class="mx-2 my-0" no-gutters justify="end"> 
                                                <v-col class="d-flex justify-content-end">
                                                    <v-switch
                                                        v-model="week.same_type"
                                                        color="primary"
                                                        label="Same Type"
                                                        hide-details
                                                        density="comfortable"
                                                        @update:modelValue="calculateVehicleQty(index+1)"
                                                    ></v-switch>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1 my-1">
                                                <v-col cols="8">
                                                  <v-select
                                                    :readonly="week.same_type"
                                                    v-model="week.additional_vehicle_id"
                                                    :items="vehicleTypes"
                                                    item-value="vehicle_id.id"  
                                                    item-title="vehicle_id.vehicle_name" 
                                                    label="Vehicle Type - Qty"
                                                    @update:modelValue="calculateVehicleQty(index+1)"
                                                  ></v-select>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field readonly v-model="computedAdditionalTotalUnits[index]" label="Qty"
                                                        min="1"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1 pb-4" v-if="checkLargestFulfillment(week.fulfillment_cbm, week.fulfillment_kg)">
                                                <v-col>
                                                    <label class="custom-label">Fulfillment :</label>
                                                </v-col>
                                                <v-col>
                                                    <label class="custom-label">{{ calculateFulfillment(week.fulfillment_cbm) + ' %' }}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1 pb-4" v-if="checkLargestFulfillment(week.fulfillment_kg, week.fulfillment_cbm)">
                                                <v-col>
                                                    <label class="custom-label">Fulfillment (Kg) :</label>
                                                </v-col>
                                                <v-col>
                                                    <label class="custom-label">{{ calculateFulfillment(week.fulfillment_kg) + ' %' }}</label>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-1 pb-4" v-if="week.fulfillment_cbm === 0 ||  week.fulfillment_kg === 0">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">0</label>
                                              </v-col>
                                            </v-row>
                                            <v-row class="my-1">
                                              <v-expansion-panels>
                                                <v-expansion-panel>
                                                  <v-expansion-panel-title expand-icon="mdi-menu-down" disable-icon-rotate class="text-caption">
                                                    Add Qty Recommendation
                                                    <template v-slot:actions>
                                                      <v-icon color="primary" icon="mdi-plus"></v-icon>
                                                    </template>
                                                  </v-expansion-panel-title>
                                                  <v-expansion-panel-text>
                                                    <v-row class="mx-1 pt-1" v-for="(productGroup, index) in product_groups_name" :key="index">
                                                      <v-col>
                                                        <label class="custom-label">{{ productGroup }}</label>
                                                      </v-col>
                                                      <v-col>
                                                        <label class="custom-label">
                                                          {{ 
                                                            calculateRecomendation(week, week[`${productGroup.toLowerCase().replace('-', '')}_recomendation`]) 
                                                          }}
                                                        </label>
                                                      </v-col>
                                                      <v-col>
                                                          {{ 
                                                            calculateRecomendation(week, week[`${productGroup.toLowerCase().replace('-', '')}_recomendation2`]) 
                                                          }}
                                                      </v-col>
                                                    </v-row>
                                                  </v-expansion-panel-text>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                  <v-expansion-panel-title expand-icon="mdi-menu-down" disable-icon-rotate class="text-caption">
                                                    Reduce Qty Recommendation
                                                    <template v-slot:actions>
                                                      <v-icon color="primary" icon="mdi-minus"></v-icon>
                                                    </template>
                                                  </v-expansion-panel-title>
                                                  <v-expansion-panel-text>
                                                    <v-row class="my-1" v-for="(productGroup, index) in product_groups_name" :key="index">
                                                      <v-col>
                                                        <label class="custom-label">{{ productGroup }}</label>
                                                      </v-col>
                                                      <v-col>
                                                        <label class="custom-label">
                                                          {{ 
                                                            week[`${productGroup.toLowerCase().replace('-', '')}_reduce`] 
                                                          }}
                                                        </label>
                                                      </v-col>
                                                      <v-col>
                                                          {{ 
                                                            week[`${productGroup.toLowerCase().replace('-', '')}_reduce2`] 
                                                          }}
                                                      </v-col>
                                                    </v-row>
                                                  </v-expansion-panel-text>                          
                                                </v-expansion-panel>
                                              </v-expansion-panels>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider class="mt-2"></v-divider>
                        </v-card>
                    </v-col>
                </v-row>
              </div>
            </v-card-action>
        </v-card>
    </v-bottom-sheet>

    <!-- Promo Confirmation Dialog -->
    <v-dialog v-model="promoConfirmation" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-h6">
          Promotion Confirmation Required
        </v-card-title>

        <v-card-text>
          <p>
            There are active promotion{{ cmo.promotions.length > 1 ? 's' : '' }} that require your decision:
          </p>

          <v-container>
            <v-row v-for="(promo, index) in cmo.promotions" :key="index" class="mb-2">
              <v-col cols="12">
                <v-card outlined class="pa-3">
                  <p><strong>By:</strong> {{ promo.sell_in_by }}</p>
                  
                  <p><strong>Free Product List:</strong></p>
                  <v-list dense>
                    <v-list-item v-for="(product, idx) in promo.free_product_detail" :key="idx">
                      <v-list-item-content>
                        <v-list-item-title>{{ product.product_name }}</v-list-item-title>
                        <v-list-item-subtitle>SKU: {{ product.sku }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <p><strong>Target Limit:</strong> {{ promo.target_limit }}</p>
                  <p><strong>Free Quantity:</strong> {{ promo.free_qty }}</p>
                  <p><strong>Max Quantity:</strong> {{ promo.max_qty }}</p>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <p class="mt-4"><strong>Would you like to accept them?</strong></p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="flat" color="red" @click="cmo.accept_promotion = false; handleSubmit(0)">
            Decline
          </v-btn>
          <v-btn color="green" @click="cmo.accept_promotion = true; handleSubmit(0)">
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      outlined
      color="primary"
    >
      <div class="text-subtitle-1 pb-2">{{ snackbar.message }}</div>

      <!-- <p>This is a longer paragraph explaining something</p> -->

      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay
			:model-value="loading"
			class="align-center justify-center"
			persistent
		>
			<v-progress-circular
				color="primary"
				size="120"
				indeterminate
			></v-progress-circular>
		</v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
    components: {
        Breadcrumbs
    },
    data() {
        return {
            cmo: {
                id: null,
                period_id:null,
                sold_to: null,
                grand_total_qty: 0,
                accept_promotion: false
            },
            cmoData: {},
            roles: [],
            selectedRoleId: null,
            tab: null,
            length: 3,
            loading: false,
            headers: [
                {
                    title: 'REQ. DELIVERY DATE',
                    align: 'center',
                    children: [
                        {
                            title: 'Valid From - Valid To',
                            align: 'center',
                            value: 'valid-from-to',
                            children: [
                                { title: 'SKU', value: 'sku', align: 'left', clickable: true },
                                { title: 'Product Name', value: 'product_name', nowrap: false, align: 'left', width: '300px' },
                                { title: 'Product Nickname', value: 'alias_name', align: 'left' },
                                { title: 'Product Group', value: 'product_group', align: 'center' },
                                { title: 'ROFO Total', value: 'rolling_forecast_total', align: 'center' },
                                { title: 'ROFO Outstanding', value: 'rolling_forecast_outstanding', align: 'center' },
                                { title: 'Grand Total', value: 'grand_total_qty', align: 'center' },
                            ]
                        },
                    ],
                    
                },
                {
                    title: '',
                    align: 'center',
                    value: 'v1',
                    width: '600px',
                    children: [
                         {
                            title: '',
                            align: 'center',
                            value: 'w1',
                            width: '600px',
                            children: [
                                { title: 'Week 1', value: 'week1', align: 'center',width: '200px' },
                            ]
                        },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    value: 'v2',
                    children: [
                         {
                            title: '',
                            align: 'center',
                            value: 'w2',
                            children: [
                                { title: 'Week 2', value: 'week2', align: 'center' },
                            ]
                        },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    value: 'v3',
                    children: [
                         {
                            title: '',
                            align: 'center',
                            value: 'w3',
                            children: [
                                { title: 'Week 3', value: 'week3', align: 'center' },
                            ]
                        },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    value: 'v4',
                    children: [
                         {
                            title: '',
                            align: 'center',
                            value: 'w4',
                            children: [
                                { title: 'Week 4', value: 'week4', align: 'center' },
                            ]
                        },
                    ]
                },
                {
                    title: '',
                    align: 'center',
                    children: [
                         {
                            title: '',
                            align: 'center',
                            value: 'rmrk',
                            children: [
                                { title: 'Remarks', value: 'remarks', align: 'center' },
                            ]
                         }
                    ]
                },
                

            ],
            isBottomSheetOpen: false,
            toggle: null,
            notes: null,
            startDate: null,
            confirm_monthly_order_details_attributes: [
            ],
            vehicleTypes: [],
            weeks: [
                {
                    week: 'Week 1',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
                {
                    week: 'Week 2',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
                {
                    week: 'Week 3',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
                {
                    week: 'Week 4',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
            ],
            combinedWeeks: [
                {
                    week: 'Week 1',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
                {
                    week: 'Week 2',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
                {
                    week: 'Week 3',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
                {
                    week: 'Week 4',
                    vehicle_id: null,
                    additional_vehicle_id: null,
                    total_unit: 0,
                    additional_total_unit: 0,
                    fulfillment_cbm: 0,
                    fulfillment_kg: 0,
                    additional_fulfillment_cbm: 0,
                    additional_fulfillment_kg: 0,
                    same_type: true,
                    xsmall_recomendation: 0,
                    small_recomendation: 0,
                    medium_recomendation: 0,
                    large_recomendation: 0,
                    xlarge_recomendation: 0,
                    xsmall_reduce: 0,
                    small_reduce: 0,
                    medium_reduce: 0,
                    large_reduce: 0,
                    xlarge_reduce: 0
                },
            ],
            transactionTypes: [],
            shipmentTypes: [],
            periods: [],
            distributors: [],
            parentDistributors: [],
            selected_confirm_monthly_order_details_attributes: [],
            weekQtyKey: '',
            product_groups: [],
            product_groups_name: [],
            snackbar: {
              show: false,
              message: '',
            },
            directTransaction: false,
            distributionTypeGT: true,
            selectedHeadTab: 0,
            headTabsData: [],
            cmo_ids: [],
            draftDocument: false,
            isVisible: false,
            combiningProcess: false,
            fulfillment_limit_max: 0,
            formulas: {},
            promoConfirmation: false,
            file: null,
            type: null,
            searchQuery: '',
        };
    },
    watch: {
      length(val) {
          this.tab = val - 1
      },
      // computedAdditionalTotalUnits: {
      //   handler(newValues) {
      //     const maxLimit = this.fulfillment_limit_max * 100;
      //     let fulfillment = 0;
      //     let biggestFulfillment = 0;

      //     this.weeks.forEach((week, index) => {
      //        // Ensure week.total_unit is a number
      //       if (week.fulfillment_cbm > 1 || week.fulfillment_kg > 1) {
      //         biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg) % 1;
      //         console.log('addTotal Fulfillment', biggestFulfillment, Math.round(biggestFulfillment * 100));
      //         fulfillment = Math.round(biggestFulfillment * 100);
      //       } else {
      //         fulfillment = Math.round(Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100);
      //       }
      //       console.log('addTotal Fulfillment', (fulfillment >= maxLimit && (biggestFulfillment <= 100)));
      //       console.log('addTotal Fulfillment', fulfillment, maxLimit , biggestFulfillment );
      //       if ((fulfillment >= maxLimit && (biggestFulfillment <= 100))) {
              
      //           // Ensure week.total_unit is a number
      //           let baseValue = week.total_unit || 0;
      //           let additionalValue = week.additional_total_unit || 0;
      //           console.log('compute addTotal 1', week.fulfillment_cbm, week.fulfillment_kg, baseValue, additionalValue, week.total_unit, week.additional_total_unit)
      //           // Proper numeric addition instead of string concatenation
      //           if(week.same_type === true) {
      //             if (this.fulfillment_limit_max < 1 && (additionalValue >= this.fulfillment_limit_max && additionalValue * 100 < 100)) {
      //               week.total_unit = baseValue + Math.round(additionalValue);
      //               week.additional_total_unit = 0;
      //             } else {
      //               week.total_unit = baseValue;
      //               week.additional_total_unit = additionalValue;
      //             }
      //             week.total_unit = baseValue + Math.round(additionalValue);
      //             week.additional_total_unit = 0;
      //           } else {
      //             week.additional_total_unit = additionalValue;
      //           }
      //       }
      //     });
      //   },
      //   deep: true, // Reacts to nested object changes
      //   immediate: true // Runs immediately when component mounts
      // }
      computedAdditionalTotalUnits: {
        handler(newValues) {
          const maxLimit = Math.round(this.fulfillment_limit_max * 100); // Ensure proper precision
          let fulfillment = 0;

          this.weeks.forEach((week, index) => {
            let biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg);
            fulfillment = Math.round(biggestFulfillment * 100); // Correct rounding

            console.log('addTotal Fulfillment', fulfillment, maxLimit, biggestFulfillment);

            if (fulfillment >= maxLimit && fulfillment <= 100) {
              let baseValue = Number(week.total_unit) || 0;
              let additionalValue = Number(week.additional_total_unit) || 0;

              console.log('compute addTotal 1', week.fulfillment_cbm, week.fulfillment_kg, baseValue, additionalValue);

              if (week.same_type === true) {
                if (this.fulfillment_limit_max < 1 && additionalValue >= this.fulfillment_limit_max * 100 && additionalValue < 100) {
                  week.total_unit = baseValue + Math.round(additionalValue);
                  week.additional_total_unit = 0;
                } else {
                  week.total_unit = baseValue;
                  week.additional_total_unit = additionalValue;
                }
              } else {
                week.additional_total_unit = additionalValue;
              }
            }
          });
        },
        deep: true,
        immediate: true
      },
    },
    created() {
        // this.fetchTransactionTypes();
    },
    computed: {
      isAdding() {
          return this.$route.params.action === 'add';
      },
      breadcrumbItems() {
          const action = this.$route.params.action;
          const route = this.$route.name;
          const items = breadcrumbConfig[route];

          if (typeof items === 'function') {
              return items(action);
          }

          return items || [];
      },
      grandTotalQty() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.grand_total_qty) || 0);
        }, 0);
      },
      totalWeight() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return (total + (Number(item.grand_total_qty * item.gross_weight)) / 1000 || 0);
        }, 0);
      },

      totalVolume() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.grand_total_qty * item.ratio_loading_capacity) || 0);
        }, 0);
      },

      w1TotalQty() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w1_qty) || 0);
        }, 0);
      },
      w2TotalQty() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w2_qty) || 0);
        }, 0);
      },
      w3TotalQty() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w3_qty) || 0);
        }, 0);
      },
      w4TotalQty() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w4_qty) || 0);
        }, 0);
      },

        // Total Weight Footer
      w1TotalWeight() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return (total + (Number(item.w1_qty * item.gross_weight)) / 1000 || 0);
        }, 0);
      },
      w2TotalWeight() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return (total + (Number(item.w2_qty * item.gross_weight)) / 1000 || 0);
        }, 0);
      },
      w3TotalWeight() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return (total + (Number(item.w3_qty * item.gross_weight)) / 1000 || 0);
        }, 0);
      },
      w4TotalWeight() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return (total + (Number(item.w4_qty * item.gross_weight)) / 1000 || 0);
        }, 0);
      },


      // Total Volume Footer
      w1TotalVolume() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w1_qty * item.ratio_loading_capacity) || 0);
        }, 0);
      },
      w2TotalVolume() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w2_qty * item.ratio_loading_capacity) || 0);
        }, 0);
      },
      w3TotalVolume() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w3_qty * item.ratio_loading_capacity) || 0);
        }, 0);
      },
      w4TotalVolume() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.w4_qty * item.ratio_loading_capacity) || 0);
        }, 0);
      },

      // See example on XLS file CMO calculation
      weightConvertion() { //week_qty should be like w1_qty, so i can fetch item.w1_qty based on parameters
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          const weight = Number(item[this.weekQtyKey] * item.product_group_gross_weight) || 0;
          return total + weight;
        }, 0);
      },
      volumeConvertion() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          const volume = Number(item[this.weekQtyKey] * item.ratio_loading_capacity) || 0;
          return total + volume;
        }, 0);
      },
      computedTotalUnits() {
        return this.weeks.map(week => this.calculateTotalUnit(week));
      },
      computedAdditionalTotalUnits() {
        return this.weeks.map(week => this.calculateAdditionalTotalUnit(week));
      },
      groupedProducts() {
        const groups = [];
        let currentDivision = null;
        let divisionTotals = {
          grand_total_qty: 0,
          w1_qty: 0,
          w2_qty: 0,
          w3_qty: 0,
          w4_qty: 0,
          rolling_forecast_total: 0,
          rolling_forecast_outstanding: 0,
        };

        this.selected_confirm_monthly_order_details_attributes.forEach((item) => {
          const category = item.product_category;
          const division = item.product_division;

          // Check if we're moving to a new division
          if (currentDivision && currentDivision !== division) {
            // Push the division total before starting a new division
            groups.push({
              category: `${currentDivision}`,
              division: currentDivision,
              items: [],
              totals: { ...divisionTotals },
              isDivisionTotal: true, // Mark as division total row
            });

            // Reset division totals
            divisionTotals = {
              grand_total_qty: 0,
              w1_qty: 0,
              w2_qty: 0,
              w3_qty: 0,
              w4_qty: 0,
              rolling_forecast_total: 0,
              rolling_forecast_outstanding: 0,
            };
          }

          // Update the current division
          currentDivision = division;

          // Find or create a group for the current category
          let group = groups.find((g) => g.category === category && g.division === division);
          if (!group) {
            group = {
              category,
              division,
              items: [],
              totals: {
                grand_total_qty: 0,
                w1_qty: 0,
                w2_qty: 0,
                w3_qty: 0,
                w4_qty: 0,
                rolling_forecast_total: 0,
                rolling_forecast_outstanding: 0,
              },
            };
            groups.push(group);
          }

          // Add the item to the group
          group.items.push(item);

          // Update category totals
          group.totals.grand_total_qty += parseFloat(item.grand_total_qty) || 0;
          group.totals.w1_qty += parseFloat(item.w1_qty) || 0;
          group.totals.w2_qty += parseFloat(item.w2_qty) || 0;
          group.totals.w3_qty += parseFloat(item.w3_qty) || 0;
          group.totals.w4_qty += parseFloat(item.w4_qty) || 0;
          group.totals.rolling_forecast_total += parseFloat(item.rolling_forecast_total) || 0;
          group.totals.rolling_forecast_outstanding += parseFloat(item.rolling_forecast_outstanding) || 0;

          // Update division totals
          divisionTotals.grand_total_qty += parseFloat(item.grand_total_qty) || 0;
          divisionTotals.w1_qty += parseFloat(item.w1_qty) || 0;
          divisionTotals.w2_qty += parseFloat(item.w2_qty) || 0;
          divisionTotals.w3_qty += parseFloat(item.w3_qty) || 0;
          divisionTotals.w4_qty += parseFloat(item.w4_qty) || 0;
          divisionTotals.rolling_forecast_total += parseFloat(item.rolling_forecast_total) || 0;
          divisionTotals.rolling_forecast_outstanding += parseFloat(item.rolling_forecast_outstanding) || 0;
        });

        // Add the final division total
        if (currentDivision) {
          groups.push({
            category: `${currentDivision}`,
            division: currentDivision,
            items: [],
            totals: { ...divisionTotals },
            isDivisionTotal: true,
          });
        }
        console.log('groups',groups)
        return groups;
      },
      filteredProducts() {
        if (!this.searchQuery) {
          return this.groupedProducts; // Return all products if there's no search input
        }
        const query = this.searchQuery.toLowerCase();
        
        return this.groupedProducts
          .map(group => {
            const filteredItems = group.items.filter(item =>
              item.sku.toLowerCase().includes(query) ||
              item.product_name.toLowerCase().includes(query) ||
              item.alias_name.toLowerCase().includes(query) ||
              item.product_group.toLowerCase().includes(query)
            );

            if (filteredItems.length > 0) {
              return {
                ...group,
                items: filteredItems, // Only keep the matched items
              };
            }
            return null;
          })
          .filter(group => group !== null); // Remove empty groups
      },
      filteredShipTo() {
        if (!this.searchQuery) return this.distributors;
        const query = this.searchQuery.toLowerCase();
        return this.distributors.filter(d => 
          d.name.toLowerCase().includes(query) ||
          (d.email && d.email.toLowerCase().includes(query)) ||
          (d.code && d.code.includes(query))
        );
      }
    },
    async mounted() {
        const { action, id = null, cmo_ids } = this.$route.params;
        this.cmo_ids = cmo_ids;
        // this.fetchDistributors();
        // this.fetchParentDistributor();
        // this.fetchProductGroup();

        if (this.isAdding) {
          this.populateData();
        } else {
          this.populateCombineDocument(cmo_ids);
        }    
    },
    methods: {
      ...mapActions(['showAlert']),
      async changeCmoType(transaction_type_id  = null, period_id = null, distributor_id = null) {
        this.loading = true;
        try {
          const params = new URLSearchParams();
          if (transaction_type_id) params.append('type', transaction_type_id); // Append type if provided
          if (period_id) params.append('period', period_id.id); // Extract period_id.id for the request
          if (distributor_id) params.append('distributor', distributor_id.id)

          const response = await axios.get(`/confirm_monthly_order/new?${params.toString()}`);

          this.cmo = response.data;
          this.cmo.transaction_date = new Date(response.data.transaction_date);
          this.directTransaction = response.data.is_direct_transaction;
          this.distributionTypeGT = response.data.is_distribution_gt;
          this.periods = [...response.data.period];
          this.cmo.period_id = response.data.period_id;

          this.distributors = response.data.ship_to_list;
          this.cmo.ship_to = response.data.distributor;
          this.cmo.shipment_type = response.data.distributor.cmo_shipment_type[0];
          this.shipmentTypes = response.data.distributor.cmo_shipment_type;
          this.vehicleTypes = [...response.data.distributor.distributor_vehicles]; // Assign Vehicle list based on distributor selected
          this.cmo.ship_to_address = response.data.distributor.address;
          this.cmo.ship_to_city = response.data.distributor.city;
          this.cmo.ship_to_area = response.data.distributor.area;
          this.cmo.ship_to_region = response.data.distributor.region;
          this.transactionTypes = response.data.transaction_types;

          this.cmo.sold_to = response.data.sold_to;
          this.cmo.sold_to_address = response.data.sold_to.address;
          this.confirm_monthly_order_details_attributes = [...response.data.confirm_monthly_order_details_attributes];
          
          // Filter to find data with matching distributor_id
          this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes
              .filter(detail => detail.distributor_id === response.data.distributor.id)
              .map(detail => detail.product_details)
              .flat();
          
          this.largestVehicle(response.data.distributor.distributor_vehicles);

          // Convert dates to JavaScript Date objects
          this.cmo.w1_request_delivery_date = new Date(response.data.w1_request_delivery_date);
          this.cmo.w2_request_delivery_date = new Date(response.data.w2_request_delivery_date);
          this.cmo.w3_request_delivery_date = new Date(response.data.w3_request_delivery_date);
          this.cmo.w4_request_delivery_date = new Date(response.data.w4_request_delivery_date);

          // Convert valid_from and valid_to to Date objects
          this.cmo.w1_valid_from = this.directTransaction ? null : new Date(response.data.w1_valid_from);
          this.cmo.w1_valid_to = this.directTransaction ? null : new Date(response.data.w1_valid_to);

          this.cmo.w2_valid_from = this.directTransaction ? null : new Date(response.data.w2_valid_from);
          this.cmo.w2_valid_to = this.directTransaction ? null : new Date(response.data.w2_valid_to);

          this.cmo.w3_valid_from = this.directTransaction ? null : new Date(response.data.w3_valid_from);
          this.cmo.w3_valid_to = this.directTransaction ? null : new Date(response.data.w3_valid_to);

          this.cmo.w4_valid_from = this.directTransaction ? null : new Date(response.data.w4_valid_from);
          this.cmo.w4_valid_to = this.directTransaction ? null : new Date(response.data.w4_valid_to);

          this.draftDocument = response.data.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
          this.fulfillment_limit_max = response.data.fulfillment_limit_max;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
              ? error.response.data.messages
              : error.response?.message || 'error di awal';

          this.showAlert({ message: errorMessage, color: 'error' });
          this.loading = false;
          this.$router.push({ name: 'cmo' });
        } 
        this.loading = false;
      },
      async populateData(coverage_id = null, period_id = null) {
        this.loading = true;
        try {
          const response = await axios.get(`/confirm_monthly_order/new`);
          this.cmo = response.data;
          this.distributionTypeGT = response.data.is_distribution_gt;
          this.periods = [...response.data.period];
          this.cmo.period_id = response.data.period[0];

          this.distributors = response.data.ship_to_list;
          this.cmo.ship_to = response.data.distributor;
          this.cmo.shipment_type = response.data.distributor.cmo_shipment_type[0];
          this.shipmentTypes = response.data.distributor.cmo_shipment_type;
          this.vehicleTypes = [...response.data.distributor.distributor_vehicles];
          this.cmo.ship_to_address = response.data.distributor.address;
          this.cmo.ship_to_city = response.data.distributor.city;
          this.cmo.ship_to_area = response.data.distributor.area;
          this.cmo.ship_to_region = response.data.distributor.region;
          this.cmo.transaction_date = new Date(response.data.transaction_date);

          this.cmo.sold_to = response.data.sold_to;
          this.cmo.sold_to_address = response.data.sold_to.address;
          this.confirm_monthly_order_details_attributes = [...response.data.confirm_monthly_order_details_attributes];
          
          // Filter to find data with matching distributor_id
          this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes
              .filter(detail => detail.distributor_id === response.data.distributor.id)
              .map(detail => detail.product_details)
              .flat();

          // Convert dates to JavaScript Date objects
          this.cmo.w1_request_delivery_date = new Date(response.data.w1_request_delivery_date);
          this.cmo.w2_request_delivery_date = new Date(response.data.w2_request_delivery_date);
          this.cmo.w3_request_delivery_date = new Date(response.data.w3_request_delivery_date);
          this.cmo.w4_request_delivery_date = new Date(response.data.w4_request_delivery_date);

          // Convert valid_from and valid_to to Date objects
          this.cmo.w1_valid_from = new Date(response.data.w1_valid_from);
          this.cmo.w1_valid_to = new Date(response.data.w1_valid_to);

          this.cmo.w2_valid_from = new Date(response.data.w2_valid_from);
          this.cmo.w2_valid_to = new Date(response.data.w2_valid_to);

          this.cmo.w3_valid_from = new Date(response.data.w3_valid_from);
          this.cmo.w3_valid_to = new Date(response.data.w3_valid_to);

          this.cmo.w4_valid_from = new Date(response.data.w4_valid_from);
          this.cmo.w4_valid_to = new Date(response.data.w4_valid_to);

          // Find the vehicle with the largest weight
          this.largestVehicle(response.data.distributor.distributor_vehicles);

          this.product_groups = response.data.product_groups;
          this.product_groups_name = response.data.product_groups.map(group => group.name);
          this.transactionTypes = response.data.transaction_types;

          this.draftDocument = response.data.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
          this.fulfillment_limit_max = response.data.fulfillment_limit_max;

          this.directTransaction = response.data.is_direct_transaction;
          this.distributionTypeGT = response.data.is_distribution_gt;
          this.formulas = response.data.formulas;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
              ? error.response.data.messages
              : error.response?.message || 'error di awal';

          this.showAlert({ message: errorMessage, color: 'error' });
          this.loading = false;
          this.$router.push({ name: 'cmo' });
        } finally {
          // Just Trigger calculation at the first time
          if (this.selected_confirm_monthly_order_details_attributes.length > 0) {
            // this.updateValue(this.selected_confirm_monthly_order_details_attributes[0], 'grand_total_qty', 1)
            // this.updateValue(this.selected_confirm_monthly_order_details_attributes[1], 'grand_total_qty', 2)
            // this.updateValue(this.selected_confirm_monthly_order_details_attributes[2], 'grand_total_qty', 3)
            // this.updateValue(this.selected_confirm_monthly_order_details_attributes[3], 'grand_total_qty', 4)
          }
        }
        this.loading = false;
      },
      async populateCombineDocument(cmo_ids) {
        this.loading = true;
        try {
          const response = await axios.get('/confirm_monthly_order/combine', {
            params: { cmo_ids: cmo_ids }, // Pass query parameters here
          });

          this.cmo = response.data;
          this.periods = [...response.data.period];
          this.cmo.period_id = response.data.period[0];

          this.distributors = response.data.ship_to_list;
          this.cmo.ship_to = response.data.distributor;
          this.shipmentTypes = response.data.distributor.cmo_shipment_type;
          this.vehicleTypes = [...response.data.distributor.distributor_vehicles];
          this.cmo.ship_to_address = response.data.distributor.address;
          this.cmo.ship_to_city = response.data.distributor.city;
          this.cmo.ship_to_area = response.data.distributor.area;
          this.cmo.ship_to_region = response.data.distributor.region;
          this.cmo.transaction_date = new Date(response.data.transaction_date);

          this.cmo.sold_to = response.data.sold_to;
          this.cmo.sold_to_address = response.data.sold_to.address;
          this.confirm_monthly_order_details_attributes = [...response.data.confirm_monthly_order_details_attributes];
          this.weeks = response.data.confirm_monthly_order_vehicle_details_attributes;

          // Filter to find data with matching distributor_id1
          this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes
                .filter(detail => response.data.combined_distributors.includes(detail.distributor_id))
                .map(detail => detail.product_details)
                .flat();
                
          // Convert dates to JavaScript Date objects
          this.cmo.w1_request_delivery_date = new Date(response.data.w1_request_delivery_date);
          this.cmo.w2_request_delivery_date = new Date(response.data.w2_request_delivery_date);
          this.cmo.w3_request_delivery_date = new Date(response.data.w3_request_delivery_date);
          this.cmo.w4_request_delivery_date = new Date(response.data.w4_request_delivery_date);

          // Convert valid_from and valid_to to Date objects
          this.cmo.w1_valid_from = new Date(response.data.w1_valid_from);
          this.cmo.w1_valid_to = new Date(response.data.w1_valid_to);

          this.cmo.w2_valid_from = new Date(response.data.w2_valid_from);
          this.cmo.w2_valid_to = new Date(response.data.w2_valid_to);

          this.cmo.w3_valid_from = new Date(response.data.w3_valid_from);
          this.cmo.w3_valid_to = new Date(response.data.w3_valid_to);

          this.cmo.w4_valid_from = new Date(response.data.w4_valid_from);
          this.cmo.w4_valid_to = new Date(response.data.w4_valid_to);

          // Find the vehicle with the largest weight
          this.largestVehicle(response.data.distributor.distributor_vehicles);

          this.cmo.combined_cmo_ids = response.data.combined_cmo_ids;
          this.cmo.is_combined_document = response.data.is_combined_document;

          this.cmo.purchase_order_date = new Date(response.data.purchase_order_date);
          this.cmo.purchase_order_expired_date = new Date(response.data.purchase_order_expired_date);
          this.cmo.purchase_order_number = response.data.purchase_order_number;

          this.headTabsData = [...response.data.combined_cmo_ids];

          this.product_groups = response.data.product_groups;
          this.product_groups_name = response.data.product_groups.map(group => group.name);
          this.transactionTypes = response.data.transaction_types;

          this.draftDocument = response.data.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
          this.fulfillment_limit_max = response.data.fulfillment_limit_max;
          this.formulas = response.data.formulas;
          this.loading = false;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'Error occurred';
          this.showAlert({ message: errorMessage, color: 'error' });
          this.$router.push({ name: 'cmo' });
        } finally {
          this.loading = false;

          // Need to recalculate the vehicle on each week
          for (let i = 0; i <= 4; i++) {
            this.calculateVehicleQty(i);
          }
        }
      },
      // async handleSubmit(type) {
      //   this.promoConfirmation = false;
      //   const status = type === 0 ? 'Draft' : 'Awaiting for Approval';
      //     try {
      //         const cmoParams = {
      //           accept_promotion: this.cmo.accept_promotion,
      //           transaction_date: this.cmo.transaction_date.toLocaleDateString('en-CA'),
      //           period_id: this.cmo.period_id.id,
      //           transaction_type_id: this.cmo.transaction_type_id,
      //           shipment_type: this.cmo.shipment_type,
      //           distributor_id: this.cmo.ship_to.id,
      //           purchase_order_number: this.cmo.purchase_order_number || null,
      //           purchase_order_date: this.cmo.purchase_order_date
      //             ? this.cmo.purchase_order_date.toLocaleDateString('en-CA')
      //             : null,
      //           purchase_order_expired_date: this.cmo.purchase_order_expired_date
      //             ? this.cmo.purchase_order_expired_date.toLocaleDateString('en-CA')
      //             : null,
      //             w1_valid_from: this.cmo.w1_valid_from
      //             ? this.cmo.w1_valid_from.toLocaleDateString('en-CA')
      //             : null,
      //           w1_valid_to: this.cmo.w1_valid_to
      //             ? this.cmo.w1_valid_to.toLocaleDateString('en-CA')
      //             : null,
      //           w2_valid_from: this.cmo.w2_valid_from
      //             ? this.cmo.w2_valid_from.toLocaleDateString('en-CA')
      //             : null,
      //           w2_valid_to: this.cmo.w2_valid_to
      //             ? this.cmo.w2_valid_to.toLocaleDateString('en-CA')
      //             : null,
      //           w3_valid_from: this.cmo.w3_valid_from
      //             ? this.cmo.w3_valid_from.toLocaleDateString('en-CA')
      //             : null,
      //           w3_valid_to: this.cmo.w3_valid_to
      //             ? this.cmo.w3_valid_to.toLocaleDateString('en-CA')
      //             : null,
      //           w4_valid_from: this.cmo.w4_valid_from
      //             ? this.cmo.w4_valid_from.toLocaleDateString('en-CA')
      //             : null,
      //           w4_valid_to: this.cmo.w4_valid_to
      //             ? this.cmo.w4_valid_to.toLocaleDateString('en-CA')
      //             : null,
      //           w1_active: this.cmo.w1_active,
      //           w2_active: this.cmo.w2_active,
      //           w3_active: this.cmo.w3_active,
      //           w4_active: this.cmo.w4_active,
      //           w1_request_delivery_date: this.cmo.w1_request_delivery_date
      //             ? this.cmo.w1_request_delivery_date.toLocaleDateString('en-CA')
      //             : null,
      //           w2_request_delivery_date: this.cmo.w2_request_delivery_date
      //             ? this.cmo.w2_request_delivery_date.toLocaleDateString('en-CA')
      //             : null,
      //           w3_request_delivery_date: this.cmo.w3_request_delivery_date
      //             ? this.cmo.w3_request_delivery_date.toLocaleDateString('en-CA')
      //             : null,
      //           w4_request_delivery_date: this.cmo.w4_request_delivery_date
      //             ? this.cmo.w4_request_delivery_date.toLocaleDateString('en-CA')
      //             : null,
      //           grand_total_order_qty: this.grandTotalQty,
      //           total_weight: this.totalWeight,
      //           total_volume: this.totalVolume,
      //           status: status,
      //           confirm_monthly_order_details_attributes: this.selected_confirm_monthly_order_details_attributes,
      //           confirm_monthly_order_vehicle_details_attributes: !this.directTransaction ? this.weeks : null,
      //         };

      //         if (this.$route.params.action === 'add') {
      //           const response = await axios.post('/confirm_monthly_order', { confirm_monthly_order: cmoParams });

      //           this.showAlert({ message: 'CMO data successfully saved', color: 'success' });
      //           if (response.data) {
      //             this.loading = false
      //             this.$router.push({ name: 'cmo-view', 
      //               params: { action: 'view', id: response.data.data.id },
      //             });
      //           } else {
      //             throw new Error(response.message);
      //           }
      //         } else if(this.$route.params.action === 'combine') {
      //           let response;

      //           if(this.headTabsData.findIndex(tab => tab === this.selectedHeadTab) < 1){
      //             cmoParams.combined_cmo_ids = this.cmo.combined_cmo_ids;
      //             cmoParams.is_combined_document= true;
      //             response = await axios.post('/confirm_monthly_order', { confirm_monthly_order: cmoParams });

      //             if (response.data) {
      //               this.loading = false
      //               this.$router.push({ name: 'cmo-view', 
      //                 params: { action: 'view', id: response.data.data.id },
      //               });
      //               this.showAlert({ message: 'CMO data successfully saved', color: 'success' });
      //             } else {
      //               throw new Error(response.message);
      //             }
      //           } else {
      //             cmoParams.status = "Awaiting Combining"
      //             response = await axios.put(`/confirm_monthly_order/${this.cmo.id}`, { confirm_monthly_order: cmoParams });
      //             if (response.data) {
      //               // this.loading = false;
      //               this.showAlert({ message: 'CMO data successfully saved', color: 'success' });
      //               this.populateCombineDocument(this.cmo_ids)
      //             } else {
      //               throw new Error(response.message);
      //             }
      //           }
      //         } else {
      //           const updateStatus = 'Awaiting for Approval';
      //           const response = await axios.put(`/confirm_monthly_order/${this.cmo.id}/${updateStatus}`, { confirm_monthly_order: rofoData });
      //           this.showAlert({ message: 'CMO data successfully update', color: 'success' });
      //           if (response.data) {
                 
      //           } else {
      //             throw new Error('Failed to update rofo');
      //           }
      //         }
      //     } catch (error) {
      //       console.log(error)
      //           const errorMessage = error.response?.data?.messages
      //             ? error.response.data.messages 
      //             : error.response?.message || 'An error occurred';

      //           this.showAlert({ message: errorMessage, color: 'error' });
      //     }
      // },
      async handleSubmit(type) {
        this.promoConfirmation = false;
        const status = type === 0 ? 'Draft' : 'Awaiting for Approval';

        try {
            const formData = new FormData();

            // ✅ Helper function: Append only non-null values & handle numbers properly
            const appendIfNotNull = (key, value) => {
                if (value !== null && value !== undefined && value !== '') {
                    formData.append(key, value);
                }
            };

            // ✅ Append Standard Fields
            appendIfNotNull('confirm_monthly_order[accept_promotion]', this.cmo.accept_promotion);
            appendIfNotNull('confirm_monthly_order[transaction_date]', this.cmo.transaction_date?.toLocaleDateString('en-CA'));
            appendIfNotNull('confirm_monthly_order[period_id]', this.cmo.period_id?.id);
            appendIfNotNull('confirm_monthly_order[transaction_type_id]', this.cmo.transaction_type_id);
            appendIfNotNull('confirm_monthly_order[shipment_type]', this.cmo.shipment_type);
            appendIfNotNull('confirm_monthly_order[distributor_id]', this.cmo.ship_to?.id);
            appendIfNotNull('confirm_monthly_order[purchase_order_number]', this.cmo.purchase_order_number);
            appendIfNotNull('confirm_monthly_order[purchase_order_date]', this.cmo.purchase_order_date?.toLocaleDateString('en-CA'));
            appendIfNotNull('confirm_monthly_order[purchase_order_expired_date]', this.cmo.purchase_order_expired_date?.toLocaleDateString('en-CA'));
            appendIfNotNull('confirm_monthly_order[status]', status);

            // ✅ Append `combined_cmo_ids` as an array of objects (Fixed Approach)
            if (Array.isArray(this.cmo.combined_cmo_ids) && this.cmo.combined_cmo_ids.length > 0) {
                this.cmo.combined_cmo_ids.forEach(item => {
                    formData.append('confirm_monthly_order[combined_cmo_ids][][id]', item.id !== undefined ? item.id : '');
                    formData.append('confirm_monthly_order[combined_cmo_ids][][ship_to]', item.ship_to !== undefined ? item.ship_to : '');
                });
            }

            // ✅ Append week-related valid date and request delivery dates
            ['w1', 'w2', 'w3', 'w4'].forEach(week => {
                appendIfNotNull(`confirm_monthly_order[${week}_valid_from]`, this.cmo[`${week}_valid_from`]?.toLocaleDateString('en-CA'));
                appendIfNotNull(`confirm_monthly_order[${week}_valid_to]`, this.cmo[`${week}_valid_to`]?.toLocaleDateString('en-CA'));
                appendIfNotNull(`confirm_monthly_order[${week}_request_delivery_date]`, this.cmo[`${week}_request_delivery_date`]?.toLocaleDateString('en-CA'));
                appendIfNotNull(`confirm_monthly_order[${week}_active]`, this.cmo[`${week}_active`]);
            });

            // ✅ Append numerical summary fields
            appendIfNotNull('confirm_monthly_order[grand_total_order_qty]', this.grandTotalQty);
            appendIfNotNull('confirm_monthly_order[total_weight]', this.totalWeight);
            appendIfNotNull('confirm_monthly_order[total_volume]', this.totalVolume);

            // ✅ Attach file if present
            if (this.file) {
                formData.append('confirm_monthly_order[file]', this.file);
            }

            // ✅ Append confirm_monthly_order_details_attributes correctly (Fixed Approach)
            if (Array.isArray(this.selected_confirm_monthly_order_details_attributes) && this.selected_confirm_monthly_order_details_attributes.length > 0) {
                this.selected_confirm_monthly_order_details_attributes.forEach((detail, index) => {
                    Object.keys(detail).forEach(key => {
                        appendIfNotNull(`confirm_monthly_order[confirm_monthly_order_details_attributes][${index}][${key}]`, detail[key]);
                    });
                });
            }

            // ✅ Append confirm_monthly_order_vehicle_details_attributes correctly (Fixed Approach)
            if (!this.directTransaction && Array.isArray(this.weeks) && this.weeks.length > 0) {
                this.weeks.forEach((week, index) => {
                    Object.keys(week).forEach(key => {
                        appendIfNotNull(`confirm_monthly_order[confirm_monthly_order_vehicle_details_attributes][${index}][${key}]`, week[key]);
                    });
                });
            }

            // ✅ Debugging: Log all FormData before sending
            console.log("🚀 FormData being sent:");
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]);
            }

            let response;
            if (this.$route.params.action === 'add') {
                response = await axios.post('/confirm_monthly_order', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else if (this.$route.params.action === 'combine') {
                if (this.headTabsData.findIndex(tab => tab === this.selectedHeadTab) < 1) {
                    // New Combined CMO - Send POST request
                    appendIfNotNull('confirm_monthly_order[is_combined_document]', true);

                    response = await axios.post('/confirm_monthly_order', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });

                    if (response.data) {
                        this.loading = false;
                        this.$router.push({ name: 'cmo-view', params: { action: 'view', id: response.data.data.id } });
                        this.showAlert({ message: 'CMO data successfully saved', color: 'success' });
                    } else {
                        throw new Error(response.message);
                    }
                } else {
                    // Updating an Existing Combined CMO - Send PUT request
                    appendIfNotNull('confirm_monthly_order[status]', 'Awaiting Combining');

                    response = await axios.put(`/confirm_monthly_order/${this.cmo.id}`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });

                    if (response.data) {
                        this.showAlert({ message: 'CMO data successfully saved', color: 'success' });
                        this.populateCombineDocument(this.cmo_ids);
                    } else {
                        throw new Error(response.message);
                    }
                }
            } else {
                response = await axios.put(`/confirm_monthly_order/${this.cmo.id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }

            this.showAlert({ message: 'CMO data successfully saved', color: 'success' });

            if (response.data) {
                this.$router.push({ name: 'cmo-view', params: { action: 'view', id: response.data.data.id } });
            }
        } catch (error) {
            console.log(error);
            const errorMessage = error.response?.data?.messages || error.response?.message || 'An error occurred';
            this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      submitForm(type) {
        this.type = type;
        if (type == 0) {
          if (this.cmo.promotions.length > 0 && !this.cmo.is_combined_document) {
            this.promoConfirmation = true;
          } else {
            this.handleSubmit(this.type);  
          }
        } else {
          this.handleSubmit(this.type);
        }
      },
      toggleFullScreen() {
          this.isBottomSheetOpen = true;
      },
      viewRecommendation(vehicle) {
          // Handle viewing quantity recommendation logic here
          console.log('Viewing recommendation for:', vehicle);
      },
      async fetchTransactionTypes() {
          try {
              const response = await axios.get('/transaction_type');
              this.transactionTypes = response.data;
          } catch (error) {
              const errorMessage = error.response?.data?.messages
                  ? error.response.data.messages
                  : error.response?.message || 'An error occurred';

              this.showAlert({ message: errorMessage, color: 'error' });
          }
      },
      async fetchParentDistributor() {
        try {
          const response = await axios.get('/distributor?is_parent=true');
          this.parentDistributors = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchDistributors(parent_id) {
        try {
        //   const response = await axios.get(`/distributor?parent_id=${parent_id}`);
        const response = await axios.get(`/distributor`);
          this.distributors = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchProductGroup() {
        try {
        const response = await axios.get(`/product_group`);
          this.product_groups = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      soldToChanged(event) {
        if(event) {
          this.fetchDistributors(event.id)  
        } else {
          this.distributors = []
        }
      },
      shipToChanged(event) {
        this.cmo.ship_to_address = event.address;
        this.cmo.ship_to_city = event.city;
        this.cmo.ship_to_area = event.area;
        this.cmo.ship_to_region = event.region;
        this.shipmentTypes = event.cmo_shipment_type;
        this.vehicleTypes = event.distributor_vehicles;
        this.cmo.shipment_type = null;
        this.largestVehicle(event.distributor_vehicles);
        this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes
              .filter(detail => detail.distributor_id === this.cmo.ship_to.id)
              .map(detail => detail.product_details)
              .flat();
      },
      largestVehicle(vehicles) {
        const maxWeightVehicle = vehicles.reduce((max, vehicle) =>
          vehicle.vehicle_id.weight > (max?.vehicle_id.weight || 0) ? vehicle : max, null
        );

        if (maxWeightVehicle) {
          // Assign the highest weight
          this.weeks = this.weeks.map(week => ({
              ...week,
              vehicle_id: maxWeightVehicle.vehicle_id.id,
              additional_vehicle_id: maxWeightVehicle.vehicle_id.id
          }));
        }
      },
      dateFilter(date, requestDeliveryDate) {
        const currentDate = new Date(date);
        const startBlockedDate = new Date(requestDeliveryDate);
        const endBlockedDate = new Date(requestDeliveryDate);
        endBlockedDate.setDate(endBlockedDate.getDate() + 7); // Add 7 days

        // Allow only the dates between startBlockedDate and endBlockedDate
        return currentDate >= startBlockedDate && currentDate <= endBlockedDate;
      },
      validFromAndToAllowedDate(purchaseOrderDate, purchaseOrderExpiredDate) {
        // Parse the dates to handle them accurately
        const startDate = new Date(purchaseOrderDate);
        const endDate = new Date(purchaseOrderExpiredDate);

        // Return a function that validates if the date is within range
        return (date) => {
            const selectedDate = new Date(date);
            return selectedDate >= startDate && selectedDate <= endDate;
        };
      },
      updateValue(item, type, week=null) {
        switch (type) {
            case 'grand_total_qty':
              item.grand_total_qty = this.calculateGrandTotalQty(Number(item.w1_qty), Number(item.w2_qty), Number(item.w3_qty), Number(item.w4_qty));
              item.rolling_forecast_outstanding = this.calculateOutstading(item.grand_total_qty, item.rolling_forecast_total);
              if(!this.directTransaction || this.distributionTypeGT) {
                this.calculateVehicleQty(week);
              }
              break;
            case 'w2':
                
                break;
          }
      },
      productGroupGrossWeight(group) {
        if(this.product_groups.length > 0) {
          const product_group = this.product_groups.find(product_group => product_group.name === group);
          return product_group ? (product_group.gross_weight) : 0;
        }
      },
      productGroupRatioBasisBag(group){
        if(this.product_groups.length > 0) {
          const product_group = this.product_groups.find(product_group => product_group.name === group);
          return product_group ? (product_group.ratio_loading_capacity) : 0;
        }
      },
      // calculateVehicleQty(wk = null) {
      //   this.weeks.forEach((week, index) => { 
      //     if (wk !== null && wk === index + 1) {
      //       this.weekQtyKey = `w${wk}_qty`;
      //       const berat = Math.ceil(this.weightConvertion);
      //       const volume = Math.ceil(this.volumeConvertion);
      //       const maxLimit = this.fulfillment_limit_max;

      //       try {
      //         week.same_type ? week.additional_vehicle_id = week.vehicle_id : week.additional_vehicle_id;
      //         const vehicle = this.vehicleTypes.find(v => v.vehicle_id.id === week.vehicle_id);
      //         const additionalVehicle = this.vehicleTypes.find(v => v.vehicle_id.id === week.additional_vehicle_id);

      //         if (vehicle) {
      //           const vehicleWeight = Math.round(vehicle.vehicle_id.weight * maxLimit);
      //           const vehicleCapacity = Math.round(vehicle.vehicle_id.loading_ratio_in_crt * maxLimit);

      //           const volumeCapacity = volume / vehicleCapacity;
      //           const weightCapacity = berat / vehicleWeight;
      //           let maxCapacity = Math.max(volumeCapacity, weightCapacity);
      //           const excess = (maxCapacity - Math.trunc(maxCapacity)).toFixed(2);

      //           if(week.same_type) {
      //             week.total_unit = maxCapacity.toFixed(2); // Get the integer part
      //           } else {
      //             if (maxCapacity < 1) {
      //               week.total_unit = Math.floor(maxCapacity).toFixed(2)
      //             }
      //             maxCapacity = maxCapacity.toFixed(2) - week.total_unit;
      //           }
      //           // week.additional_total_unit = (maxCapacity - week.total_unit).toFixed(2); // Get the decimal part
      //           week.fulfillment_cbm = volumeCapacity;
      //           week.fulfillment_kg = weightCapacity;

      //           // Identify "Small" group dynamically by name
      //           const smallGroup = this.product_groups.find(pg => pg.name === 'Small');
      //           let smallRec = 0;
      //           let smallReduce = 0;

      //           const groupResults = {};

      //           this.product_groups.forEach(product_group => {
      //             const grossWeight = this.productGroupGrossWeight(product_group.name);
      //             const ratio = this.productGroupRatioBasisBag(product_group.name);

      //             // Determine if the group depends on "Small"
      //             const isDependent = product_group.name !== 'X-Small' && product_group.name !== 'Small';

      //             let formulaVars = {
      //               excess: excess,
      //               vehicle_weight: vehicleWeight,
      //               vehicle_capacity: vehicleCapacity,
      //               gross_weight: grossWeight,
      //               ratio: ratio,
      //               small_rec: smallRec,
      //               small_reduce: smallReduce
      //             };

      //             let rec = evalFormula(this.formulas.recommendation_qty_formula, formulaVars);
      //             let reduce = evalFormula(this.formulas.reduction_qty_formula, formulaVars);

      //             if (isDependent && smallGroup) {
      //               rec = evalFormula(this.formulas.dependent_recommendation_qty_formula, formulaVars);
      //               reduce = evalFormula(this.formulas.dependent_reduction_qty_formula, formulaVars);
      //             }

      //             // Store results for the group
      //             groupResults[product_group.name] = { rec, reduce };

      //             // Update smallRec and smallReduce if processing "Small"
      //             if (product_group.name === 'Small') {
      //               smallRec = rec;
      //               smallReduce = reduce;
      //             }
      //           });

      //           // Assign recommendations and reductions to the week object
      //           this.product_groups.forEach(product_group => {
      //             const { rec, reduce } = groupResults[product_group.name];
      //             const groupKey = product_group.name.toLowerCase().replace(/-/g, '');
      //             week[`${groupKey}_recomendation`] = rec;
      //             week[`${groupKey}_reduce`] = reduce;
      //           });

      //           if (additionalVehicle) {
                  
      //             const additionalVehicleWeight = Math.round(additionalVehicle.vehicle_id.weight * maxLimit);
      //             const additionalVehicleCapacity = Math.round(additionalVehicle.vehicle_id.loading_ratio_in_crt * maxLimit);

      //             // Calculate actual remaining volume and weight after main vehicle allocation
      //             const remainingVolume = volume - (Math.floor(week.total_unit) * vehicleCapacity);
      //             const remainingWeight = berat - (Math.floor(week.total_unit) * vehicleWeight);

      //             // Calculate how much the additional vehicle can take
      //             const additionalVolumeCapacity = remainingVolume / additionalVehicleCapacity;
      //             const additionalWeightCapacity = remainingWeight / additionalVehicleWeight;
      //             const additionalMaxCapacity = Math.max(additionalVolumeCapacity, additionalWeightCapacity);

      //             // Assign remaining capacity to the additional vehicle
      //             week.additional_total_unit = additionalMaxCapacity.toFixed(2);
      //             console.log('AddTotal calculation:', additionalMaxCapacity )
      //             if(maxCapacity > 1 && week.same_type) {
      //               week.total_unit = Math.floor(maxCapacity);
      //               // week.additional_total_unit = (maxCapacity - Math.floor(maxCapacity)).toFixed(2);
      //               console.log('AddTotal calculation 1:', additionalMaxCapacity , Math.floor(maxCapacity), maxCapacity );
      //             } else if(!week.same_type) {
      //               if(week.total_unit >= 1) {
      //                 week.additional_total_unit = additionalMaxCapacity.toFixed(2);
      //               } else {
      //                 week.total_unit = maxCapacity.toFixed(2);
      //                 week.additional_total_unit = 0;
      //               }
      //               console.log('AddTotal calculation 2:', additionalMaxCapacity , Math.floor(maxCapacity), maxCapacity );
      //             }else {
      //               console.log('AddTotal calculation 3:', additionalMaxCapacity , Math.floor(maxCapacity), maxCapacity );
      //               week.additional_total_unit = 0;
      //             }

                  
      //              // if(maxCapacity < 1) {
      //             //   week.total_unit = maxCapacity.toFixed(2);
      //             //   week.additional_total_unit = 0;
      //             // }
      //             // week.total_unit = maxCapacity;                
                  
      //             additionalMaxCapacity.toFixed(2) > 0 ? week.fulfillment_cbm = additionalVolumeCapacity : volumeCapacity
      //             additionalMaxCapacity.toFixed(2) > 0 ? week.fulfillment_kg = additionalWeightCapacity : weightCapacity;

      //             // Identify "Small" group dynamically by name
      //             const smallGroup = this.product_groups.find(pg => pg.name === 'Small');
      //             let smallRec = 0;
      //             let smallReduce = 0;

      //             const groupResults = {};

      //             this.product_groups.forEach(product_group => {
      //               const grossWeight = this.productGroupGrossWeight(product_group.name);
      //               const ratio = this.productGroupRatioBasisBag(product_group.name);

      //               // Determine if the group depends on "Small"
      //               const isDependent = product_group.name !== 'X-Small' && product_group.name !== 'Small';

      //               // Calculate Here
      //               // Calculate excess capacity for the additional vehicle
      //               const additionalExcess = Number((additionalMaxCapacity - Math.trunc(additionalMaxCapacity)).toFixed(2));

      //               const additionalFormulaVars = {
      //                 excess: additionalExcess,
      //                 vehicle_weight: additionalVehicleWeight,
      //                 vehicle_capacity: additionalVehicleCapacity,
      //                 gross_weight: grossWeight,
      //                 ratio: ratio,
      //                 small_rec: smallRec,
      //                 small_reduce: smallReduce
      //               };

      //               let rec = evalFormula(this.formulas.recommendation_qty_formula, additionalFormulaVars);
      //               let reduce = evalFormula(this.formulas.reduction_qty_formula, additionalFormulaVars);

      //               if (isDependent && smallGroup) {
      //                 rec = evalFormula(this.formulas.dependent_recommendation_qty_formula, additionalFormulaVars);
      //                 reduce = evalFormula(this.formulas.dependent_reduction_qty_formula, additionalFormulaVars);
      //               }
      //               // Store results for the group
      //               groupResults[product_group.name] = { rec, reduce };

      //               // Update smallRec and smallReduce if processing "Small"
      //               if (product_group.name === 'Small') {
      //                 smallRec = rec;
      //                 smallReduce = reduce;
      //               }
      //             });

      //             // Assign recommendations and reductions to the week object
      //             this.product_groups.forEach(product_group => {
      //               const { rec, reduce } = groupResults[product_group.name];
      //               const groupKey = product_group.name.toLowerCase().replace(/-/g, '');
      //               week[`${groupKey}_recomendation`] = rec;
      //               week[`${groupKey}_reduce`] = reduce;
      //             });

      //             console.log('MASUK', this.product_groups)
      //           } else {
      //             // If no additional vehicle, store the excess directly
      //             week.additional_total_unit = 0;
      //           }
      //         } else {
      //           // Default values if no vehicle is found
      //           week.total_unit = 0;
      //           week.fulfillment_cbm = '0.00';
      //           week.fulfillment_kg = '0.00';

      //           this.product_groups.forEach(product_group => {
      //             const groupKey = product_group.name.toLowerCase().replace(/-/g, '');
      //             week[`${groupKey}_recomendation`] = 0;
      //             week[`${groupKey}_reduce`] = 0;
      //           });
      //         }
      //       } catch (error) {
      //         this.snackbar.show = true;
      //         this.snackbar.message = error.message;
      //       }
      //     }
      //   });

      //   if (this.cmo.is_combined_document) {
      //     this.combinedWeeks = this.weeks;
      //   }

      //   // Utility function to evaluate the formula string safely
      //   function evalFormula(formula, vars) {
      //     try {
      //       // Extract variable keys and values
      //       const variableKeys = Object.keys(vars);
      //       const variableValues = Object.values(vars);

      //       // Construct a function with variables as parameters
      //       const fn = new Function(...variableKeys, `return ${formula};`);

      //       // Execute the function with actual values
      //       const result = fn(...variableValues);

      //       // Return the rounded result
      //       return typeof result === "number" && isFinite(result) && !isNaN(result) 
      //         ? result 
      //         : 0;
      //     } catch (error) {
      //       console.error("Formula evaluation error:", error);
      //       return 0;
      //     }
      //   }
      // },
      calculateVehicleQty(wk = null) {
        this.weeks.forEach((week, index) => { 
          if (wk !== null && wk === index + 1) {
            this.weekQtyKey = `w${wk}_qty`;
            const berat = (this.weightConvertion);
            const volume = this.volumeConvertion;
            const maxLimit = this.fulfillment_limit_max;

            try {
              // Assign additional vehicle properly
              if (week.same_type) {
                week.additional_vehicle_id = week.vehicle_id;
              }

              const vehicle = this.vehicleTypes.find(v => v.vehicle_id.id === week.vehicle_id);
              const additionalVehicle = this.vehicleTypes.find(v => v.vehicle_id.id === week.additional_vehicle_id);

              if (vehicle) {
                const vehicleWeight = vehicle.vehicle_id.weight * maxLimit;
                const vehicleCapacity = vehicle.vehicle_id.loading_ratio_in_crt * maxLimit;

                const volumeCapacity = volume / vehicleCapacity;
                const weightCapacity = berat / vehicleWeight;
                let maxCapacity = Math.max(volumeCapacity, weightCapacity);
                
                // const excess = (maxCapacity - Math.floor(maxCapacity)); // 2 Type
                const excess = (maxCapacity - Math.trunc(maxCapacity))
                if (week.same_type) {
                  // week.total_unit = Math.floor(maxCapacity);
                  week.total_unit = maxCapacity.toFixed(2)
                } else {
                  if (maxCapacity < 1) {
                    week.total_unit = Math.floor(maxCapacity);
                  }
                  maxCapacity = maxCapacity - week.total_unit;
                }
                week.fulfillment_cbm = volumeCapacity;
                week.fulfillment_kg = weightCapacity;

                // Compute product group recommendations
                this.assignProductGroupCalculations(week, vehicleWeight, vehicleCapacity, excess);

                if (additionalVehicle) {
                  const additionalVehicleWeight = additionalVehicle.vehicle_id.weight * maxLimit;
                  const additionalVehicleCapacity = additionalVehicle.vehicle_id.loading_ratio_in_crt * maxLimit;

                  // Calculate remaining volume and weight
                  const remainingVolume = volume - (Math.floor(week.total_unit) * vehicleCapacity);
                  const remainingWeight = berat - (Math.floor(week.total_unit) * vehicleWeight);
                  const additionalVolumeCapacity = remainingVolume / additionalVehicleCapacity;
                  const additionalWeightCapacity = remainingWeight / additionalVehicleWeight;
                  const additionalMaxCapacity = Math.max(additionalVolumeCapacity, additionalWeightCapacity);

                  week.additional_total_unit = additionalMaxCapacity.toFixed(2);

                  if (maxCapacity > 1 && week.same_type) {
                    week.total_unit = Math.floor(maxCapacity);
                  } else if (!week.same_type) {
                    if (week.total_unit >= 1) {
                      week.additional_total_unit = additionalMaxCapacity.toFixed(2);
                    } else {
                      week.total_unit = maxCapacity.toFixed(2);
                      week.additional_total_unit = 0;
                    }
                  } else {
                    week.additional_total_unit = 0;
                  }

                  week.fulfillment_cbm = additionalMaxCapacity > 0 ? additionalVolumeCapacity : volumeCapacity;
                  week.fulfillment_kg = additionalMaxCapacity > 0 ? additionalWeightCapacity : weightCapacity;

                  // Compute additional vehicle product group recommendations
                  this.assignProductGroupCalculations(week, additionalVehicleWeight, additionalVehicleCapacity, additionalMaxCapacity - Math.floor(additionalMaxCapacity));
                } else {
                  week.additional_total_unit = 0;
                }
              } else {
                this.resetWeekValues(week);
              }
            } catch (error) {
              this.snackbar.show = true;
              this.snackbar.message = error.message;
            }
          }
        });

        if (this.cmo.is_combined_document) {
          this.combinedWeeks = this.weeks;
        }
      },
      assignProductGroupCalculations(week, vehicleWeight, vehicleCapacity, excess) {
        const smallGroup = this.product_groups.find(pg => pg.name === 'Small');
        
        let smallRec = 0;
        let smallReduce = 0;
        let smallRec2 = 0;
        let smallReduce2 = 0;

        const groupResults = {};

        this.product_groups.forEach(product_group => {
          const grossWeight = this.productGroupGrossWeight(product_group.name);
          const ratio = this.productGroupRatioBasisBag(product_group.name);
          const largeGroup = product_group.name === 'Large';
          const isDependent =  product_group.name !== 'Small';

          let formulaVars = {
            excess: excess, // Truncated MaxCapacity
            vehicle_weight: vehicleWeight,
            vehicle_capacity: vehicleCapacity,
            gross_weight: grossWeight,
            ratio: ratio,
            small_rec: smallRec,
            small_reduce: smallReduce
          };
          
          let formulaVars2 = {
            excess: excess.toFixed(2), // Truncated MaxCapacity
            vehicle_weight: vehicleWeight,
            vehicle_capacity: vehicleCapacity,
            gross_weight: grossWeight,
            ratio: ratio,
            small_rec: smallRec2,
            small_reduce: smallReduce2
          };

          // let rec = this.evalFormula(this.formulas.recommendation_qty_formula, formulaVars);
          // let reduce = this.evalFormula(this.formulas.reduction_qty_formula, formulaVars);

          // let rec2 = this.evalFormula(this.formulas.recommendation_qty_formula, formulaVars2);
          // let reduce2 = this.evalFormula(this.formulas.reduction_qty_formula, formulaVars2);

          let rec = Math.min(((1 - excess) * formulaVars.vehicle_capacity), ((1 - excess) * formulaVars.vehicle_weight / formulaVars.gross_weight) * 1000 )
          let reduce = Math.ceil(Math.min((excess * formulaVars.vehicle_weight / formulaVars.gross_weight) * 1000, (excess * formulaVars.vehicle_capacity / ratio)));

          let rec2 = this.evalFormula(this.formulas.recommendation_qty_formula, formulaVars2);
          let reduce2 = this.evalFormula(this.formulas.reduction_qty_formula, formulaVars2);
          

          if (isDependent && smallGroup) {
            rec = Math.min((formulaVars.small_rec / ratio), ((1 - excess) * formulaVars.vehicle_weight / formulaVars.gross_weight )* 1000 )

            if(largeGroup) {
              reduce = Math.ceil(Math.min((formulaVars.small_reduce / ratio), (excess * formulaVars.vehicle_weight / formulaVars.gross_weight) * 1000) * (1 - 1.28 / 100)) ;
            } else {
              reduce = Math.ceil(Math.min((formulaVars.small_reduce / ratio), (excess * formulaVars.vehicle_weight / formulaVars.gross_weight) * 1000));
            }
            

            rec2 = Math.min((formulaVars.small_rec / ratio), ((1 - excess).toFixed(2) * formulaVars.vehicle_weight / formulaVars.gross_weight )* 1000 )
            reduce2 = this.evalFormula(this.formulas.dependent_reduction_qty_formula, formulaVars2);
          }

          groupResults[product_group.name] = { rec, reduce, rec2, reduce2 };

          if (product_group.name === 'Small') {
            smallRec = rec;
            smallReduce = reduce;
            smallRec2 = rec2;
            smallReduce2 = reduce2;
          }
        });

        this.product_groups.forEach(product_group => {
          const { rec, reduce, rec2, reduce2 } = groupResults[product_group.name];
          const groupKey = product_group.name.toLowerCase().replace(/-/g, '');
          week[`${groupKey}_recomendation`] = rec;
          week[`${groupKey}_reduce`] = reduce;
          week[`${groupKey}_recomendation2`] = rec2;
          week[`${groupKey}_reduce2`] = reduce2;
        });

        console.log('PRODUCT GROUP', groupResults)
      },
      resetWeekValues(week) {
        week.total_unit = 0;
        week.fulfillment_cbm = '0.00';
        week.fulfillment_kg = '0.00';

        this.product_groups.forEach(product_group => {
          const groupKey = product_group.name.toLowerCase().replace(/-/g, '');
          week[`${groupKey}_recomendation`] = 0;
          week[`${groupKey}_reduce`] = 0;
        });
      },

      evalFormula(formula, vars) {
        try {
          const variableKeys = Object.keys(vars);
          const variableValues = Object.values(vars);

          const fn = new Function(...variableKeys, `return ${formula};`);
          const result = fn(...variableValues);
          console.log('RESULT', variableValues)
          console.log('RESULT 2', fn)
          
          return typeof result === "number" && isFinite(result) && !isNaN(result) ? result : 0;
        } catch (error) {
          console.error("Formula evaluation error:", error);
          return 0;
        }
      },
      calculateGrandTotalQty(w1_qty, w2_qty, w3_qty, w4_qty) {
        
        const q1 = w1_qty ? Number(w1_qty) : 0;
        const q2 = w2_qty ? Number(w2_qty) : 0;
        const q3 = w3_qty ? Number(w3_qty) : 0;
        const q4 = w4_qty ? Number(w4_qty) : 0;

        return q1 + q2 + q3 + q4;
      },
      calculateOutstading(grand_total_qty, rolling_forecast_total) {
        return Number(grand_total_qty) - Number(rolling_forecast_total);
      },
      vehicleDetail(vehicleId, type) {
        const vehicle = this.vehicleTypes.find(v => v.vehicle_id.id === vehicleId);
        if (!vehicle) return ''; 

        // Return the requested type or empty string if the property is undefined
        return type === 'dimension' ? (vehicle.vehicle_id.loading_ratio_in_crt || '') : (vehicle.vehicle_id.weight || '');
      },
      poDateSelected() {
        if(this.directTransaction) {
          this.cmo.w1_valid_from = this.cmo.purchase_order_date;
          this.cmo.w2_valid_from = this.cmo.purchase_order_date;
          this.cmo.w3_valid_from = this.cmo.purchase_order_date;
          this.cmo.w4_valid_from = this.cmo.purchase_order_date;
        }
      },
      poExpiredDateSelected() {
        if(this.directTransaction) {
          this.cmo.w1_valid_to = this.cmo.purchase_order_expired_date;
          this.cmo.w2_valid_to = this.cmo.purchase_order_expired_date;
          this.cmo.w3_valid_to = this.cmo.purchase_order_expired_date;
          this.cmo.w4_valid_to = this.cmo.purchase_order_expired_date;
        }
      },
      async updateHeadTabValue(value, index) {
        if(index > 0) {
          this.loading = true;
          this.combiningProcess = true;
          const response = await axios.get(`/confirm_monthly_order/${value.id}`);
          this.cmo = response.data;
          this.periods = [...response.data.period];
          this.cmo.period_id = response.data.period[0];
          this.cmo.transaction_type_id = response.data.transaction_type;
          this.distributors = response.data.ship_to_list;
          this.cmo.ship_to = response.data.distributor;
          this.shipmentTypes = response.data.distributor.cmo_shipment_type;
          this.vehicleTypes = [...response.data.distributor.distributor_vehicles]; // Assign Vehicle list based on distributor selected
          this.cmo.ship_to_address = response.data.distributor.address;
          this.cmo.ship_to_city = response.data.distributor.city;
          this.cmo.ship_to_area = response.data.distributor.area;
          this.cmo.ship_to_region = response.data.distributor.region;
          this.asApprover = response.data.is_approver;
          this.approvedDocument = response.data.status === "Approved" ? true : false;
          this.isOwner = response.data.is_owner;
          this.cmo.transaction_date = new Date(response.data.transaction_date);
          
          // Assign vehicle recomendations data
          this.weeks = response.data.confirm_monthly_order_vehicle_details_attributes;

          this.cmo.sold_to = response.data.sold_to;
          this.cmo.sold_to_address = response.data.sold_to.address;
          this.confirm_monthly_order_details_attributes = [...response.data.confirm_monthly_order_details_attributes];

          this.cmo.purchase_order_date = new Date(response.data.purchase_order_date);
          this.cmo.purchase_order_expired_date = new Date(response.data.purchase_order_expired_date);
          this.cmo.purchase_order_number = response.data.purchase_order_number;
          
          this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes

          // Convert dates to JavaScript Date objects
          this.cmo.w1_request_delivery_date = new Date(response.data.w1_request_delivery_date);
          this.cmo.w2_request_delivery_date = new Date(response.data.w2_request_delivery_date);
          this.cmo.w3_request_delivery_date = new Date(response.data.w3_request_delivery_date);
          this.cmo.w4_request_delivery_date = new Date(response.data.w4_request_delivery_date);

          // Convert valid_from and valid_to to Date objects
          this.cmo.w1_valid_from = new Date(response.data.w1_valid_from);
          this.cmo.w1_valid_to = new Date(response.data.w1_valid_to);

          this.cmo.w2_valid_from = new Date(response.data.w2_valid_from);
          this.cmo.w2_valid_to = new Date(response.data.w2_valid_to);

          this.cmo.w3_valid_from = new Date(response.data.w3_valid_from);
          this.cmo.w3_valid_to = new Date(response.data.w3_valid_to);

          this.cmo.w4_valid_from = new Date(response.data.w4_valid_from);
          this.cmo.w4_valid_to = new Date(response.data.w4_valid_to);

          this.directTransaction = response.data.is_direct_transaction;
          this.distributionTypeGT = response.data.is_distribution_gt;

          // this.cmo.combined_cmo_ids = response.data.combined_cmo_ids;
          this.cmo.is_combined_document = true;

          this.formulas = response.data.formulas;
          this.loading = false;
        } else {
          this.selectedHeadTab = 0;
          this.combiningProcess = false;
          this.populateCombineDocument(this.cmo_ids)
        }
      },
      formatDate(date) {
        if (!date) return "N/A"; // Handle null or undefined dates
        const options = { year: "numeric", month: "long", day: "numeric" }; // e.g., December 16, 2024
        return new Date(date).toLocaleDateString(undefined, options);
      },
      formatNumber(value) {
        if (!value) return "0";
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      periodChanged(item) {
        this.cmo.transaction_date = new Date(item.date);
        this.cmo.w1_active = true;
        this.cmo.w2_active = true;
        this.cmo.w3_active = true;
        this.cmo.w4_active = true;
      },
      transactionDateFilter(date, currentDate_) {
        const currentDate = new Date(date);
        
        // Get today's date without time (set hours, minutes, seconds, and ms to 0)
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Compare currentDate_ with today's date and choose the later one
        const startBlockedDate = new Date(currentDate_) < today ? today : new Date(currentDate_);

        // Calculate the last day of the current month
        const endBlockedDate = new Date(startBlockedDate.getFullYear(), startBlockedDate.getMonth() + 1, 0);

        // Allow only the dates between startBlockedDate and endBlockedDate
        return currentDate >= startBlockedDate && currentDate <= endBlockedDate;
      },
      checkLargestFulfillment(value1 , value2) {
        if (value1 > value2) {
          return true;
        } else {
          return false;
        }
      },
      // calculateTotalUnit(week) {
      //   const maxLimit = this.fulfillment_limit_max * 100;
      //   let fulfillment = 0;
      //   let biggestFulfillment = 0;

      //   if (week.fulfillment_cbm > 1 || week.fulfillment_kg > 1) {
      //     biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg) % 1;
      //     fulfillment = Math.round(biggestFulfillment * 100);
      //   } else {
      //     fulfillment = Math.round(Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100);
      //   }
        
      //   if (fulfillment >= maxLimit && fulfillment <= 100) {
      //     console.log('TotalUnit 1: ' + Math.ceil(week.total_unit))
      //     return Math.ceil(week.total_unit);
      //   } else {
      //     console.log('TotalUnit 2: ' + week.total_unit)
      //     return week.total_unit;
      //   }

      // },
      // calculateAdditionalTotalUnit(week) {
      //   const maxLimit = this.fulfillment_limit_max * 100;
      //   let fulfillment = 0;
      //   let biggestFulfillment = 0;
      //   if (week.fulfillment_cbm > 1 || week.fulfillment_kg > 1) {
      //     biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg) % 1;
      //     // fulfillment = Math.round(biggestFulfillment * 100);
      //     fulfillment = (biggestFulfillment * 100).toFixed(2);
      //   } else {
      //     // fulfillment = Math.round(Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100);
      //     fulfillment = (Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100).toFixed(2);
      //   }
        
      //   if (fulfillment >= maxLimit && fulfillment <= 100) {
      //     // return 0;
      //     console.log('AddTotalUnit 1: ' + Math.ceil(week.additional_total_unit), fulfillment, week.additional_total_unit)
      //     return Math.ceil(week.additional_total_unit);
      //   } else {
      //     console.log('AddTotalUnit 2: ' + week.additional_total_unit)
      //     return week.additional_total_unit;
      //   }

      // },
      // calculateRecomendation(week, recomendation) {
      //   const maxLimit = this.fulfillment_limit_max * 100;
      //   let fulfillment = 0;
      //   let biggestFulfillment = 0;
        
      //   if (week.fulfillment_cbm > 1 || week.fulfillment_kg > 1) {
      //     biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg) % 1;
      //     fulfillment = (biggestFulfillment * 100).toFixed(2);
      //   } else {
      //     fulfillment = (Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100).toFixed(2);
          
      //   }
        
      //   if (fulfillment >= maxLimit && fulfillment <= 100) {
      //     console.log('zero 1', week.fulfillment_cbm, week.fulfillment_kg)
      //     return 0;
      //   } else if(
      //     (week.fulfillment_cbm >= 1 && week.fulfillment_cbm < 1.01) ||  // Prevent show recomendation if fullfillment have 3 decimal places
      //     (week.fulfillment_kg >= 1 && week.fulfillment_kg < 1.01)
      //   ) {
      //     console.log('zero 2', week.fulfillment_cbm, week.fulfillment_kg)
      //     return 0;
      //   } else {
      //     return Math.round(recomendation * this.fulfillment_limit_max);
      //   }

      // },
      // calculateFulfillment(fulfillment_) {
      //   const maxLimit = this.fulfillment_limit_max;
      //   let rounded_fulfillment = 0;
      //   if (fulfillment_ > 1) {
      //     const decimalPart = fulfillment_ - Math.floor(fulfillment_);
      //     if (decimalPart.toFixed(2) > 0 && decimalPart.toFixed(2) < maxLimit) { // Prevent show recomendation if fullfillment decimal part < maxLimit
      //       rounded_fulfillment = Math.round(decimalPart * 100);
      //       // rounded_fulfillment = decimalPart * 100;
      //     } else {
      //       rounded_fulfillment = 100;
      //     }
      //   } else if (Math.round(fulfillment_ * 100) >= Math.round(maxLimit * 100) && Math.round(fulfillment_) <= 1) {
      //     rounded_fulfillment = 100;
      //   } else {
      //     rounded_fulfillment = Math.round(fulfillment_ * 100);
      //   }
      //   return rounded_fulfillment;
      // },
      calculateTotalUnit(week) {
        const maxLimit = Math.round(this.fulfillment_limit_max * 100); // Ensure consistent precision
        let fulfillment = 0;
        let biggestFulfillment = 0;

        if (week.fulfillment_cbm > 1 || week.fulfillment_kg > 1) {
          biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg) % 1;
          fulfillment = Math.round(biggestFulfillment * 100);
        } else {
          fulfillment = Math.round(Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100);
        }
        
        if (fulfillment >= maxLimit && fulfillment <= 100) {
          console.log('TotalUnit 1: ' + Math.ceil(week.total_unit));
          return Math.ceil(week.total_unit);
        } else {
          console.log('TotalUnit 2: ' + week.total_unit);
          return week.total_unit;
        }
      },
      calculateAdditionalTotalUnit(week) {
        const maxLimit = Math.round(this.fulfillment_limit_max * 100);
        let fulfillment = 0;
        let biggestFulfillment = 0;

        if (week.fulfillment_cbm > 1 || week.fulfillment_kg > 1) {
          biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg) % 1;
          fulfillment = parseFloat((biggestFulfillment * 100).toFixed(2)); 
        } else {
          fulfillment = parseFloat((Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100).toFixed(2)); 
        }

        if (fulfillment >= maxLimit && fulfillment <= 100) {
          console.log('AddTotalUnit 1: ' + Math.ceil(week.additional_total_unit), fulfillment, week.additional_total_unit);
          return Math.ceil(week.additional_total_unit);
        } else {
          console.log('AddTotalUnit 2: ' + week.additional_total_unit);
          return week.additional_total_unit;
        }
      },

      calculateRecomendation(week, recomendation) {
        const maxLimit = Math.round(this.fulfillment_limit_max * 100);
        let fulfillment = 0;
        let biggestFulfillment = 0;

        if (week.fulfillment_cbm > 1 || week.fulfillment_kg > 1) {
          biggestFulfillment = Math.max(week.fulfillment_cbm, week.fulfillment_kg) % 1;
          fulfillment = parseFloat((biggestFulfillment * 100).toFixed(2));
        } else {
          fulfillment = parseFloat((Math.max(week.fulfillment_cbm, week.fulfillment_kg) * 100).toFixed(2));
        }

        if (fulfillment >= maxLimit && fulfillment <= 100) {
          console.log('zero 1', week.fulfillment_cbm, week.fulfillment_kg);
          return 0;
        } else if (
          (week.fulfillment_cbm >= 1 && week.fulfillment_cbm < 1.01) ||  
          (week.fulfillment_kg >= 1 && week.fulfillment_kg < 1.01)
        ) {
          console.log('zero 2', week.fulfillment_cbm, week.fulfillment_kg);
          return 0;
        } else {
          return Math.round(recomendation * this.fulfillment_limit_max);
        }
      },

      calculateFulfillment(fulfillment_) {
        const maxLimit = Math.round(this.fulfillment_limit_max * 100);
        let rounded_fulfillment = 0;

        if (fulfillment_ > 1) {
          const decimalPart = fulfillment_ - Math.floor(fulfillment_);
          if (decimalPart > 0 && decimalPart * 100 < maxLimit) {
            rounded_fulfillment = Math.round(decimalPart * 100);
          } else {
            rounded_fulfillment = 100;
          }
        } else if (Math.round(fulfillment_ * 100) >= maxLimit && Math.round(fulfillment_ * 100) <= 100) {
          rounded_fulfillment = 100;
        } else {
          rounded_fulfillment = Math.round(fulfillment_ * 100);
        }

        return rounded_fulfillment;
      },
      isProductInPromotion(productId) {
        return this.cmo.promotions.some(promo =>
          promo.sell_in_product_detail?.some(p => p.id === productId) 
        );
      },
      attachDocument(event) {
        if (event.target.files.length > 0) {
          this.file = event.target.files[0];
          console.log("Selected file:", this.file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
    }
};
</script>

<style scoped>
.v-col {
    padding: 5px;
}

.custom-input {
    border: 1px solid #ddd;
    padding: 6px 6px;
    border-radius: 2px;
    width: 11vw;
    box-sizing: border-box;
}

.custom-input:focus {
    border-color: #1976d2;
    outline: none;
}

/* Apply the same fixed positioning to the Total row's fixed columns */
.fixed-column {
    position: sticky;
    background-color: white;
    z-index: 1;
}

/* Apply specific width for each fixed column to match headers */
.total-label {
    width: 100px;
    /* Adjust this to match the width of the first column */
}

.fixed-column:nth-child(2) {
    left: 100px;
    /* Adjust based on the width of the first fixed column */
}

/* Ensure the sticky columns stay in place when scrolling */
tr>.fixed-column {
    box-shadow: 2px 0px 5px -2px rgba(0, 0, 0, 0.2);
}

:deep(.v-table.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th) {
    padding-bottom: 14px;
    color: black;
}
:deep(.v-table.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th) {
    padding: 16px;
}

:deep(.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep(.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep(.v-table > .v-table__wrapper > table > tbody > tr > td) {
	border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.v-table {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 700px;
}

.custom-table {
  /* width: 100%; */
	border-collapse: collapse;
  text-align: left;
  font-size: 12px;
}

th, td {
  border: 1px solid #ddd;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

.rowspan-header {
  font-weight: bold;
}

.merge-cell {
  text-align: center;
}

.empty-column {
	background-color: lightgray;
}

.invalid-mark {
  background: #FFCDD2 !important;
}

.highlight-promotion {
  background-color: #E8F5E9 !important;
}

.custom-table tr.highlight-promotion td:nth-child(1),
.custom-table tr.highlight-promotion td:nth-child(2),
.custom-table tr.highlight-promotion td:nth-child(3),
.custom-table tr.highlight-promotion td:nth-child(4),
.custom-table tr.highlight-promotion td:nth-child(5),
.custom-table tr.highlight-promotion td:nth-child(6),
.custom-table tr.highlight-promotion td:nth-child(7) {
  background-color: #E8F5E9 !important;
}

tr.division-total, tr.subtotal {
  background-color: lightgray !important;
}

tr.division-total td, tr.subtotal td {
  background-color: lightgray !important;
}

/* Ensure sticky columns inside .division-total keep the background */
tr.division-total td:nth-child(1), tr.subtotal td:nth-child(1),
tr.division-total td:nth-child(2), tr.subtotal td:nth-child(2),
tr.division-total td:nth-child(3), tr.subtotal td:nth-child(3),
tr.division-total td:nth-child(4), tr.subtotal td:nth-child(4),
tr.division-total td:nth-child(5), tr.subtotal td:nth-child(5),
tr.division-total td:nth-child(6), tr.subtotal td:nth-child(6),
tr.division-total td:nth-child(7), tr.subtotal td:nth-child(7),
tr.division-total td:nth-child(8), tr.subtotal td:nth-child(8) {
  background-color: lightgray !important;
}

/* Sticky Column (No - 1st Column) */
.custom-table tr th:nth-child(1),
.custom-table tr td:nth-child(1) {
  position: sticky;
  left: 0; /* Sticks to the left edge */
  background-color: white;
  z-index: 3;
  min-width: 100px;
  padding: 8px;
}

/* Sticky Column (Product Division - 2nd Column) */
.custom-table tr th:nth-child(2),
.custom-table tr td:nth-child(2) {
  position: sticky;
  left: 100px; /* Unchanged */
  background-color: white;
  z-index: 2;
  min-width: 150px;
}

/* Sticky Column (Product Division - 3rd Column) */
.custom-table tr th:nth-child(3),
.custom-table tr td:nth-child(3) {
  position: sticky;
  left: 250px; /* Unchanged */
  background-color: white;
  z-index: 2;
  min-width: 150px;
}

/* Sticky Column (Product Division - 4th Column) */
.custom-table tr th:nth-child(4),
.custom-table tr td:nth-child(4) {
  position: sticky;
  left: 401px; /* Unchanged */
  background-color: white;
  z-index: 2;
  min-width: 150px;
}

/* Sticky Column (Product Division - 6th Column) (Previously 5th) */
.custom-table tr th:nth-child(5),
.custom-table tr td:nth-child(5) {
  position: sticky;
  left: 550px; /* Shifted from 699px */
  background-color: white;
  z-index: 2;
  min-width: 100px;
}

/* Sticky Column (Product Division - 7th Column) (Previously 6th) */
.custom-table tr th:nth-child(6),
.custom-table tr td:nth-child(6) {
  position: sticky;
  left: 649px; /* Shifted from 798px */
  background-color: white;
  z-index: 2;
  min-width: 130px;
}

/* Sticky Column (Product Division - 8th Column) (Previously 7th) */
.custom-table tr th:nth-child(7),
.custom-table tr td:nth-child(7) {
  position: sticky;
  left: 778px; /* Shifted from 927px */
  background-color: white;
  z-index: 2;
  min-width: 130px;
}

/* Adjusting the subtotal row for column 8 */
.subtotal tr td:nth-child(7) {
  position: sticky;
  left: 780px; /* Adjusted from 880px */
  background-color: white;
  z-index: 2; 
  min-width: 130px;
}

</style>