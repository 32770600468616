<template>
  <v-row>
    <!-- Filters with custom labels at the top -->
    <v-col cols="4">
      <div class="custom-label">Period</div>
      <v-select
        v-model="filters.period"
        :items="periodItems"
        item-title="title"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">Region</div>
      <v-select
        v-model="filters.region"
        :items="regionItems"
        item-title="name"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">Area</div>
      <v-select
        v-model="filters.area"
        :items="areaItems"
        item-title="name"
        item-value="id"
        clearable
        class="white-background"
      ></v-select>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">Sold-to</div>
      <v-combobox
        v-model="soldToId"
        variant="outlined"
        density="compact"
        :items="filteredSoldTo"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">Ship-to</div>
      <v-combobox
        v-model="shipToId"
        variant="outlined"
        density="compact"
        :items="filteredShipTo"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <v-col cols="4">
      <div class="custom-label">ASS Name</div>
      <v-combobox
        v-model="assName"
        variant="outlined"
        density="compact"
        :items="filteredAssName"
        item-value="id"
        item-title="name"
        clearable
        :search-input.sync="searchQuery"
      ></v-combobox>
    </v-col>

    <!-- Generate & Export Buttons -->
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="secondary" @click="downloadReport">EXPORT</v-btn>
      <v-btn color="primary" class="ml-2" @click="fetchrofoByDistributor">GENERATE</v-btn>
    </v-col>

    <!-- Scrollable Table Wrapper -->
    <v-row v-if="rofoByDistributor && rofoByDistributor.length"  class="scroll-container justify-end">
      <!-- <v-container> -->
      <v-data-table
        :items="rofoByDistributor"
        :headers="headers"
        class="elevation-1 table-fixed"
      >
        <template v-slot:headers>
          <tr>
            <th colspan="5"></th>
            <template v-for="(month, index) in dynamicMonths" :key="month">
              <th colspan="3" class="text-center">{{ month }} (M+{{ index }})</th>
            </template>
            <th colspan="3" class="text-center">Est. YTD Acv</th>
            <th colspan="3" class="text-center">FORECAST ACCURACY L3M</th>
          </tr>
          <tr>
            <th class="text-center">Region</th>
            <th class="text-center">Area</th>
            <th class="text-center">Sold-To</th>
            <th class="text-center">Ship-To</th>
            <th class="text-center">ASS Name</th>
            <template v-for="month in dynamicMonths" :key="month">
              <th class="text-center">QTY</th>
              <th class="text-center">DEFIASI</th>
              <th class="text-center">% To Target</th>
            </template>
            <template v-for="month in ytdMonths" :key="month">
              <th class="text-center">{{ month }}</th>
            </template>
            <template v-for="month in accuracyMonths" :key="month">
              <th class="text-center">{{ month }}</th>
            </template>
          </tr>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.region }}</td>
            <td>{{ item.area }}</td>
            <td>{{ item.sold_to }}</td>
            <td>{{ item.ship_to }}</td>
            <td>{{ item.ass_name }}</td>
            <template v-for="month in dynamicMonths" :key="month">
              <td>{{ item[`${month.toLowerCase()}_qty`] || '-' }}</td>
              <td :class="{ 'bg-red': (item[`${month.toLowerCase()}_defiation`] || '').includes('-') }">
                {{ item[`${month.toLowerCase()}_defiation`] || '-' }}
              </td>
              <td>{{ item[`${month.toLowerCase()}_percent_to_target`] || '-' }}</td>
            </template>
            <template v-for="month in ytdMonths" :key="month">
              <td>{{ item[`actual_${month.toLowerCase()}`] || '-' }}</td>
            </template>
            <template v-for="month in accuracyMonths" :key="month">
              <td>{{ item[`accuracy_${month.toLowerCase()}`] || '-' }}</td>
            </template>
          </tr>
        </template>
      </v-data-table>
      <!-- </v-container> -->
    </v-row>

    <v-overlay v-if="loading" persistent class="align-center justify-center">
      <v-progress-circular color="primary" size="120" indeterminate />
    </v-overlay>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      headers: [],
      rofoByDistributor: [],
      periodItems: [],
      regionItems: [],
      areaItems: [],
      userItems: [],
      soldToItems: [],
      shipToItems: [],
      dynamicMonths: [],
      ytdMonths: [],
      accuracyMonths: [],
      loading: false,
      filters: { period: null, 
        region: null,
        area: null,
        user: null,
        sold_to: null,
        ship_to: null
      },
      searchQuery: ''
    };
  },
  computed: {
    filteredAssName() {
      if (!this.searchQuery) return this.userItems;
      const query = this.searchQuery.toLowerCase();
      return this.userItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredSoldTo() {
      if (!this.searchQuery) return this.soldToItems;
      const query = this.searchQuery.toLowerCase();
      return this.soldToItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    filteredShipTo() {
      if (!this.searchQuery) return this.shipToItems;
      const query = this.searchQuery.toLowerCase();
      return this.shipToItems.filter(d => 
        d.name.toLowerCase().includes(query)
      );
    },
    assName: {
      get() {
        return this.filters.user ?? null;;
      },
      set(value) {
        this.filters.user = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    shipToId: {
      get() {
        return this.filters.ship_to ?? null;;
      },
      set(value) {
        this.filters.ship_to = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
    soldToId: {
      get() {
        return this.filters.sold_to ?? null;;
      },
      set(value) {
        this.filters.sold_to = value && typeof value === 'object' ? value.id : value ?? null;
      }
    },
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchrofoByDistributor() {
      this.loading = true;
      try {
        const response = await axios.get('/report/rofo_by_distributor', { params: this.filters });
        const report = response.data.report;

        this.buildDynamicHeaders(report.selected_period);
        this.rofoByDistributor = this.transformData(report.data);

      } catch (error) {
        this.showAlert({ message: error.response.data.messages, color: 'error' });
      } finally {
        this.loading = false;
      }
    },

    async fetchFilterData() {
      this.loading = true;
      try {
        const response = await axios.get('/report/filter-options?type=rofo_by_distributor');
        this.periodItems = response.data.periods;
        this.regionItems = response.data.regions;
        this.areaItems = response.data.areas;
        this.userItems = response.data.users;
        this.soldToItems = response.data.ship_to;
        this.shipToItems = response.data.sold_to;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async downloadReport() {
      this.loading = true;
      try {
        const response = await axios.get(`/report/rofo_by_distributor?export=true`, {
          params: this.filters,
          responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'rofo_by_distributor.xlsx';
        link.click();

        // Cleanup
        URL.revokeObjectURL(url);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },

    buildDynamicHeaders(selectedPeriod) {
      const monthOrder = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      const baseIndex = monthOrder.indexOf(selectedPeriod.split(' ')[0].toUpperCase());

      this.dynamicMonths = [
        monthOrder[baseIndex],
        monthOrder[(baseIndex + 1) % 12],
        monthOrder[(baseIndex + 2) % 12]
      ];

      this.ytdMonths = this.dynamicMonths;
      this.accuracyMonths = [
        monthOrder[(baseIndex - 3 + 12) % 12],
        monthOrder[(baseIndex - 2 + 12) % 12],
        monthOrder[(baseIndex - 1 + 12) % 12]
      ];
    },

    transformData(data) {
      return data.map(item => ({
        region: item.region,
        area: item.area,
        sold_to: item.sold_to,
        ship_to: item.ship_to,
        ass_name: item.ass_name,
        product_division: item.product_division,
        product_number: item.product_number,
        product_nickname: item.product_nickname,
        product_description: item.product_description,

        // Forecast Data
        [`${this.dynamicMonths[0].toLowerCase()}_qty`]: item.forecast.m0.qty,
        [`${this.dynamicMonths[0].toLowerCase()}_defiation`]: item.forecast.m0.defiation,
        [`${this.dynamicMonths[0].toLowerCase()}_percent_to_target`]: item.forecast.m0.percent_to_target,

        [`${this.dynamicMonths[1].toLowerCase()}_qty`]: item.forecast.m1.qty,
        [`${this.dynamicMonths[1].toLowerCase()}_defiation`]: item.forecast.m1.defiation,
        [`${this.dynamicMonths[1].toLowerCase()}_percent_to_target`]: item.forecast.m1.percent_to_target,

        [`${this.dynamicMonths[2].toLowerCase()}_qty`]: item.forecast.m2.qty,
        [`${this.dynamicMonths[2].toLowerCase()}_defiation`]: item.forecast.m2.defiation,
        [`${this.dynamicMonths[2].toLowerCase()}_percent_to_target`]: item.forecast.m2.percent_to_target,

        // Actual Data
        [`actual_${this.ytdMonths[0].toLowerCase()}`]: item.actual.m0,
        [`actual_${this.ytdMonths[1].toLowerCase()}`]: item.actual.m1,
        [`actual_${this.ytdMonths[2].toLowerCase()}`]: item.actual.m2,

        // Accuracy Data
        [`accuracy_${this.accuracyMonths[0].toLowerCase()}`]: item.accuracy.l3m,
        [`accuracy_${this.accuracyMonths[1].toLowerCase()}`]: item.accuracy.l2m,
        [`accuracy_${this.accuracyMonths[2].toLowerCase()}`]: item.accuracy.lm
      }));
    }
  },
  mounted() {
    this.fetchFilterData();
  }
};
</script>

<style scoped>
.scroll-container { 
  max-width: 100% !important;
  margin: 10px !important;
  overflow-x: auto !important;
}

.bg-red {
  background-color: #f28b82 !important;
}

.text-center {
  text-align: center !important;
}
</style>