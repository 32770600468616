<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Promotion Number</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="promotionManager.promotion_number" bgColor="none" placeholder="Automatic" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Description</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-textarea v-model="promotionManager.description" required bgColor="none"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                  <label class="custom-label">Promotion Period</label>
              </v-col>
              <v-col cols="6" md="3">
                  <v-date-input v-model="promotionManager.start_date" placeholder="Start Date" variant="outlined" density="compact" hide-details
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
              </v-col>
              <v-col cols="6" md="3">
                  <v-date-input v-model="promotionManager.end_date" placeholder="End Date" variant="outlined" density="compact" hide-details
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
              </v-col>
          </v-row>
          <v-row class="pb-4">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="promotionManager.status" :items="statusOption" item-value="id" item-title="name"
                placeholder="" required></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pt-4">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Region</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="region" return-object :items="regions" item-value="item" item-title="name"
                placeholder="" clearable></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Area</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select v-model="area" :items="areas" item-value="item" item-title="name" return-object
                placeholder="" clearable></v-select>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distributor</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select v-model="distributor" :items="distributors" item-value="id" item-title="name"
                placeholder="Select Location" required></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distributor</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-combobox
                v-model="distributor"
                variant="outlined"
                density="compact"
                :items="filteredDistributors"
                item-value="id"
                item-title="name"
                placeholder="Search or select distributor"
                clearable
                hide-no-data
                :search-input.sync="searchQuery"
              >
                <template v-slot:prepend-inner>
                  <v-icon icon="mdi-magnify" />
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Ship To</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-btn
                variant="outlined"
                append-icon="mdi-menu-down"
                width="100%"
                height="37"
                class="d-flex justify-space-between align-center"
              >
                Select Data
                <v-dialog activator="parent" max-width="640">
                  <template v-slot:default="{ isActive }">
                    <v-card
                      title="Select Ship to"
                      class="pa-4"
                    >
                      <v-row class="mb-2">
                        <v-col offset="6">
                          <v-text-field v-model="search" placeholder="Search" density="compact" append-inner-icon="mdi-magnify"></v-text-field>
                        </v-col> 
                      </v-row>
                      <v-data-table
                        :headers="shiptoHeaders"
                        :items="shipToData"
                        item-value="id"
                        show-select
                        class="elevation-1 custom-border-table"
                        :search="search"
                        v-model="selectedShipTo"
                        hide-default-footer
                        items-per-page="-1"
                      >
                        <!-- No column using index -->
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>

                        <template v-slot:footer.prepend>
                          Showing {{ items.length }} of 30 Data
                        </template>
                      </v-data-table>

                      <template v-slot:actions>
                        <v-btn
                          variant="flat"
                          color="primary"
                          text="Select"
                          @click="isActive.value = false"
                        ></v-btn>
                      </template>
                    </v-card>
                  </template>
                </v-dialog>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Promotion Scheme</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-btn
                variant="outlined"
                append-icon="mdi-list-box-outline"
                width="100%"
                height="37"
                class="d-flex justify-space-between align-center"
              >
                  Create Scheme
                <v-dialog activator="parent" max-width="80%">
                  <template v-slot:default="{ isActive }">
                    <v-card
                      prepend-icon="mdi-picture-in-picture-bottom-right"
                        :title="`Promotion Scheme`"
                    >
                    <v-row class="my-4">
                      <v-col cols="12">
                        <v-data-table :items="schemeData" :headers="schemeHeaders" hide-default-footer >
                          <!-- Sell In Product -->
                          <template v-slot:item.sell_in_by="{ item }">
                            <v-select v-model="item.sell_in_by" :items="selectByOption" class="py-3"
                              placeholder="" @update:modelValue="sellInBySelected(item.sell_in_by)"></v-select>
                          </template>
                          <template v-slot:item.sell_in_product="{ item }">
                            <v-btn
                              variant="outlined"
                              append-icon="mdi-menu-down"
                              width="100%"
                              height="37"
                              class="d-flex justify-space-between align-center"
                            >
                              Multi Product
                              <v-dialog activator="parent" max-width="640">
                                <template v-slot:default="{ isActive }">
                                  <v-card
                                    title="Select Product"
                                  >
                                    <v-data-table
                                      :headers="sellInByHeaders"
                                      :items="sellInByItems"
                                      item-value="id"
                                      show-select
                                      class="elevation-1"
                                      v-model="item.sell_in_product"
                                      hide-default-footer
                                      :items-per-page="-1"
                                    >
                                      <!-- No column using index -->
                                      <template v-slot:item.no="{ index }">
                                        {{ index + 1 }}
                                      </template>

                                      <template v-slot:footer.prepend>
                                        Showing {{ items.length }} of 30 Data
                                      </template>
                                    </v-data-table>

                                    <template v-slot:actions>
                                      <v-btn
                                        variant="flat"
                                        color="primary"
                                        text="Select"
                                        @click="isActive.value = false"
                                      ></v-btn>
                                    </template>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </v-btn>
                          </template>
                          <!-- Free Product -->
                          <template v-slot:item.free_product_by="{ item }">
                            <v-select v-model="item.free_product_by" :items="selectByOption" class="py-3"
                              placeholder="" @update:modelValue="freeProductBySelected(item.free_product_by)"></v-select>
                          </template>
                          <template v-slot:item.free_product="{ item }">
                            <v-btn
                              variant="outlined"
                              append-icon="mdi-menu-down"
                              width="100%"
                              height="37"
                              class="d-flex justify-space-between align-center"
                            >
                              Multi Product
                              <v-dialog activator="parent" max-width="640">
                                <template v-slot:default="{ isActive }">
                                  <v-card
                                    title="Select Product"
                                  >
                                    <v-data-table
                                      :headers="freeProductByHeaders"
                                      :items="freeProductByItems"
                                      item-value="id"
                                      show-select
                                      class="elevation-1"
                                      v-model="item.free_product"
                                      hide-default-footer
                                      :items-per-page="-1"
                                    >
                                      <!-- No column using index -->
                                      <template v-slot:item.no="{ index }">
                                        {{ index + 1 }}
                                      </template>

                                      <template v-slot:footer.prepend>
                                        Showing {{ items.length }} of 30 Data
                                      </template>
                                    </v-data-table>

                                    <template v-slot:actions>
                                      <v-btn
                                        variant="flat"
                                        color="primary"
                                        text="Select"
                                        @click="isActive.value = false"
                                      ></v-btn>
                                    </template>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </v-btn>
                          </template>
                          <template v-slot:item.target_limit="{ item }">
                            <vue-number v-model="item.target_limit" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                          </template>
                          <template v-slot:item.max_qty="{ item }">
                            <vue-number v-model="item.max_qty" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                          </template>
                          <template v-slot:item.free_qty="{ item }">
                            <vue-number v-model="item.free_qty" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                          </template>
                          <template v-slot:item.allocation_limit="{ item }">
                            <vue-number v-model="item.allocation_limit" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                          </template>
                          <template v-slot:item.auto_implement="{ item }">
                            <v-switch
                              v-model="item.auto_implement"
                              color="primary"
                              hide-details
                              inset
                            ></v-switch>
                          </template>

                          

                          <template v-slot:item.actions="{ item }"> <!-- Use item.actions here -->
                            <v-btn density="compact" variant="text" color="error"
                              @click="confirmDelete(item, 2)">{{ $t('actions.delete') }}</v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col>
                        <v-btn class="ml-4" variant="outlined" color="primary" @click="addNewScheme">Add Another</v-btn>
                      </v-col>
                    </v-row>

                      <template v-slot:actions>
                        <v-btn
                          class="ml-auto"
                          text="Save"
                          @click="isActive.value = false"
                        ></v-btn>
                        
                      </template>
                    </v-card>
                  </template>
                </v-dialog>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
              <v-col offset="2" cols="4" class="d-flex align-center">
                  <v-btn @click="saveSchemeData" color="primary" class="align-self-center">Add</v-btn>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="12">
                  <div>Scheme List</div>
              </v-col>
              <v-col cols="12">
                  <v-data-table :items="schemeList" :headers="schemeListHeaders" hide-default-footer class="custom-border-table">
                      <template v-slot:item.ship_to="{ item }">
                        <v-btn @click="showingShipTo(item.ship_to_detail)" variant="text" color="primary" class="align-self-center">Multiple Ship to</v-btn>
                      </template>
                      <template v-slot:item.scheme_details_attributes="{ item }">
                        <v-btn @click="showingSchemeDetail(item.scheme_details_attributes)" variant="text" color="primary" class="align-self-center">Multiple Scheme</v-btn>
                      </template>
                      <template v-slot:item.actions="{ item }"> 
                        <v-btn density="compact" variant="text" color="error"
                            @click="confirmDelete(item, 1)">{{ $t('actions.delete') }}</v-btn>
                      </template>
                  </v-data-table>
              </v-col>
          </v-row>
         

        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>

   <!-- Confirmation Dialog -->
  <v-dialog v-model="showConfirmation" max-width="400" persistent>
      <v-card>
          <v-card-title>
              Confirm Deletion
          </v-card-title>
          <v-card-subtitle>
              Are you sure you want to delete this item?
          </v-card-subtitle>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="showConfirmation = false">
                  Cancel
              </v-btn>
              <v-btn @click="deleteItem">
                  Confirm
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

  <!-- Dialog to Show Ship To  -->
  <v-dialog v-model="showShipToDialog" max-width="800" persistent>
      <v-card>
          <v-card-title>
              Ship To List
          </v-card-title>
          <v-card-subtitle>
            <v-data-table
              :headers="shiptoHeaders"
              :items="displayShipto"
              class="elevation-1"
              hide-default-footer
              items-per-page="-1"
            >
              <!-- No column using index -->
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:footer.prepend>
                Showing {{ items.length }} of 30 Data
              </template>
            </v-data-table>
          </v-card-subtitle>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="showShipToDialog = false">
                  Close
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

  <!-- Dialog to Show Scheme  -->
  <v-dialog v-model="showSchemeToDialog" persistent>
      <v-card>
        <v-card-title>
            Promotion Scheme List
        </v-card-title>
        <v-card-subtitle>
          <v-data-table :items="displayScheme" :headers="displaySchemeHeaders" hide-default-footer >
            <!-- Sell In Product -->
            <template v-slot:item.sell_in_by="{ item }">
              {{ item.sell_in_by }}
            </template>
            <template v-slot:item.sell_in_product_detail="{ item }">
              <div v-if="item.sell_in_product_detail?.length" class="sell-in-product-detail">
                <v-chip
                  v-for="(detail, index) in item.sell_in_product_detail"
                  :key="index"
                  class="ma-1"
                  color="black"
                  variant="outlined"
                >
                  {{ detail.product_name + ', ' + detail.description }}
                </v-chip>
              </div>
              <span v-else class="text-black">No Products</span>
            </template>
            <!-- Free Product -->
            <template v-slot:item.free_product_by="{ item }">
              {{ item.free_product_by }}
            </template>
            <template v-slot:item.free_product_detail="{ item }">
              <div v-if="item.free_product_detail?.length" class="sell-in-product-detail">
                <v-chip
                  v-for="(detail, index) in item.free_product_detail"
                  :key="index"
                  class="ma-1"
                  color="black"
                  variant="outlined"
                >
                  {{ detail.product_name + ', ' + detail.description }}
                </v-chip>
              </div>
              <span v-else class="text-black">No Products</span>
            </template>
            <template v-slot:item.target_limit="{ item }">
              {{ item.target_limit }}
            </template>
            <template v-slot:item.max_qty="{ item }">
              {{ item.max_qty }}
            </template>
            <template v-slot:item.free_qty="{ item }">
              {{ item.free_qty }}
            </template>
            <template v-slot:item.allocation_limit="{ item }">
              {{ item.allocation_limit }}
            </template>
            <template v-slot:item.auto_implement="{ item }">
              <v-switch
                v-model="item.auto_implement"
                color="primary"
                hide-details
                inset
              ></v-switch>
            </template>
          </v-data-table>
        </v-card-subtitle>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showSchemeToDialog = false">
                Close
            </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      promotionManager: {
        id: null,
        status: 'Active'
      },
      statusOption: ['Active', 'Inactive'],
      coverages: [],
      roles: [],
      distributors: [],
      shipToData: [],
      distributor: null,
      searchQuery: '',
      area: null,
      region: null,
      products: [],
      categories: [],
      divisions: [],
      selectedShipTo: [],
      selectedProducts: [],
      selectedFreeProducts: [],
      schemeList: [],
      schemeData: [],
      regions: [],
      areas: [],
      selectedRoleId: null,
      selectedCoverageId: null,
      selectedCoverageTypeId: null,
      showConfirmation: false,
      itemToDelete : null,
      itemNeedToDelete: null,
      selectByOption: ['By Product', 'By Category 1', 'By Category 2', 'By Division' ],
      shiptoHeaders: [
        { title: 'No', value: 'no' },
        { title: 'Ship To Code', value: 'code' },
        { title: 'Ship To Name', value: 'name' },
      ],
      productHeaders: [
        { title: 'No', value: 'no' },
        { title: 'SKU', value: 'sku' },
        { title: 'Product Nick Name', value: 'product_name' },
        { title: 'Product Name', value: 'description' },
      ],
      schemeHeaders: [
        { title: 'Sell In Product', key: 'sell_in_by', align: 'center', width:400 },
        { title: 'Sell In Product', key: 'sell_in_product', align: 'center', width:400 },
        { title: 'Free Product', key: 'free_product_by', align: 'center', width:400 },
        { title: 'Free Product', key: 'free_product', align: 'center', width:400 },
        { title: 'Target Limit', key: 'target_limit', align: 'center', width:400 },
        { title: 'Max Qty', key: 'max_qty', align: 'center', width:400 },
        { title: 'Free Qty', key: 'free_qty', align: 'center', width:400 },
        { title: 'Allocation Limit', key: 'allocation_limit', align: 'center', width:400 },
        { title: 'Auto Implement', key: 'auto_implement', align: 'center' },
        { title: 'Action', value: 'actions', align: 'center' },
      ],
      displaySchemeHeaders: [
        { title: 'Sell In Product', key: 'sell_in_by', align: 'center' },
        { title: 'Sell In Product', key: 'sell_in_product_detail', align: 'center', width:400 },
        { title: 'Free Product', key: 'free_product_by', align: 'center' },
        { title: 'Free Product', key: 'free_product_detail', align: 'center', width:400 },
        { title: 'Target Limit', key: 'target_limit', align: 'center' },
        { title: 'Max Qty', key: 'max_qty', align: 'center' },
        { title: 'Free Qty', key: 'free_qty', align: 'center' },
        { title: 'Allocation Limit', key: 'allocation_limit', align: 'center' },
        { title: 'Auto Implement', key: 'auto_implement', align: 'center' },
      ],
      schemeListHeaders :[
          // { title: 'Region', value: 'region', align: 'center' },
          // { title: 'Area', value: 'coverage_detail.name', align: 'center' },
          // { title: 'Distributor', value: 'ship_to_detail.name', align: 'center' },
          { title: 'Ship To', key: 'ship_to', align: 'center' },
          { title: 'Scheme', key: 'scheme_details_attributes', align: 'center' },
          { title: 'Action', value: 'actions', align: 'center' },
      ],
      categoryHeaders: [
        { title: 'Name', key: 'name', align: 'start', clickable: true , sortable: true},
        { title: 'Code', key: 'code', align: 'start', clickable: true, sortable: true },
        { title: 'Parent', value: 'parent.name', align: 'start', clickable: true, sortable: true },
      ],
      divisionHeaders: [
        { title: 'Name', key: 'name', align: 'start', clickable: true, sortable: true },
        { title: 'Code', key: 'code', align: 'start', clickable: true, sortable: true },
      ],
      search: '',
      showShipToDialog: false,
      showSchemeToDialog: false,
      displayShipto: [],
      displayScheme: [],
      sellInByItems: [],
      freeProductByItems: [],
      sellInByHeaders: [],
      freeProductByHeaders: [] 
    };
  },
  watch: {
    coverages(newValue, oldValue) {
      this.promotionManager.coverages = []
    },
    region() {
      this.fetchCoverage();
      this.fetchDistributors();
      this.fetchShipTo();
    },
    area() {
      this.fetchDistributors();
      this.fetchShipTo();
    },
    distributor() {
      this.fetchDistributors();
      this.fetchShipTo();
    }
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
    filteredDistributors() {
      if (!this.searchQuery) return this.distributors;
      const query = this.searchQuery.toLowerCase();
      return this.distributors.filter(d => 
        d.name.toLowerCase().includes(query) ||
        (d.email && d.email.toLowerCase().includes(query)) ||
        (d.code && d.code.includes(query))
      );
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchCoverage();
    this.fetchDistributors();
    this.fetchShipTo();
    this.fetchProducts();
    this.fetchProductCategories();
    this.fetchProductDivisions();
    
    // Fetch promotionManager details if editing
    if (id) {
      try {
        const response = await axios.get(`/promotion/${id}`);
        this.promotionManager = response.data;
        this.promotionManager.start_date = new Date(response.data.start_date);
        this.promotionManager.end_date = new Date(response.data.end_date);
        this.schemeList = [...response.data.scheme_headers_attributes];
        // this.selectedCoverageId = this.promotionManager.coverage_id
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const promotionData = {
          // promotion_number: this.promotionManager.promotion_number, 
          description: this.promotionManager.description, 
          start_date: new Date(this.promotionManager.start_date).toLocaleDateString('en-CA'),
          end_date: new Date(this.promotionManager.end_date).toLocaleDateString('en-CA'),
          status: this.promotionManager.status, 
          scheme_headers_attributes: [
              ...this.schemeList
          ]
        };

        console.log(promotionData)
        console.log(this.area)

         if (this.isAdding) {
           const response = await axios.post('/promotion', { promotion: promotionData });
           this.showAlert({ message: 'promotionManager data successfully saved', color: 'success' });
           if (response.data) {
             this.$router.push({ name: 'promotion' });
           } else {
             throw new Error(response.message);
           }
         } else {
           const response = await axios.put(`/promotion/${this.promotionManager.id}`, { promotion: promotionData });
           this.showAlert({ message: 'promotionManager data successfully update', color: 'success' });
           if (response.data) {
             this.$router.push({ name: 'promotion' });
           } else {
             throw new Error('Failed to update promotionManager');
           }
         }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchCoverage() {
      try {
        const [regionResponse, areaResponse] = await Promise.all([
          axios.get('/coverage?type=region'),
          axios.get('/coverage?type=area')
        ]);

        this.regions = regionResponse.data;
        this.areas = this.region 
          ? areaResponse.data.filter(area => area.parent_id === this.region.id) 
          : areaResponse.data;

      } catch (error) {
        const errorMessage = error.response?.data?.messages 
          ? error.response.data.messages 
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchDistributors() {
      this.loading = true;
      try {
        const response = await axios.get('/distributor?is_parent=true');
        let filteredData = response.data;

        // Apply filters in sequence
        if (this.region) {
          filteredData = filteredData.filter(cvr => cvr.coverage.parent_id === this.region.id);
        }
        if (this.area) {
          filteredData = filteredData.filter(cvr => cvr.coverage.id === this.area.id);
        }

        this.distributors = filteredData;
      } catch (error) {
        const errorMessage = error.response?.data?.messages 
          ? error.response.data.messages 
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async fetchShipTo() {
      this.loading = true;
      try {
        const response = await axios.get('/distributor?is_parent=false');
        let filteredData = response.data;

        // Apply filters in sequence
        if (this.region) {
          filteredData = filteredData.filter(cvr => cvr.region === this.region.name);
        }
        if (this.area) {
          filteredData = filteredData.filter(cvr => cvr.coverage.parent_id === this.area.id);
        }
        if (this.distributor) {
          filteredData = filteredData.filter(dist => dist.parent_id === this.distributor.id);
        }

        this.shipToData = filteredData;
        this.selectedShipTo = filteredData.map(item => item.id);
        console.log('SHIPTO',this.selectedShipTo)
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      this.loadPfetchProducts = true;
      try {
        const response = await axios.get('/product');
        this.products = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProductCategories() {
      this.loadPfetchProducts = true;
      try {
        const response = await axios.get('/product_category');
        this.categories = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProductDivisions() {
      this.loadPfetchProducts = true;
      try {
        const response = await axios.get('/product_division');
        this.divisions = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    addNewScheme() {
      let newScheme = {
        sell_in_by: null,
        sell_in_product: null,
        free_product_by: null,
        free_product: null,
        target_limit: null,
        max_qty: null,
        free_qty: null,
        allocation_limit: null,
        auto_implement: false,
      }

      this.schemeData.push(newScheme);
    },
    confirmDelete(item, type) {
      this.itemToDelete = item;
      this.itemNeedToDelete = type;
      this.showConfirmation = true;
    },
    deleteItem() {
      if(this.itemNeedToDelete === 1) {
        const index = this.schemeList.indexOf(this.itemToDelete);
        if (index !== -1) {
          this.schemeList.splice(index, 1); 
        }
      } else {
        const index = this.schemeData.indexOf(this.itemToDelete);
        if (index !== -1) {
          this.schemeData.splice(index, 1); 
        }
      }
      
     
      this.showConfirmation = false;
      this.itemToDelete = null;
    },
    saveSchemeData(isActive) {
        const shipToDetails = this.shipToData.filter(shipTo => 
            this.selectedShipTo.some(selected => selected === shipTo.id)
        );

        const coverage = this.region || this.area || this.distributor?.coverage || null;
        const coverage_id = this.region?.id || this.area?.id || this.distributor?.coverage?.id || shipToDetails[0]?.coverage?.id || null;

        this.schemeList.push({
            coverage,
            coverage_id,
            distributor: this.distributor,
            ship_to: this.selectedShipTo,
            ship_to_detail: shipToDetails, // Matching shipTo data
            scheme_details_attributes: this.schemeData
        });

        console.log('Scheme', this.schemeList);
        // Reset schemeData for the next input
        this.schemeData = [];
      },
    showingShipTo(ship_to) {
      this.displayShipto = ship_to;
      this.showShipToDialog = true;
    },
    showingSchemeDetail(scheme) {
      // Ensure scheme is an array
      if (!Array.isArray(scheme)) {
          console.error('Error: scheme is not an array!', scheme);
          return;
      }
      console.log('Scheme:', scheme)
      // Iterate over each `sch` object inside `scheme`
      scheme.forEach(sch => {
          const sellInProductIds = sch.sell_in_product || [];
          const freeProductIds = sch.free_product || [];

          // Ensure this.products is an array
          if (!Array.isArray(this.products)) {
              console.error('Error: this.products is not an array!');
              return;
          }

          // Find matching products
          if (sch.sell_in_by == 'By Product') {
            sch.sell_in_product_detail = this.products.filter(product => sellInProductIds.includes(product.id));
            this.sellInByHeaders = this.productHeaders;
          } else if (sch.sell_in_by == 'By Category 1' || sch.sell_in_by == 'By Category 2') {
            sch.sell_in_product_detail = this.categories.filter(cat => sellInProductIds.includes(cat.id));
            this.sellInByHeaders = this.categoryHeaders;
          } else {
            sch.sell_in_product_detail = this.divisions.filter(div => sellInProductIds.includes(div.id));
            this.sellInByHeaders = this.divisionHeaders;
          }

          if (sch.free_product_by == 'By Product') {
            sch.free_product_detail = this.products.filter(product => freeProductIds.includes(product.id));
            this.freeProductByHeaders = this.productHeaders;
          } else if (sch.free_product_by == 'By Category 1' || sch.free_product_by == 'By Category 2') {
            sch.free_product_detail = this.categories.filter(cat => freeProductIds.includes(cat.id));
            this.freeProductByHeaders = this.categoryHeaders;
          } else {
            sch.free_product_detail = this.divisions.filter(div => freeProductIds.includes(div.id));
            this.freeProductByHeaders = this.divisionHeaders;
          }
          
          console.log('SCH',sch)
      });

      // Assign modified array to displayScheme
      this.displayScheme = scheme;

      this.showSchemeToDialog = true;
    },
    sellInBySelected(item) {
      switch (item) {
        case 'By Product':
          this.sellInByItems = this.products;
          this.sellInByHeaders = this.productHeaders;
          break;
        case 'By Category 1':
          this.sellInByItems = this.categories;
          this.sellInByHeaders = this.categoryHeaders;
          break;
        case 'By Category 2':
          this.sellInByItems = this.categories;
          this.sellInByHeaders = this.categoryHeaders;
          break;
        case 'By Division':
          this.sellInByItems = this.divisions;
          this.sellInByHeaders = this.divisionHeaders;
          break;
        default:
          this.sellInByItems = [];
          this.sellInByHeaders = [];
          break;
      }
    },
    freeProductBySelected(item) {
      switch (item) {
        case 'By Product':
          this.freeProductByItems = this.products;
          this.freeProductByHeaders = this.productHeaders;
          break;
        case 'By Category 1':
          this.freeProductByItems = this.categories;
          this.freeProductByHeaders = this.categoryHeaders;
          break;
        case 'By Category 2':
          this.freeProductByItems = this.categories;
          this.freeProductByHeaders = this.categoryHeaders;
          break;
        case 'By Division':
          this.freeProductByItems = this.divisions;
          this.freeProductByHeaders = this.divisionHeaders;
          break;
        default:
          this.freeProductByItems = [];
          this.freeProductByHeaders = [];
          break;
      }
    }
  }
};
</script>

<style scoped>
  .custom-input {
    border: 1px solid #ddd;
    padding: 6px 6px;
    border-radius: 2px;
    width: 5vw;
    box-sizing: border-box;
    text-align: right;
  }

  /* Custom border styling */
  .custom-border-table {
    --v-border-color: #ddd;
  }

  /* Optional: Add inner borders */
  .custom-border-table ::v-deep table {
    border-collapse: collapse;
  }

  .custom-border-table ::v-deep th,
  .custom-border-table ::v-deep td {
    border: 1px solid #ddd;
  }

  .sell-in-product-detail {
    max-width: 400px; /* Adjust as needed */
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    display: flex;
    flex-wrap: wrap; /* Ensures items wrap to the next line */
  }
</style>