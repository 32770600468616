<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card elevation="0">
        <template v-slot:append>
            <v-btn class="text-none" color="primary" @click="submitForm">
                {{ isEditMode ? 'Update' : 'Save' }}
            </v-btn>
        </template>

        <v-divider></v-divider>
        <v-card-text>
            <v-form @submit.prevent="submitForm">
                <v-row>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <label class="custom-label">Module</label>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select :readonly="isEditMode" v-model="moduleType" :items="moduleTypes"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <label class="custom-label">Transaction Type</label>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select :readonly="isEditMode" v-model="transactionType" :items="transactionTypes" item-value="id" item-title="name"
                            placeholder="Select Transaction Type" return-object></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <label class="custom-label">Active on</label>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-date-input :disabled="isEditMode" v-model="startDate" placeholder="Start Date" variant="outlined" density="compact"
                            :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-date-input :disabled="isEditMode" v-model="endDate" placeholder="End Date" variant="outlined" density="compact"
                            :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <label class="custom-label">Status</label>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select v-model="status" :items="approvalStatus"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <label class="custom-label">Step</label>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select :readonly="isEditMode" v-model="coverageType" :items="coverageTypes" item-value="id" item-title="name"
                            placeholder="Select Coverage Type"></v-select>
                    </v-col>
                </v-row>
                <v-divider class="my-6"></v-divider>
                <v-row>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <label class="custom-label">Approver Type</label>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select v-model="approverType" :items="approverTypes"
                            placeholder="Select Approver Type"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <label class="custom-label">Approval Level & Role</label>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-select v-model="approvalLevel" :items="approvalLevels"
                            placeholder="Approval level"></v-select>
                    </v-col>
                    <v-col cols="6" md="3" v-if="approverType == 'By Role'">
                        <v-select v-model="roleSelected" :items="roles" item-value="id" item-title="name"
                            placeholder="Select User / Role"></v-select>
                    </v-col>
                    <v-col cols="6" md="3" v-else>
                        <v-select v-model="userSelected" :items="users" item-value="id" item-title="username"
                            placeholder="Select User / Role"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" offset-md="3" class="d-flex align-center">
                        <v-btn @click="addApproval" color="primary" class="align-self-center">Add</v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="formattedApprovers" item-value="transactionType"
                            hide-default-footer>
                            <template v-slot:item.discretion_active="{ item }">
                                <v-switch
                                    v-if="coverageType === 'Domestic'"
                                    v-model="item.discretion_active"
                                    color="primary"
                                    hide-details
                                    inset
                                    @change="updateDiscretionStatus(item)"
                                ></v-switch>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn variant="text" color="error" @click="confirmDelete(item)">
                                    Delete
                                </v-btn>
                            </template>
                        </v-data-table>

                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
    <!-- Confirmation Dialog -->
    <v-dialog v-model="showConfirmation" max-width="400" persistent>
        <v-card>
            <v-card-title>
                Confirm Deletion
            </v-card-title>
            <v-card-subtitle>
                Are you sure you want to delete this item?
            </v-card-subtitle>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showConfirmation = false">
                    Cancel
                </v-btn>
                <v-btn @click="removeApproval(approvalToDelete)">
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
    components: {
        Breadcrumbs,
        VDateInput
    },
    async mounted() {
        const { action, id } = this.$route.params;
        if (id) {
            try {
                const response = await axios.get(`/approval/${id}`);
                const approval = response.data;

                this.moduleType = approval.module;
                this.transactionType = approval.transaction_type_id;
                this.status = approval.status;
                this.coverageType = approval.coverage_type;
                // Convert the date strings to Date objects
                this.startDate = new Date(approval.start_date);
                this.endDate = new Date(approval.end_date);
                this.approverType = approval.approver_type;

                await this.initializeApprover(approval.approvers);

            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        }
    },
    computed: {
        breadcrumbItems() {
            const action = this.$route.params.action;
            const route = this.$route.name;
            const items = breadcrumbConfig[route];

            if (typeof items === 'function') {
                return items(action);
            }

            return items || [];
        },
        isEditMode() {
            return !!this.$route.params.id;
        },
        formattedApprovers() {
            if (Array.isArray(this.approvers) && this.approvers.length > 0) {
                return this.approvers.map(approver => {
                    const approverName = this.approverType === 'By Role'
                        ? this.roles.find(role => role.id === approver.approver)?.name
                        : this.users.find(user => user.id === approver.approver)?.username;

                    return {
                        ...approver,
                        approverName: approverName || 'Unknown'
                    };
                });
            }
            return []; // Return empty array if no data
        }

    },
    data() {
        return {
            moduleType: 'Rolling Forecast',
            transactionType: null,
            coverageType: null,
            approverType: null,
            approvalLevel: null,
            roleSelected: null,
            userSelected: null,
            startDate: null,
            endDate: null,
            status: 'Active',
            transactionTypes: [],
            moduleTypes: ['Rolling Forecast', 'Confirm Monthly Order', 'Memo Discount'],
            approvalStatus: ['Active', 'Inactive'],
            approverTypes: ['By Role', 'By User'],
            coverageTypes: ['District', 'Area', 'Region', 'Domestic'],
            approvalLevels: Array.from({ length: 10 }, (_, i) => i + 1),
            roles: [],
            users: [],
            approvers: [],
            headers: [
                { title: 'Transaction type', value: 'transaction_type.name' },
                { title: 'Module/Type', key: 'module' },
                { title: 'Approval Level', key: 'level' },
                { title: 'Approver Name', key: 'approverName' },
                { title: 'Discreation', key: 'discretion_active' },
                { title: '', key: 'actions', sortable: false },
            ],
            alert: {
                show: false,
                message: '',
                color: 'error',
            },
            showConfirmation: false,
            approvalToDelete: null,
            discretionActive: false
        };
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchTransactionTypes() {
            try {
                const response = await axios.get('/transaction_type');
                this.transactionTypes = response.data;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async fetchRoles() {
            try {
                const response = await axios.get('/role');
                this.roles = response.data;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async fetchUser() {
            try {
                const response = await axios.get('/users?is_admin_only=false');
                this.users = response.data;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        addApproval() {
            const approver = this.approverType === 'By Role' ? this.roleSelected : this.userSelected;

            // Check if the level already exists in the approvers array
            const levelExists = this.approvers.some(a => a.level === this.approvalLevel);

            if (approver && !levelExists) {
                this.approvers.push({
                    level: this.approvalLevel,
                    approver: approver,
                    module: this.moduleType,
                    transaction_type: this.transactionType,
                    discretion_active: false,
                });

                this.approvalLevel = null;
                this.userSelected = null;
                this.roleSelected = null;
            } else if (levelExists) {
                this.showAlert({ message: 'Approval level already exist', color: 'error' });
            }
        },
        confirmDelete(appr) {
            this.showConfirmation = true;
            this.approvalToDelete = appr;
        },
        removeApproval(approvalToRemove) {
            const approverIndex = this.approvers.findIndex(approver =>
                approver.level === approvalToRemove.level && approver.approver === approvalToRemove.approver
            );

            if (approverIndex !== -1) {
                this.approvers.splice(approverIndex, 1);
            } else {
                console.warn('Approval to remove not found:', approvalToRemove);
            }
            this.showConfirmation = false;
        },
        async submitForm() {
            try {
                if (this.coverageType !== 'Domestic') {
                    this.approvers = this.approvers.map(approver => ({
                        ...approver,
                        discretion_active: null
                    }));
                }
                
                const payload = {
                    approval: {
                        module: this.moduleType,
                        transaction_type_id: this.transactionType.id,
                        status: this.status,
                        coverage_type: this.coverageType,
                        start_date: this.startDate,
                        end_date: this.endDate,
                        approver_type: this.approverType,
                        approvers: this.approvers
                    }
                };

                let response;

                if (this.$route.params.id) {
                    // Edit mode: PUT request
                    response = await axios.put(`/approval/${this.$route.params.id}`, payload);
                    this.showAlert({ message: 'Approval data successfully updated', color: 'success' });
                } else {
                    // Create mode: POST request
                    response = await axios.post('/approval/create', payload);
                    this.showAlert({ message: 'Approval data successfully saved', color: 'success' });
                }

                this.$router.push('/home/approval');
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async initializeApprover(approvers) {
            this.approvers = approvers;
        },
        updateDiscretionStatus(item) {
            const approver = this.approvers.find(a => a.level === item.level);
            if (approver) {
                approver.discretion_active = item.discretion_active;
        }
    }

    },
    created() {
        this.fetchTransactionTypes();
        this.fetchUser();
        this.fetchRoles();
    }
};
</script>

<style scoped>
.custom-value {
    font-size: 12px;
    line-height: 32px;
}
</style>
