<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-container>
    <!-- First Section -->
    <h2 class="table-title mx-4">Rolling Forecast Report</h2>
    <v-row align="start" class="mx-4"> 
      <!-- First Column (3 Buttons) -->
      <v-col cols="6" class="pa-0 button-container-left">
        <router-link to="report/rofoSummary" class="custom-link">
          <v-btn block class="custom-btn">Summary Report</v-btn>
        </router-link>
        <router-link to="report/rofoSummaryDetail" class="custom-link">
          <v-btn block class="custom-btn">Summary Report Detail</v-btn>
        </router-link>
        <router-link to="report/rofoExtract" class="custom-link">
          <v-btn block class="custom-btn">Rofo Extract</v-btn>
        </router-link>
      </v-col>

      <!-- Second Column (1 Button at the Top) -->
      <v-col cols="6" class="pa-0 button-container-right">
        <router-link to="report/rofoByDistributor" class="custom-link">
          <v-btn block class="custom-btn">Rofo by Distributor</v-btn>
        </router-link>
      </v-col>
    </v-row>

    <!-- Huge Space between sections -->
    <div class="my-12"></div>

    <!-- Second Section -->
    <h2 class="table-title mx-4">CMO Report</h2>
    <v-row align="start" class="mx-4">
      <!-- First Column (3 Buttons) -->
      <v-col cols="6" class="pa-0 button-container-left">
        <router-link to="reportStation" class="custom-link">
          <v-btn block class="custom-btn">CMO Report 1</v-btn>
        </router-link>
        <router-link to="reportStation" class="custom-link">
          <v-btn block class="custom-btn">CMO Report 2</v-btn>
        </router-link>
        <router-link to="reportStation" class="custom-link">
          <v-btn block class="custom-btn">CMO Report 3</v-btn>
        </router-link>
      </v-col>

      <!-- Second Column (3 Buttons) -->
      <v-col cols="6" class="pa-0 button-container-right">
        <router-link to="reportStation" class="custom-link">
          <v-btn block class="custom-btn">CMO Report 4</v-btn>
        </router-link>
        <router-link to="reportStation" class="custom-link">
          <v-btn block class="custom-btn">CMO Report 5</v-btn>
        </router-link>
        <router-link to="reportStation" class="custom-link">
          <v-btn block class="custom-btn">CMO Report 6</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
/* Title Styling */
.table-title {
  font-weight: bold;
  color: black;
  margin-bottom: 12px;
}

/* Add border around each button container (left and right) */
.button-container-left, .button-container-right {
  border: 1px solid #d3d3d3; /* Light grey border around button container */
  border-radius: 4px; /* Optional: rounded corners for the border */
  margin-bottom: 12px; /* Space between button containers */
}

/* Add border to the left and right of the button containers */
.button-container-left {
  border-right: 1px solid #d3d3d3;
}

.button-container-right {
  border-left: 1px solid #d3d3d3;
}

/* Button Styling */
.custom-btn {
  background-color: white !important;
  color: #4a4a4a !important; /* Dark grey text */
  border-radius: 0 !important; /* No rounded corners */
  border-bottom: 1px solid #d3d3d3 !important; /* Light grey separator */
  box-shadow: none !important; /* Remove button shadow */
  text-transform: none !important; /* Keep button text normal */
  width: 100%;
  min-height: 48px; /* Standard height */
  text-align: left !important; /* Align text to the left */
  justify-content: flex-start; /* Ensure text is left-aligned */
  padding-left: 16px !important; /* Add padding inside button */
}

/* Router Link Styling */
.custom-link {
  text-decoration: none;
  width: 100%;
  display: flex;
}
</style>


<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'sellIn-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
};
</script>
